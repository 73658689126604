<template>
  <div class="s-merchandise-checkout-delivery" v-if="fields" v-show="stepCode === 'delivery'">
    <Heading class="s-merchandise-checkout-delivery__title text-primary" :field="fields.title" :type="fields.titleType" rich />
    <CustomerTypeSelector :customer-types="fields.deliveryTypes" :value="deliveryType" @item-click="onDeliveryTypeClick" v-show="false">
      <JssRichText :field="fields.deliveryTypeLabel" />
    </CustomerTypeSelector>

    <DynamicForm :form="fields.dealerForm" :dealer-modal="fields.dealerSelectModal" @change="onChangeDealer" ref="dealerFormRef" />

    <JssRichText :field="fields.formTitle" />

    <JssRichText class="mg-t-24" :field="fields.personalTitle" />
    <DynamicForm :form="fields.personalForm" ref="personalFormRef" />

    <JssRichText :field="fields.addressTitle" />
    <DynamicForm :form="fields.addressForm" :data="addressFormCfg" ref="addressFormRef" />

    <Checkbox class="s-merchandise-checkout-delivery__check" v-model:value="diffAddr" :mandatoryText="fields.diffAddressText.value" v-if="!$isNullOrEmpty(fields.diffAddressText.value)" />
    <Transition @before-enter="transitions?.accordion.beforeEnter" @enter="transitions?.accordion.enter" @leave="transitions?.accordion.leave">
      <div v-show="diffAddr">
        <JssRichText :field="fields.billingTitle" />
        <DynamicForm :form="fields.billingForm" ref="billingFormRef" />
      </div>
    </Transition>

    <JssRichText class="mg-t-24" :field="fields.consentTitle" />
    <DynamicForm :form="fields.consentForm" ref="consentFormRef" />

    <GeeCaptcha ref="geeCaptchaRef" />
  </div>
</template>
<script>
import { reactive, onMounted, toRefs, watch, inject } from 'vue';
import { getCurrentAlpha2Code } from '@/utils/site-utils';
import { DELIVERY_TYPE_CODE } from '@/utils/constants';
import { getCountryOptions } from '@/services/siteService';
import { transitions } from '@/utils/transitions';
export default {
  name: 'MerchandiseCheckoutDelivery',
  props: {
    step: {
      type: Number
    },
    stepCode: {
      type: String
    },
    fields: {
      type: Object
    },
    currency: {
      type: String,
      default: null
    },
    dealerList: {
      type: Array
    }
  },
  setup(props) {
    const merchandiseCheckoutMethods = inject('merchandiseCheckoutMethods');
    const state = reactive({
      deliveryType: null,
      dealer: null,
      dealerSelectorRef: null,
      dealerFormRef: null,
      personalFormRef: null,
      addressFormRef: null,
      consentFormRef: null,
      addressFormCfg: {
        country: {
          controlProps: {
            selectedOption: null
          }
        }
      },
      diffAddr: false,
      billingFormRef: {
        country: {
          controlProps: {
            selectedOption: null
          }
        }
      },
      geeCaptchaRef: null,
      dealerSelectModalRef: null,
      currentCountry: null
    });
    const _methods = {
      initAddressCountry: async () => {
        const alpha2Code = getCurrentAlpha2Code();
        const countryOptions = await getCountryOptions();
        const currentCountry = countryOptions.find((c) => c.code === alpha2Code);
        if (currentCountry) {
          state.addressFormRef.updateItem('country', {
            controlProps: {
              selectedOption: currentCountry
            }
          });
          state.billingFormRef.updateItem('country', {
            controlProps: {
              selectedOption: currentCountry
            }
          });
        }
      }
    };
    const methods = {
      onDeliveryTypeClick: (ct) => {
        state.deliveryType = ct;
      },
      onChangeDealer: (name, value) => {
        merchandiseCheckoutMethods.changeDealer(value);
      }
    };
    watch(
      () => props.dealerList,
      (v) => {
        const dealerRef = state.dealerFormRef.getItem('dealer').ref;
        dealerRef.setDealers(v);
      }
    );
    onMounted(() => {
      state.geeCaptchaRef?.init();
      state.deliveryType = props.fields.deliveryTypes.find((d) => !d.disabled);
      _methods.initAddressCountry();
    });
    return {
      DELIVERY_TYPE_CODE,
      ...toRefs(state),
      ...methods,
      transitions
    };
  }
};
</script>
