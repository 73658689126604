<script setup>
import { buildConfigField } from '@/utils/sitecore-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { merge } from 'lodash';

const props = defineProps({
  title: {
    type: String
  },
  /**@type CarModel*/
  carModel: {
    type: Object
  },
  /**@type ComponentTheme */
  theme: {
    type: String
  }
});
const buttonMap = {
  black: 'white',
  white: 'black'
};
/**
 * @param {CarModelPaying} item
 */
const buildButton = (item) => {
  const { text, url, linktype, target } = item.link || {};
  const result = {
    buttonType: buildConfigField('tertiary-button'),
    buttonTheme: buildConfigField(buttonMap[props.theme]),
    text: {
      value: item.buttonText
    },
    link: {
      value: {
        linktype,
        target,
        href: url
      }
    },
    hasAnimation: {
      value: false
    }
  };
  if (!isNullOrEmpty(item.buttonIcon?.value)) {
    merge(result, {
      icon: item.buttonIcon
    });
  }
  return result;
};
</script>

<template>
  <div class="s-vehicle-paying" :class="[{ [`theme-${theme}`]: !$isNullOrEmpty(theme) }]" v-if="carModel?.paying?.length > 0">
    <div class="__ft-h3" v-html="props.title" />
    <div class="s-vehicle-paying__items">
      <div class="s-vehicle-paying__item" v-for="(item, i) in carModel.paying" :key="i">
        <div class="s-vehicle-paying__item-image" v-aspect-ratio="'3_2'">
          <img :src="$adaptiveField(item.desktopImage.src, item.mobileImage.src, item.tabletImage.src)" alt="" />
        </div>
        <div class="fs-26" v-html="item.heading" />
        <div class="text-desc" v-html="item.body" />
        <site-button :fields="buildButton(item)" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-vehicle-paying {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: grid-width-m(1);
  &__items {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &-image {
      aspect-ratio: 3/2;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &.theme- {
    &black {
      background-color: $black;
      color: $white;
    }
  }
  @include tablet-landscape {
    gap: 40px;
    padding: grid-width(2);
    &__items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 32px;
      row-gap: 16px;
    }
  }
}
</style>
