<script setup>
import { inject } from 'vue';

const props = defineProps({
  title: {
    type: String
  },
  disclaimer: {
    type: Object
  }
});
const toast = inject('toast');
const onIconClick = () => {
  toast.show({
    icon: 'lotus',
    message: props.disclaimer.fields.text.value ?? props.disclaimer.fields.text,
    sticky: false,
    closable: true
  });
};
</script>

<template>
  <div class="s-simple-disclaimer">
    <div class="s-simple-disclaimer__title" v-html="title" v-if="!$isNullOrEmpty(title)" />
    <slot class="s-simple-disclaimer__title" v-else />
    <icon class="s-simple-disclaimer__icon" :field="disclaimer?.fields.icon" @click.stop="onIconClick" v-if="disclaimer?.fields" />
  </div>
</template>

<style lang="scss">
.s-simple-disclaimer {
  display: flex;
  align-items: center;
  gap: 8px;
  &__title {
    display: inline;
  }
  &__icon {
    vertical-align: middle;
    cursor: pointer;
  }
}
</style>
