import api from '@/api';
import { webStorage } from '@/utils/web-storage';
import { S_MHP_PRODUCTS } from '@/utils/web-storage-keys';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import { buildModelImgUrl } from '@/utils/mhp-utils';

export const getProduct = async (series) => {
  if (isNullOrEmpty(series)) return null;
  const cache = webStorage.get(S_MHP_PRODUCTS) || {};
  if (!cache[series]) {
    const [product] = await api.mhp.product.detail({
      series: series.toUpperCase()
    });
    cache[series] = product;
    webStorage.set(S_MHP_PRODUCTS, cache);
  }
  return cache[series];
};
export const getProductCameras = async (series, length = 5) => {
  const product = await getProduct(series);
  const cameras = product?.cameras?.cameras;
  if (!isArray(cameras)) return [];
  return cameras.slice(0, Math.min(cameras.length, length));
};
export const getImages = async (page, seriesName, carModel, filterId, buttonFeatures, length = 5) => {
  const cameras = await getProductCameras(seriesName, 5);
  const result = [];
  for (let camera of cameras) {
    result.push(
      buildModelImgUrl(page, filterId, seriesName, buttonFeatures, carModel, 'Mountain', {
        camera
      })
    );
  }
  return result;
};
