<template>
  <div class="s-reservation-model-list-content" :class="[`columns_${$settingValue(fields.columnCount, '2')}`]">
    <jss-rich-text class="s-reservation-model-list-content__item" :field="item.fields?.title" v-for="(item, index) in fields.items" :key="index" />
  </div>
</template>

<script>
export default {
  name: 'reservation-model-list-content',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-reservation-model-list-content {
  &.columns_ {
    &2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 4px;
      grid-column-gap: 24px;
    }
    &3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 4px;
      grid-column-gap: 24px;
    }
  }
}
</style>
