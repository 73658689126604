import SpinnerClimbing from './spinner-climbing';
import SpinnerJumping from './spinner-jumping';
import SpinnerBallGridPulse from './spinner-ball-grid-pulse';
import SpinnerBallBeat from './spinner-ball-beat';
import SpinnerCircle from './spinner-circle';
import SpinnerLotus from './spinner-lotus';
export default {
  install(Vue) {
    Vue.component('SpinnerClimbing', SpinnerClimbing);
    Vue.component('SpinnerJumping', SpinnerJumping);
    Vue.component('SpinnerBallGridPulse', SpinnerBallGridPulse);
    Vue.component('SpinnerBallBeat', SpinnerBallBeat);
    Vue.component('SpinnerCircle', SpinnerCircle);
    Vue.component('SpinnerLotus', SpinnerLotus);
  }
};
