<script setup>
import { ref } from 'vue';

const props = defineProps({
  markets: {
    type: Array
  }
});
const emit = defineEmits(['selected']);
const modalRef = ref();
const onLanguageClick = (market, language) => {
  emit('selected', market, language);
};
const open = () => {
  modalRef.value.open();
};
const close = () => {
  modalRef.value.close();
};
defineExpose({ open, close });
</script>

<template>
  <modal class="s-market-select-modal" animation="right-slide-in" closable ref="modalRef">
    <div class="s-market-select-modal__market" v-for="(m, i) in markets" :key="i">
      <div class="s-market-select-modal__market-name">{{ m.displayName }}</div>
      <div class="s-market-select-modal__market-languages">
        <div class="s-market-select-modal__market-language" @click="onLanguageClick(m.fields, l.fields)" v-for="(l, j) in m.fields.languages" :key="j">{{ l.fields.text.value }}</div>
      </div>
    </div>
  </modal>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-market-select-modal {
  $m: '.e-modal';
  &.e-modal {
    justify-content: flex-end;
    #{$m}__content {
      width: 100%;
      height: 100%;
      padding: 72px 24px 16px 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  &__market {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-name {
      background: $grey-light;
      padding: 4px 8px;
    }
    &-languages {
      padding: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4px;
    }
  }
  @include tablet-landscape {
    &.e-modal {
      #{$m}__content {
        width: grid-width(10);
      }
    }
  }
}
</style>
