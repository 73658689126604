<template>
  <div class="c-stock-vehicle-price-nav" v-if="fields">
    <div class="c-stock-vehicle-price-nav__overview">
      <!--      <img :src="vehicleDetail?.images?.stickyBar[0].externalUrl" v-if="vehicleDetail?.images?.stickyBar?.length" class="c-stock-vehicle-price-nav__overview__car" alt="" />-->
      <div class="c-stock-vehicle-price-nav__overview__detail">
        <div class="c-stock-vehicle-price-nav__overview__model">{{ $formatModel(vehicleDetail?.vehicleModel) }}</div>
        <div class="c-stock-vehicle-price-nav__overview__color">{{ modelColor }}</div>
      </div>
    </div>
    <div class="c-stock-vehicle-price-nav__handle">
      <div class="c-stock-vehicle-price-nav__handle__price">
        <template v-if="!$isNullOrEmpty(totalPrice?.price) && showPrice"> {{ $t('Total') }}: {{ $formatMoney(totalPrice?.price, totalPrice.currency) }} </template>
      </div>
      <div class="c-stock-vehicle-price-nav__handle__ctas">
        <SiteButton :fields="fields?.summaryCta?.fields" @click.prevent="openPriceSummary(false)" v-if="showPrice" />
        <SiteButton v-bind="continueProps" @click="onContinue" />
      </div>
    </div>
  </div>
  <modal class="c-stock-vehicle-price-nav__modal" ref="productOverviewModal">
    <div class="c-stock-vehicle-price-nav__modal__title">
      {{ modelTitle }}
      <Icon class="c-stock-vehicle-price-nav__modal__close" name="close" @click="productOverviewModal.close()"></Icon>
    </div>
    <div class="c-stock-vehicle-price-nav__modal__car">
      <img :src="vehicleDetail?.images?.priceSummary[0]?.externalUrl" v-if="vehicleDetail?.images?.priceSummary?.length" alt="" class="" />
    </div>
    <div class="c-stock-vehicle-price-nav__modal__content">
      <template v-for="item in priceOptions" :key="item?.label">
        <div class="c-stock-vehicle-price-nav__modal__item" v-if="item?.visibility">
          <div class="c-stock-vehicle-price-nav__modal__item__label">
            <span>{{ item?.label }}</span>
            <span>{{ $formatMoney(item?.price, vehicleDetail?.currency) }}</span>
          </div>
          <div class="c-stock-vehicle-price-nav__modal__item__value">{{ item?.value }}</div>
        </div>
      </template>
      <div class="c-stock-vehicle-price-nav__modal__item summary">
        <div class="c-stock-vehicle-price-nav__modal__item__label" v-if="!$isNullOrEmpty(totalPrice.price) && showPrice">
          <span>{{ $ifEmpty(fields.totalLabel?.value, $t('Total')) }}:</span>
          <span>{{ $formatMoney(totalPrice.price, totalPrice.currency) }}</span>
        </div>
        <div class="c-stock-vehicle-price-nav__modal__item__value">{{ modelVat }}</div>
      </div>
    </div>
    <div class="c-stock-vehicle-price-nav__modal__item toPay" v-if="!$isNullOrEmpty(downPayment)">
      <div class="c-stock-vehicle-price-nav__modal__item__label">
        <span>{{ modelPay }}</span>
        <span>{{ $formatMoney(downPayment, vehicleDetail?.currency) }}</span>
      </div>
      <div class="c-stock-vehicle-price-nav__modal__item__value">{{ modelReturnDeposit }}</div>
    </div>
    <div class="c-stock-vehicle-price-nav__modal__sticky" v-if="showBar">
      <div class="c-stock-vehicle-price-nav__modal__item__pay">
        <!--        <div class="c-stock-vehicle-price-nav__modal__item__pay__label">{{ modelAgreement }}</div>-->
        <div class="c-stock-vehicle-price-nav__modal__item__pay__cta">
          <site-button :fields="payCtaProps" />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { computed, reactive, toRefs, getCurrentInstance, inject } from 'vue';
import { deepCopy, isNullOrEmpty } from '@/utils/obj-utils';
import { appendQuery } from '@/utils/uri-utils';
import { merge } from 'lodash';

export default {
  name: 'stock-vehicle-price-nav',
  props: {
    type: {
      type: String
    },
    params: {
      type: Object
    },
    priceOptions: {
      type: Array
    },
    vehicleDetail: {
      type: Object
    },
    carSeries: {
      type: Object
    },
    downPayment: {
      type: Number
    },
    page: {
      type: Object
    },
    fields: {
      type: Object
    },
    modelTitle: {
      type: String
    },
    modelPay: {
      type: String
    },
    modelReturnDeposit: {
      type: String
    },
    modelAgreement: {
      type: String
    },
    modelVat: {
      type: String
    },
    modelColor: {
      type: String
    }
  },
  setup(props) {
    const stockMethods = inject('stockMethods');
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const state = reactive({
      productOverviewModal: null,
      payCtaProps: deepCopy(props?.fields?.payCta?.fields),
      showBar: false
    });
    const computes = {
      totalPrice: computed(() => props.priceOptions.find((x) => x.elementName === 'Total Price')),
      showPrice: computed(() => props.carSeries?.hideStockPrice !== '1'),
      continueProps: computed(() => {
        const { continueCta } = props.fields ?? {};
        const href = continueCta?.fields.link.value?.href;
        if (isNullOrEmpty(href)) return continueCta;
        merge(continueCta.fields.link.value, {
          href: appendQuery(href, { vin: props.params?.vin })
        });
        return continueCta;
      })
    };
    state.payCtaProps.link.value.href = appendQuery(state?.payCtaProps?.link?.value?.href, {
      vin: props.params?.vin
    });
    const methods = {
      onContinue(e) {
        if (isNullOrEmpty(e.target.href)) methods.openPriceSummary(true);
      },
      openPriceSummary(showVar) {
        const page = $jss.routeData();
        stockMethods.pushView(props.vehicleDetail, 'add_car_to_cart');
        state.showBar = showVar ?? false;
        state.productOverviewModal.open();
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.c-stock-vehicle-price-nav {
  display: block;
  height: auto;
  &__overview {
    height: 76px;
    position: relative;
    background: $grey-light;
    &__car {
      max-height: 347px;
      position: absolute;
      top: -$space-32;
      left: $space-24;
      z-index: 2;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
    &__detail {
      position: absolute;
      right: $space-24;
      top: 50%;
      transform: translate(0, -50%);
      line-height: 24px;
    }
    &__color {
      color: $grey;
      @include h11;
    }
    &__theme {
      color: $grey-neutral;
    }
  }

  &__handle {
    height: 100%;
    background: $black;
    color: $white;
    display: block;
    padding: $space-32 $space-24;

    &__price {
      font-size: 24px;
    }

    &__ctas {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: $space-24;
      .e-site-button {
        &:last-child {
          margin-left: $space-16;
        }
      }
    }
  }

  &__modal {
    user-select: none;

    .e-modal__content {
      position: relative;
      width: 100vw;
    }

    &__title {
      padding: $space-32 0 $space-32;
      margin: 0 24px;
      border-bottom: 1px solid $grey-fade;
      font-size: 18px;
      background: $white;
      position: sticky;
      top: 0;
    }

    &__car {
      width: 100%;
      height: grid-width(12);
      max-height: 347px;
      padding: 0 24px;
      display: block;
      margin-top: $space-32;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    &__close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;

      svg,
      path {
        width: 18px;
      }
    }

    &__content {
      margin: 0 24px;
    }

    &__sticky {
      padding: 0 grid-width(1) $space-60 grid-width(1);
      background: $white;
      position: sticky;
      bottom: 0;
    }

    &__item {
      $item: &;
      padding: $space-30 0 $space-32;
      border-bottom: 1px solid $grey-fade;
      &,
      &.summary {
        margin: 0;
      }
      &__label {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__value {
        font-size: 16px;
        line-height: 24px;
        color: rgba($grey-neutral, 0.8);
      }

      &__pay {
        padding-top: $space-32;
        font-size: 16px;
        line-height: 24px;
        &__label {
          margin: 0;
          color: $grey-neutral;
          display: none;
        }

        &__cta {
          text-align: center;
          margin-top: $space-48;
          .e-site-button {
            width: grid-width-m(8);
          }
        }
      }

      &.toPay {
        background: $yellow;
        padding: 12px 24px;

        #{$item}__label {
          font-size: 16px;
          line-height: 24px;
        }
      }
      &.summary {
        border: none;

        #{$item}__label {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  @include tablet-landscape {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    user-select: none;

    &__overview {
      height: 100%;
      &__car {
        left: $space-60;
        height: 60%;
      }
      &__detail {
        right: $space-100;
      }
    }

    &__handle {
      &__ctas {
        white-space: nowrap;
      }
    }
    &__modal {
      .e-modal__content {
        width: grid-width(12);
        max-width: 960px;
      }
      &__close {
        svg {
          width: 32px;
          height: 32px;
        }
      }
      &__content {
        margin: 0 grid-width(1);
      }
      &__title {
        padding: $space-32 0;
        margin: 0 grid-width(1);
        font-size: 32px;
      }
      &__car {
        margin-top: $space-48;
        padding: 0 grid-width(1);
      }
      &__item {
        $item: &;
        margin: 0;
        &,
        &.summary {
          padding: 32px 24px;
        }
        &__label {
          font-size: 16px;
          line-height: 32px;
        }

        &__pay {
          display: flex;
          align-content: flex-start;
          justify-content: flex-end;
          padding-top: $space-40;
          &__cta {
            margin-top: 0;
            .e-site-button {
              width: 148px;
            }
          }
          &__label {
            margin-right: $space-60;
          }
        }

        &.toPay {
          margin: 0 grid-width(1, true);
          border: none;
          padding: $space-32 24px;
          cursor: pointer;
          #{$item}__label {
            font-size: 24px;
            line-height: 32px;
          }
        }
        &.summary {
          #{$item}__label {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }
  }
  @include desktop {
    height: 100px;
    &__overview {
      &__car {
        height: 100%;
      }
    }
    &__handle {
      height: auto;
      padding: 0 $space-60;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__ctas {
        margin-top: 0;
        .e-site-button {
          &:last-child {
            margin-left: $space-16;
          }
        }
      }
    }
  }
}
</style>
