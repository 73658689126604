<template>
  <div class="s-merchandise-return-summary">
    <div class="s-merchandise-return-summary__close" @click="onClosePriceBreakdown" v-if="$deviceComputes.isMobileOrTablet.value">
      <Icon name="close" type="tiny" />
    </div>
    <div class="s-merchandise-return-summary__faq">
      <JssRichText class="s-merchandise-return-summary__faq__title" :field="title" />
      <JssRichText class="s-merchandise-return-summary__faq__subtitle" :field="description" />
      <div class="s-merchandise-return-summary__faq__item" :class="index === 0 ? 'active' : null" v-for="(item, index) in faqlist" :key="index">
        <div class="s-merchandise-return-summary__faq__item__question" @click="questionClick(index)">
          <jss-rich-text :field="item.fields.question" tag="div" class="s-merchandise-return-summary__faq__item__question__item" />
          <Icon name="down" size="tiny" :class="item.selected ? 'open' : ''" />
        </div>
        <transition @before-enter="onItemBeforeEnter" @enter="onItemTransEnter" @leave="onItemTransLeave">
          <jss-rich-text :field="item.fields.answer" tag="div" class="s-merchandise-return-summary__faq__item__answer" v-if="item.selected" />
        </transition>
      </div>
    </div>
    <div class="s-merchandise-return-summary__price">
      <!-- <div class="s-merchandise-return-summary__price__item">
        <div class="s-merchandise-return-summary__price__item__label" v-html="$t('Refund subtotal')" />
        <div class="s-merchandise-return-summary__price__item__value">{{ $formatShopMoney(total, currency) }}</div>
      </div> -->
      <div class="s-merchandise-return-summary__price__item">
        <div class="s-merchandise-return-summary__price__item__content">
          <div class="s-merchandise-return-summary__price__item__content__label" v-html="$t('Total refund')" />
          <div class="s-merchandise-return-summary__price__item__content__item" v-html="$t('Inc VAT')" />
        </div>
        <div class="s-merchandise-return-summary__price__item__value">{{ $formatShopMoney(total, currency) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, toRefs } from 'vue';
import { getGlobalCfgValues } from '@/utils/site-utils';
import gsap from 'gsap';
export default {
  name: 'MerchandiseReturnSummary',
  props: {
    step: {
      type: Number
    },
    title: {
      type: Object
    },
    description: {
      type: Object
    },
    faqlist: {
      type: Object
    },
    total: {
      type: Number
    },
    page: {
      type: Object
    },
    currency: {
      type: String
    }
  },
  setup(props, context) {
    const state = reactive({});
    const methods = {
      questionClick(_index) {
        context.emit('questionClick', _index);
      },
      onItemBeforeEnter(el) {
        el.style.height = 0;
      },
      onItemTransEnter(el, done) {
        gsap.to(el, {
          height: el.scrollHeight + 10,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onItemTransLeave(el, done) {
        gsap.to(el, {
          height: 0,
          duration: 0.6,
          ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
          onComplete() {
            done();
          }
        });
      },
      onClosePriceBreakdown() {
        context.emit('onClosePriceBreakdown');
      }
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-return-summary {
  background: $black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100vh;
  padding-top: 52px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &__close {
    color: $white;
    height: 56px;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }
  &__faq {
    padding: 0 grid-width(1) 0;
    flex: 1;
    &__title {
      font-size: 26px;
      line-height: 32px;
      color: $white;
    }
    &__subtitle {
      font-size: 12px;
      line-height: 20px;
      color: $white;
      margin-top: 24px;
    }
    &__item {
      padding: 16px 0 6px;
      border-top: 1px solid #2f2f2f;
      overflow: hidden;
      &.active {
        margin-top: 44px;
      }
      &__question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        line-height: 24px;
        color: $white;
        margin-bottom: 10px;
        cursor: pointer;
        &__item {
          width: 90%;
        }
        .e-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          transition: all 0.3s;
          &.open {
            transform: rotate(180deg);
            transform-origin: center center;
          }
        }
      }
      &__answer {
        font-size: 12px;
        line-height: 20px;
        color: #757575;
      }
    }
  }
  &__price {
    width: 100%;
    padding: 24px grid-width(2);
    border-top: 1px solid #2f2f2f;
    background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0, rgba(0, 0, 0, 0.9) 100%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &__content {
        display: flex;
        align-items: center;
        gap: 5px;
        &__label {
          font-size: 12px;
          line-height: 20px;
          color: $white;
        }
        &__item {
          font-size: 12px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      &__label {
        font-size: 12px;
        line-height: 20px;
        color: $white;
      }
      &__value {
        font-size: 12px;
        line-height: 20px;
        color: $white;
      }
    }
  }
  @include tablet-landscape {
    min-height: calc(100vh - (((100vw - 23 * 8px) * 12 / 24 + 12 * 8px) * 1) * 0.5625);
    position: relative;
    height: auto;
    padding-top: 0px;
    &__price {
      gap: 24px;
    }
    &__faq {
      padding: 60px grid-width(1) 0;
    }
  }
}
</style>
