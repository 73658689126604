<script setup>
import { sitecoreProps } from '@/utils/constants';
import { computed, reactive } from 'vue';
import { debounce } from 'lodash';
import utils from '@/utils';
const emit = defineEmits(['change']);
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreProps
});
const state = reactive({
  /**@type {?DynamicForm}*/
  formRef: null
});
const secFields = computed(() => utils.oneForm.buildInheritFields(props.fields));
const form = computed(() => utils.oneForm.buildFormItems(secFields.value?.formItems));
const onFormChange = debounce(async () => {
  const [valid, data] = await state.formRef.validate(false);
  emit('change', valid, data);
}, 200);
const getVerifiedConsents = () => state.formRef.getVerifiedConsents();
defineExpose({ getVerifiedConsents });
</script>

<template>
  <div class="s-of-legal" v-if="secFields">
    <jss-rich-text class="mg-b-8" :field="secFields.title" />
    <jss-rich-text class="mg-b-8" :field="secFields.body" />
    <dynamic-form :form="form" :ref="(e) => (state.formRef = e)" @change="onFormChange" />
  </div>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.s-of-legal {
  padding: 0 grid-width-m(1);
  @include tablet-landscape {
    padding: 0 grid-width(1);
  }
}
</style>
