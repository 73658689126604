<template>
  <component
    class="e-site-button old ani-link-swipe"
    :is="comType"
    :class="rootClasses"
    v-bind="comAttrs"
    :disabled="disabled"
    v-if="fields && $appVisible($route, fields) && (fields?.icon?.value?.svgCode || fields?.text?.value)"
    @animationend.passive="onAnimationEnd"
    @mouseenter.passive="hoverIt"
    @mousedown="onTouchStart"
    @mouseleave.passive="unHoverIt"
    @touchstart.passive="onTouchStart"
    @touchend.passive="unHoverIt"
    @click.passive="onClick"
  >
    <div class="e-site-button__loading" v-if="loading">
      <spinner name="ball-beat" size="small" />
    </div>
    <template v-else>
      <span class="e-site-button__text" v-html="btnText" />
      <Icon class="e-site-button__icon" :field="fields?.icon" :class="{ [`__c-${$settingValue(fields.iconColor)}`]: $settingValue(fields.iconColor) }" />
    </template>
  </component>
</template>

<script>
import { computed, reactive, toRefs, watch, getCurrentInstance, inject } from 'vue';
import { settingValue } from '@/utils/site-utils';
import { merge, debounce } from 'lodash';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { sitecoreProps } from '@/utils/constants';
import { gtmPush } from '@/utils/gtm-utils';
import { openWindow } from '@/utils/dom-utils';
import { qtUtils } from '@/utils/ali-tracker-utils';
import * as querystring from 'querystring';
import { getBetterUrl } from '@/utils/uri-utils';
import { equalString } from '@/utils/string-utils';

export default {
  name: 'SiteButtonOld',
  inheritAttrs: false,
  emits: ['click'],
  props: {
    /**@type ButtonField*/
    fields: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    qtData: {
      type: Object
    },
    moduleName: {
      type: String
    },
    ...sitecoreProps
  },
  setup(props, ctx) {
    let touchTimeout, clickTriggered;
    const { deviceState } = inject('device-common');
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const _methods = {
      getTheme: () => (props.disabled ? settingValue(props.fields?.disabledTheme, 'grey') : settingValue(props.fields?.theme, 'yellow')),
      getType: () => settingValue(props.fields?.type, 'primary-button'),
      qtTrackClick() {
        /**@type SitePage */
        const page = $jss.routeData();
        const { aliQtEnabled } = props.fields;
        if (aliQtEnabled?.value) {
          const qtEventCode = qtUtils.getBtnCode(page, props);
          if (isNullOrEmpty(qtEventCode)) return;
          const qtParams = methods.getTrackParams();
          qtUtils.trackClick(qtEventCode, qtParams);
        }
      },
      clicking: debounce((e) => {
        const btnType = state.type === 'primary-button' ? 'primary' : props?.fields?.bordered?.value ? 'secondary' : 'text';
        gtmPush({
          event: `button_${btnType}_click`
        });
        if (props.disabled || props.loading) return false;
        _methods.qtTrackClick();
        const { phone, anchorId } = props.fields || {};
        if (anchorId?.value) {
          const anchor = document.getElementById(anchorId?.value);
          if (anchor) {
            const offset = anchor.getBoundingClientRect();
            window.scrollTo(0, offset.top);
            return;
          }
        }
        if (phone?.value) {
          openWindow(`tel: ${phone?.value}`, '_self');
        }
        ctx.emit('click', e);
      }, 150)
    };
    const state = reactive({
      theme: _methods.getTheme(),
      type: _methods.getType(),
      startY: 0
    });
    const computes = {
      comType: computed(() => (props.fields?.link?.value.href ? 'site-link' : 'button')),
      comAttrs: computed(() => {
        const attrs = merge({}, ctx.attrs);
        if (computes.comType.value === 'site-link') {
          merge(attrs, {
            link: props.fields?.link
          });
        }
        return attrs;
      }),
      iconCode: computed(() => props.fields?.icon?.value.svgCode),
      isExternal: computed(() => props.fields?.link?.value.linktype === 'external'),
      animation: computed(() => settingValue(props.fields?.animation)),
      btnText: computed(() => props.fields?.text?.value ?? props.fields?.link?.value?.text),
      hasIcon: computed(() => !isNullOrEmpty(computes.iconCode.value)),
      rootClasses: computed(() => {
        const tbPadding = settingValue(props.fields?.tbPadding);
        const sidePadding = settingValue(props.fields?.sidePadding);
        const align = settingValue(props.fields?.align);
        return [
          state.type,
          `theme-${state.theme}`,
          {
            disabled: props.disabled,
            'no-icon': !computes.hasIcon.value,
            'just-icon': !computes.btnText.value && computes.hasIcon.value,
            bordered: props?.fields?.bordered?.value,
            [`size-${props.size}`]: props.size,
            [`tb-padding-${tbPadding}`]: tbPadding,
            [`side-padding-${sidePadding}`]: sidePadding,
            [`align-${align}`]: align,
            reverse: props?.fields?.reverse?.value ?? false,
            loading: props.loading,
            fullWidthOnMobile: props?.fields?.fullWidthOnMobile?.value ?? false
          }
        ];
      }),
      textClasses: computed(() => {
        const fontSize = settingValue(props.fields?.fontSize);
        return [
          {
            [`__f-${fontSize}`]: fontSize
          }
        ];
      }),
      isSafari: computed(() => equalString(deviceState.browser, 'safari'))
    };
    const methods = {
      hoverIt: debounce((e) => {
        if (props.disabled) return;
        const { type, hoverType, theme, hoverTheme } = props.fields || {};
        state.theme = settingValue(hoverTheme ?? theme, 'yellow');
        state.type = settingValue(hoverType ?? type, 'primary-button');
      }, 150),
      unHoverIt(e) {
        if (props.disabled) return;
        const { type, theme } = props.fields || {};
        state.theme = settingValue(theme, 'yellow');
        state.type = settingValue(type, 'primary-button');
      },
      onAnimationEnd(e) {
        e.stopPropagation();
      },
      onTouchStart: debounce((e) => {
        if (!computes.isSafari.value) return;
        if (clickTriggered) return;
        clickTriggered = true;
        const startRect = e.target.getBoundingClientRect();
        const startY = startRect.top;
        if (touchTimeout) clearTimeout(touchTimeout);
        touchTimeout = setTimeout(() => {
          clickTriggered = false;
          const endRect = e.target.getBoundingClientRect();
          if (Math.abs(endRect.top - startY) > 50) return;
          e.preventDefault();
          _methods.clicking(e);
          const { href, target } = props.fields?.link?.value || {};
          if (!isNullOrEmpty(href)) {
            const url = getBetterUrl(href, null, true);
            openWindow(url, target);
          }
        }, 420);
      }, 100),
      onMouseup: debounce((e) => {
        if (clickTriggered) return false;
        if (!computes.hasIcon.value || !computes.isSafari.value) return;
        e.preventDefault();
        _methods.clicking(e);
        const { href, target } = props.fields?.link?.value || {};
        if (!isNullOrEmpty(href)) {
          const url = getBetterUrl(href, null, true);
          openWindow(url, target);
        }
        return false;
      }, 100),
      onClick: debounce((e) => {
        if (clickTriggered) return false;
        if (!computes.hasIcon.value || !computes.isSafari.value) {
          _methods.clicking(e);
        }
      }, 110),
      getTrackParams() {
        const { aliQtParams } = props.fields || {};
        const qtParams = {
          button_id: props.id ?? props?.fields?.link?.value.href,
          button_name: props.name ?? props?.fields?.link?.value.href,
          customized_button_name: props.fields?.text.value
        };
        if (!isNullOrEmpty(aliQtParams?.value)) {
          merge(qtParams, querystring.parse(aliQtParams?.value));
        }
        merge(qtParams, props.qtData);
        return qtParams;
      }
    };
    watch(
      () => props.fields,
      () => {
        state.theme = _methods.getTheme();
        state.type = _methods.getType();
      },
      {
        deep: true
      }
    );
    watch(
      () => props.disabled,
      () => {
        state.theme = _methods.getTheme();
        state.type = _methods.getType();
      }
    );
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.e-site-button {
  $c: &;
  &.old {
    @include h10;
    width: fit-content;
    height: 56px;
    border: 1px solid;
    line-height: 1;
    font-weight: normal;
    cursor: pointer;
    color: currentColor;
    user-select: none;
    padding: 0 24px;
    transition: all 600ms cubic-bezier(0.38, 0.015, 0, 0.995);
    position: relative;
    gap: 16px;
    &.tb-padding {
      &-small {
        padding-top: 4px;
        padding-bottom: 4px;
      }
      &-middle {
        padding-top: 8px;
        padding-bottom: 4px;
      }
      &-large {
        padding-top: 12px;
        padding-bottom: 4px;
      }
    }
    &.side-padding {
      &-small {
        padding-left: 16px;
        padding-right: 16px;
        &.primary-button {
          padding-left: 48px;
          padding-right: 16px;
          &.no-icon {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
        &.primary-link {
          &.bordered {
            padding-left: 48px;
            padding-right: 16px;
            &.no-icon {
              padding-left: 16px;
              padding-right: 16px;
            }
          }
        }
        &.icon-link {
          &.bordered {
            padding-left: 16px;
            padding-right: 48px;
            &.no-icon {
              padding-left: 16px;
              padding-right: 16px;
            }
          }
        }
      }
      &-middle {
        padding-left: 32px;
        padding-right: 32px;
        &.primary-button {
          padding-left: 64px;
          padding-right: 32px;
          &.no-icon {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
        &.primary-link {
          &.bordered {
            padding-left: 64px;
            padding-right: 32px;
            &.no-icon {
              padding-left: 32px;
              padding-right: 32px;
            }
          }
        }
        &.icon-link {
          &.bordered {
            padding-right: 32px;
            padding-left: 64px;
            &.no-icon {
              padding-left: 32px;
              padding-right: 32px;
            }
          }
        }
      }
      &-large {
        padding-left: 48px;
        padding-right: 48px;
        &.primary-button {
          padding-left: 80px;
          padding-right: 48px;
          &.reverse {
            padding-left: 48px;
            padding-right: 80px;
          }
          &.no-icon {
            padding-left: 48px;
            padding-right: 48px;
          }
        }
        &.primary-link {
          &.bordered {
            padding-left: 80px;
            padding-right: 48px;
            &.reverse {
              padding-left: 48px;
              padding-right: 80px;
            }
            &.no-icon {
              padding-left: 48px;
              padding-right: 48px;
            }
          }
        }
        &.icon-link {
          &.bordered {
            padding-left: 48px;
            padding-right: 80px;
            &.reverse {
              padding-left: 80px;
              padding-right: 48px;
            }
            &.no-icon {
              padding-left: 48px;
              padding-right: 48px;
            }
          }
        }
      }
    }
    #{$c}__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    #{$c}__text {
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-family: lotusFontFamily('Overpass'), sans-serif;
      padding-top: 0;
    }

    .e-icon {
      display: flex;
      align-items: center;
      position: relative;
      > svg {
        height: 16px;
      }
    }
    &.no-icon {
      padding: 0 24px;
      width: fit-content;
      &.fullWidthOnMobile {
        justify-content: center;
        width: 100%;
      }
    }
    &.primary-button {
      text-decoration: none;
      background-color: $yellow;
      border-color: $yellow;
      color: $black;
      padding: 0 16px 0 48px;
      transition: background-color 0.2s, border-color 0.2s;
      &:hover {
        background-color: darken($yellow, 1);
        border-color: darken($yellow, 1);
      }
      &.just-icon {
        padding: 0 8px;
        width: auto;
      }
      &.no-icon {
        padding: 0 24px;
        width: fit-content;
      }
      &.theme- {
        $themes: map-get($button-themes, 'primary-button');
        @each $theme, $value in $themes {
          $bgColor: map-get($value, 'bgColor');
          $color: map-get($value, 'color');
          &#{'' + $theme} {
            background-color: $bgColor;
            border-color: $bgColor;
            color: $color;
          }
        }
      }
      &.disabled {
        &,
        &:hover {
          background-color: $grey-light !important;
          border-color: $grey-light !important;
          color: $grey-next !important;
        }
      }
    }
    &.ani- {
      &link-swipe {
        #{$c}__text {
          position: relative;
        }
        .e-icon {
          float: right;
          color: currentColor;
          opacity: 0;
          transform: translateX(-8px);
          transition-timing-function: cubic-bezier(0.38, 0.015, 0, 0.995);
          transition: opacity 0.2s, transform 0.2s;
          svg {
            height: 16px;
          }
        }
        &:hover {
          &:not(.disabled) {
            .e-icon {
              transform: translateX(0);
              opacity: 1;
            }
          }
        }
      }
    }
    &.primary-link {
      background: transparent;
      border-color: transparent;
      text-decoration: none;
      color: currentColor;
      padding: 0;
      &.theme- {
        $themes: map-get($button-themes, 'primary-link');
        @each $theme, $value in $themes {
          $color: map-get($value, 'color');
          &#{'' + $theme} {
            color: $color;
            &.bordered {
              padding: 0 24px;
              border-color: $color;
            }
          }
        }
      }
      &.no-icon {
        padding: 0;
      }

      &.bordered {
        &.no-icon {
          padding: 0 24px;
        }
      }
      &.ani- {
        &link-swipe {
          padding-left: 0;
          .e-icon {
            opacity: 1;
          }
          &.bordered {
            padding-left: 48px;
            padding-right: 16px;
            &.no-icon {
              padding: 0 24px;
              width: fit-content;
            }
            .e-icon {
              opacity: 0;
            }
            &:hover {
              .e-icon {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    &.reverse {
      flex-direction: row-reverse;
    }
    &.icon-link {
      background: transparent;
      text-decoration: none;
      border-color: transparent;
      padding: 0;
      &.ani- {
        &link-swipe {
          .e-icon {
            position: static;
            opacity: 1;
            transform: translateX(0);
          }
          &:hover {
            &:not(.disabled) {
              .e-icon {
                transform: translateX(-8px);
                opacity: 1;
              }
            }
          }
          &.no-icon {
            padding: 0;
          }
          &.bordered {
            padding: 0 48px 0 16px;
            &.ani- {
              &link-swipe {
                .e-icon {
                  left: 16px;
                  transform: translateX(0);
                }
                &:hover {
                  &:not(.disabled) {
                    .e-icon {
                      transform: translateX(8px);
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &.no-icon {
              padding: 0 24px;
              width: fit-content;
            }
          }
        }
      }
      #{$c}__text {
        order: 2;
      }
      .e-icon {
        order: 1;
      }
      &.theme- {
        $themes: map-get($button-themes, 'primary-link');
        @each $theme, $value in $themes {
          $color: map-get($value, 'color');
          &#{'' + $theme} {
            color: $color;
            &.bordered {
              border-color: $color;
            }
          }
        }
      }
      &.ani- {
        &icon-fade-in {
          .e-icon {
            opacity: 0;
            transform: translateX(0);
            transition: all 600ms cubic-bezier(0.38, 0.015, 0, 0.995);
          }
          &:hover {
            .e-icon {
              opacity: 1;
              transform: translateX(-8px);
            }
          }
        }
      }
      &.reverse {
        justify-content: flex-end;
      }
    }
    &.size- {
      &small {
        height: 40px;
        @include h9;
      }
    }
    &.ani- {
      &icon-fade-in {
        #{$c}__text {
          position: relative;
          z-index: 1;
          transform: translateX(8px);
        }
        .e-icon {
          width: 16px;
          padding: 0;
          opacity: 0;
          transform: translateX(-8px);
        }
        &:hover {
          .e-icon {
            opacity: 1;
            transform: translateX(-8px);
            animation: fade-in-left 0.5s ease;
          }
        }
      }
    }
    #{$c}__loading {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.7;
    }
    &.fullWidthOnMobile {
      justify-content: center;
      width: 100%;
    }
    @keyframes fade-in-left {
      0% {
        opacity: 0;
        transform: translateX(-16px);
      }
      100% {
        opacity: 1;
        transform: translateX(-8px);
      }
    }
    @include tablet-landscape {
      &.side-padding {
        &-small {
          &,
          &.primary-link,
          &.icon-link {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
        &-middle {
          &,
          &.primary-link,
          &.icon-link {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
        &-large {
          &,
          &.primary-link,
          &.icon-link {
            padding-left: 64px;
            padding-right: 64px;
          }
        }
      }
      &.primary-button {
        min-width: 160px;
        &.theme- {
          $themes: map-get($button-themes, 'primary-button');
          @each $theme, $value in $themes {
            $bgColor: map-get($value, 'bgColor');
            $color: map-get($value, 'color');
          }
        }
      }
      &.primary-link {
        &.bordered {
          min-width: 160px;
        }
      }
      &.fullWidthOnMobile {
        width: fit-content;
      }
    }
  }
}
html[lang='ja-JP'] {
  .e-site-button {
    $c: '.e-site-button';
    &.old {
      #{$c}__text {
        font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
      }
    }
  }
}
html[lang='ko-KR'] {
  .e-site-button {
    $c: '.e-site-button';
    &.old {
      #{$c}__text {
        font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
      }
    }
  }
}
html.rtl {
  .e-site-button {
    &.old {
      &.primary-button,
      &.secondary-button {
        padding: 0 24px;
      }
    }
  }
}
</style>
