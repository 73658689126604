<script setup>
import { ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { gtmFormStart } from '@/utils/gtm-utils';
import { transitions } from '@/utils/transitions';
import { isNullOrEmpty } from '@/utils/obj-utils';
const emit = defineEmits(['submit']);
const props = defineProps({
  fields: {
    type: Object
  },
  page: {
    type: Object
  },
  image: {
    type: String
  },
  dealer: {
    type: Object
  }
});
const { formType } = props.page.fields;
const route = useRoute();
const router = useRouter();
const state = inject('send-to-dealer-state');
const calculation = inject('send-to-dealer-calculation');
const computes = inject('send-to-dealer-computes');
const methods = inject('send-to-dealer-methods');
const quoteOpened = ref(false);
const paymentBodyCollapsed = ref(true);
const dealerSelectorRef = ref();
const formRef = ref();
const dealerSelectModalRef = ref();
const formStarted = ref(false);
const onChangeDealer = async () => {
  const dealer = await dealerSelectModalRef.value.open(props.dealer);
  if (!formStarted.value) {
    formStarted.value = true;
    gtmFormStart(formType?.value, props.fields.form.id);
  }
  state.dealer = dealer;
};
const onFormChange = async (name, value) => {
  methods.onFormChange(name, value).catch();
  if (!formStarted.value) {
    formStarted.value = true;
    gtmFormStart(formType?.value, props.fields.form.id);
  }
};
const onCtChange = (ct) => {
  methods.setCustomerType(ct);
};
const onPayOptionChange = (e, payOption) => {
  methods.setPaymentOption(payOption);
};
const init = (dealers, dealer) => {
  formRef.value.setItemInfoMsg('comments', props.fields.commentsDesc.value);
  formRef.value.setDealers(dealers);
  const dealerControlProps = Object.create(null);
  if (!isNullOrEmpty(dealer)) {
    dealerControlProps.value = dealer;
  }
  if (state.usedCarDetail || (!isNullOrEmpty(state.configuration?.organization) && ![1, 3].includes(state.configuration.organization))) {
    dealerControlProps.readonly = true;
  }
  if (!isNullOrEmpty(dealerControlProps)) {
    formRef.value.updateItem('dealer', { controlProps: dealerControlProps });
  }
};
const updateFormItems = (payload) => {
  formRef.value.updateItems(payload);
};
const onChangeFinance = () => {
  methods.openFinanceCalculator();
};
const onSubmit = async () => {
  const [formValid, formData] = await formRef.value.validate();
  const consentNos = formRef.value.getVerifiedConsents();
  const leadTypes = formRef.value.getLeadTypes();
  if (!formValid) return;
  emit('submit', formData, consentNos, leadTypes);
  return formData;
};
defineExpose({ init, updateFormItems });
</script>

<template>
  <div class="s-std-form">
    <div class="s-std-form__main">
      <back-button class="mg-b-24" :fields="state.backButtonProps" />
      <jss-rich-text class="fs-32 __lh-1 ls-2" :field="fields.title" />
      <jss-rich-text class="__c-grey-neutral mg-b-40" :field="fields.body" />
      <dynamic-form :form="fields.form" :dealer-modal="fields.dealerSelectModal" @change="onFormChange" ref="formRef">
        <template #financeInterestContent v-if="state.carSeries?.financeLevel2">
          <div class="mg-t-24">
            <content-loading theme="white" :loading="state.financeCalculating">
              <template #loading>
                <spinner name="ball-beat" />
              </template>
              <site-accordion :title="$ifEmpty(calculation?.santander?.mappedProduct?.toggleText, fields?.quoteTitle?.value)" v-model:active="quoteOpened">
                <background-image class="mg-b-40" :image="fields.regulatoryImage" :is-background="false" />
                <template v-if="state.alpha2Code !== 'GB' && !['code-waver', 'ca-auto-bank'].includes(computes.dockCode.value)">
                  <ct-selector :options="state.customerTypes" :value="state.customerType" @change="onCtChange">
                    <template #title>
                      <jss-rich-text class="mg-b-16" :field="fields.customerTypeTitle" />
                    </template>
                  </ct-selector>
                  <template v-if="state.paymentOptions.length > 1 && !state.carSeries?.financeLevel2">
                    <jss-rich-text class="mg-t-24 mg-b-16" :field="fields.paymentOptionTitle" />
                    <div class="s-std-form__payment-options">
                      <div
                        class="s-std-form__payment-option"
                        :class="[{ active: state.paymentOption?.name === payment?.name }]"
                        v-html="payment.title"
                        v-for="payment in state.paymentOptions"
                        :key="payment.name"
                        @click.stop="onPayOptionChange($event, payment)"
                      />
                    </div>
                    <div class="text-desc" v-html="state.paymentOption?.description" />
                  </template>
                </template>
                <jss-text
                  class="hand tdl"
                  :field="paymentBodyCollapsed ? fields.readMoreText : fields.collapseText"
                  v-if="!$isNullOrEmpty(state.paymentOption?.body)"
                  @click="paymentBodyCollapsed = !paymentBodyCollapsed"
                />
                <transition :css="false" @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
                  <div v-if="!paymentBodyCollapsed">
                    <div class="text-desc mg-t-16" v-html="state.paymentOption?.body" />
                  </div>
                </transition>
                <div class="s-std-form__change-finance">
                  <icon :field="fields.changeFinanceIcon" @click="onChangeFinance" />
                  <jss-rich-text class="s-checkout-finance__change-finance-text" :field="fields.changeFinanceText" @click="onChangeFinance" />
                </div>
                <finance-quote-detail :state="state" :calculation="calculation" />
              </site-accordion>
            </content-loading>
          </div>
        </template>
      </dynamic-form>
      <site-button class="s-std-form__submit-btn" v-bind="fields.submitButton" :disabled="state.financeCalculating" @click="onSubmit" />
    </div>
    <div class="s-std-form__side">
      <img :src="image" alt="" />
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-std-form {
  $btn: '.e-site-button';
  &__side {
    display: none;
    background-color: $black;
  }
  &__main {
    padding: 40px 32px;
  }
  &__title {
    font-size: 32px;
    line-height: 1;
    letter-spacing: 2px;
  }
  .e-dealer-selector {
    &__btn {
      margin-left: 32px;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .e-form-textarea {
    &__info-msg {
      text-align: start;
    }
  }
  &__submit-btn {
    width: 100% !important;
  }
  &__payment-options {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__payment-option {
    overflow: hidden;
    padding: 16px;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.055, 0.215, 0, 1);
    border: 1px solid $black;
    cursor: pointer;
    user-select: none;
    &.active {
      background: $black;
      color: $white;
    }
  }
  &__change-finance {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    margin-top: 40px;
    .e-icon {
      cursor: pointer;
      > svg {
        width: 16px;
      }
    }
    .e-rich-text {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  @include tablet-landscape {
    @include grid-container;
    &__main {
      @include grid-block(3, 8);
      padding: 40px 0;
    }
    &__side {
      @include grid-block(13, 12);
      display: block;
      height: 100vh;
      position: sticky;
      top: 0;
      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &__payment-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
