<script setup>
import { sitecoreProps } from '@/utils/constants';
import { inject, ref, watch } from 'vue';
import useAppStore from '@/store/appStore';

const props = defineProps({
  page: {
    type: Object
  },
  steps: {
    type: Array,
    default: () => []
  },
  stepIndex: {
    type: Number,
    default: 1
  },
  ...sitecoreProps
});
const emit = defineEmits(['update:step-index']);
/**@type AppStore*/
const appStore = useAppStore();
const toast = inject('toast');
const { fields, methods, currentStep } = inject('reservation');
const prevButton = ref(null);
const onPrev = ref(() => {});
const init = (_carSeries, hideVerify) => {
  const { powertrainStep, dealerStep, accountStep, detailsStep, paymentStep } = fields;
  const _steps = [];
  if (dealerStep?.fields) {
    _steps.push({
      id: dealerStep.id,
      name: 'dealer',
      text: dealerStep?.fields.stepTitle?.value,
      hidden: dealerStep?.fields.hideSteps?.value
    });
  }
  if (powertrainStep?.fields && _carSeries?.models?.length > 0) {
    _steps.push({
      id: powertrainStep.id,
      name: 'powertrain',
      text: powertrainStep?.fields?.stepTitle?.value,
      hidden: powertrainStep?.fields.hideSteps?.value
    });
  }
  if (accountStep?.fields && !hideVerify) {
    _steps.push({
      id: accountStep.id,
      name: 'account',
      text: accountStep?.fields?.stepTitle?.value,
      hidden: accountStep?.fields.hideSteps?.value
    });
  }
  if (detailsStep?.fields) {
    _steps.push({
      id: detailsStep.id,
      name: 'details',
      text: detailsStep?.fields?.stepTitle?.value,
      hidden: detailsStep?.fields.hideSteps?.value
    });
  }
  if (paymentStep?.fields) {
    _steps.push({
      id: paymentStep.id,
      name: 'payment',
      text: paymentStep?.fields?.stepTitle?.value,
      hidden: paymentStep?.fields.hideSteps?.value
    });
  }
  return _steps;
};
const onEditPowertrain = () => {
  const index = props.steps.findIndex((x) => x.name === 'powertrain');
  if (index >= 0) {
    emit('update:step-index', index + 1);
  }
};
watch(
  () => currentStep.value,
  (cs) => {
    if (['account'].includes(cs?.name) && props.steps.some((x) => x.name === 'powertrain')) {
      prevButton.value = fields.editPowertrainButton;
      onPrev.value = onEditPowertrain;
    } else {
      prevButton.value = fields.exitButton;
      onPrev.value = methods.onExit;
    }
  },
  {
    immediate: true
  }
);
defineExpose({ init });
</script>

<template>
  <div class="s-reservation-steps" :class="[{ hidden: steps.length >= stepIndex && steps[stepIndex - 1].hidden }]" v-if="!$isOnApp($route)">
    <div class="s-reservation-steps__mobile" v-if="$deviceComputes.isMobileOrTablet.value">
      <site-button class="s-reservation-steps__prev" v-bind="prevButton" @click="onPrev" />
      <div class="s-reservation-steps__progress">{{ stepIndex }} / {{ steps.length }}</div>
    </div>
    <div class="s-reservation-steps__tablet" v-else>
      <div class="s-reservation-steps__step" :class="[{ finished: index < stepIndex - 1, current: index === stepIndex - 1 }]" v-for="(step, index) in steps" :key="step.id">
        <div class="s-reservation-steps__step-text" v-html="step.text" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-reservation-steps {
  &.hidden {
    display: none;
  }
  &__mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -24px;
    margin-bottom: 48px;
    .e-site-button {
      color: $grey-neutral !important;
      font-size: 12px;
      font-weight: 400;
      background: transparent !important;
      border-color: transparent !important;
      &__text {
        order: 2 !important;
      }
      &__icon {
        order: 1 !important;
        transform: rotate(180deg) translateX(0);
      }
      &.disabled {
        border-color: $grey-light !important;
        background: $grey-light !important;
        color: $grey-next !important;
      }
      &:hover {
        .e-site-button__icon {
          opacity: 1 !important;
          transform: rotate(180deg) translateX(8px) !important;
        }
      }
    }
  }
  &__tablet {
    display: flex;
    padding-left: grid-width(2);
    padding-right: grid-width(2);
    overflow: hidden;
  }
  &__step {
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: visible;
    position: relative;
    text-align: center;
    padding-bottom: 54px;
    font-size: 12px;
    &-text {
      position: absolute;
      top: 0;
      left: 4px;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      white-space: nowrap;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid $grey-taubmans;
      position: absolute;
      bottom: 28px;
    }
    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid $grey-taubmans;
      background: $white;
      position: absolute;
      left: 0;
      bottom: 24px;
    }
    &:last-child {
      flex-grow: 0;
      width: 8px;
      &:before {
        width: 0;
      }
    }
    &.current {
      &:after {
        border-color: $black;
        background: $yellow;
      }
      &:first-child {
        &:before {
          border-bottom-color: $black;
        }
        &:after {
          background: $yellow;
        }
      }
    }
    &.finished {
      &:before {
        border-bottom-color: $black;
      }
      &:after {
        border-color: $black;
        background: $black;
      }
    }
  }
}
html.rtl {
  .s-reservation-steps {
    &__step {
      &:first-child {
        flex-grow: 0;
        width: 8px;
        &:before {
          width: 0;
        }
      }

      &:last-child {
        flex-grow: 1;
        width: auto;
        &:before {
          width: 100%;
        }
      }
    }
  }
}
</style>
