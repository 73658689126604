<template>
  <div class="s-merchandise-checkout-verify" v-if="fields" v-show="stepCode === 'account'">
    <div class="s-merchandise-checkout-verify__main" v-show="!captchaHasSent">
      <Heading class="s-merchandise-checkout-verify__title text-primary" :field="fields.title" :type="fields.titleType" rich />
      <JssRichText class="s-merchandise-checkout-verify__subtitle" :field="fields.subtitle" />
      <DynamicForm :form="fields.accountForm" ref="accountFormRef" @change="onAccountFormChange" />
      <div class="s-merchandise-checkout-verify__actions">
        <SiteButton v-bind="fields.sendButton" :disabled="$isNullOrEmpty(email)" @click="onSendCode" />
      </div>
    </div>
    <div class="s-merchandise-checkout-verify__captcha" v-show="captchaHasSent">
      <JssRichText class="s-merchandise-checkout-verify__title text-primary" :field="fields.verifyTitle" />
      <div class="mg-b-24" v-html="$formatString(fields.verifySubtitle?.value, { email, seconds })" />
      <CaptchaInput class="mg-b-24" v-model:value="captcha" :label="fields.captchaInput?.fields?.label?.value" ref="captchaInputRef" />
      <Transition :css="false" @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
        <div v-show="captchaHasSent && seconds === 0">
          <a class="s-merchandise-checkout-verify__resend ib label-primary mg-b-32" :class="[{ disabled: resendDisabled }]" @click="onResend">{{ fields.resendText?.value }}</a>
        </div>
      </Transition>
      <div class="s-merchandise-checkout-verify__actions">
        <SiteButton v-bind="fields.verifyButton" :disabled="captcha?.length < 6 || verifying || (captchaHasSent && seconds <= 0)" @click="onVerify" />
      </div>
    </div>
    <div class="s-merchandise-checkout-verify__countries" v-if="!$isNullOrEmpty(currentCountry)" v-html="$formatString(fields.changeCountryText?.value, { country: currentCountry?.countryName })" />
    <NoticeModal v-bind="fields.switchCountryModal" ref="switchCountryModalRef" />
  </div>
</template>
<script>
import { reactive, onMounted, toRefs, inject, watch, computed, onBeforeUnmount } from 'vue';
import { GET_CAPTCHA_SCENE, getCaptchaExecutor } from '@/utils/captcha-utils';
import { merge } from 'lodash';
import { CAPTCHA_SCENE, captchaTypes } from '@/utils/constants';
import { transitions } from '@/utils/transitions';
import { getSiteError } from '@/services/siteService';
import useAppStore from '@/store/appStore';
import BUS_EVENTS from '@/utils/bus-events';
export default {
  name: 'MerchandiseCheckoutVerify',
  props: {
    step: {
      type: Number
    },
    stepCode: {
      type: String
    },
    bound: {
      type: Object
    },
    fields: {
      type: Object
    },
    currency: {
      type: String,
      default: null
    },
    bindRequired: {
      type: Boolean
    }
  },
  setup(props) {
    let codeTimeout = null;
    let getCaptcha, checkCaptcha;
    const appStore = useAppStore();
    const $bus = inject('$bus');
    const loading = inject('loading');
    const toast = inject('toast');
    const appMethods = inject('appMethods');
    const merchandiseCheckoutMethods = inject('merchandiseCheckoutMethods');
    const [turnOffGeeTest] = appMethods.getGlobalConfigs('turnOffGeeTest');

    const state = reactive({
      accountFormRef: null,
      captchaInputRef: null,
      email: null,
      captcha: null,
      captchaHasSent: false,
      verifying: false,
      seconds: 0,
      switchCountryModalRef: null,
      currentCountry: null
    });
    const computes = {
      resendDisabled: computed(() => !state.captchaHasSent || state.seconds > 0)
    };
    watch(
      () => state.captcha,
      (_captcha) => {
        if (_captcha?.length === 6) {
          methods.onVerify().catch();
        }
      }
    );
    watch(
      () => [appStore.hasLoggedIn, props.bound],
      ([hasLoggedIn, bound]) => {
        [getCaptcha, checkCaptcha] = getCaptchaExecutor(GET_CAPTCHA_SCENE.CHECKOUT, hasLoggedIn && !bound.email);
      }
    );
    const _methods = {
      startCodeCountdown(seconds) {
        const fn = (seconds) => {
          state.seconds = seconds;
          if (seconds > 0) {
            codeTimeout = setTimeout(() => {
              fn(--seconds);
            }, 1000);
          }
        };
        fn(seconds);
      },
      onPopState: async () => {
        switch (global.location.hash) {
          case '#change-market': {
            const { buttonCode } = await state.switchCountryModalRef.open();
            if (buttonCode === 'ok') {
              $bus.emit(BUS_EVENTS.OPEN_REGION_MODAL);
            }
            break;
          }
          default:
            break;
        }
      }
    };
    const methods = {
      onAccountFormChange: (name, value) => {
        if (name === 'email') {
          state.email = value;
        }
      },
      async onSendCode() {
        const [valid, data] = await state.accountFormRef.validate();
        if (valid) {
          const { email } = data;
          loading.show();
          let geeResult = null;
          if (!turnOffGeeTest?.value) {
            geeResult = await merchandiseCheckoutMethods.validateGeeTest();
          }
          const body = merge(
            {
              captchaType: captchaTypes.email,
              captchaScene: CAPTCHA_SCENE.registerLogin,
              email
            },
            geeResult
          );
          let res, ex;
          if (props.bindRequired) {
            merge(body, {
              captchaScene: CAPTCHA_SCENE.bindEmail
            });
          }
          [res, ex] = await getCaptcha(null, body);
          if (ex) {
            await toast.showEx(ex);
            loading.hide();
            return;
          }
          state.email = email;
          state.captchaHasSent = true;
          _methods.startCodeCountdown(60);
          loading.hide();
        }
      },
      onResend() {
        if (!computes.resendDisabled.value) {
          methods.onSendCode();
        }
      },
      async onVerify() {
        if (state.verifying) return;
        state.verifying = true;
        loading.show();
        const body = {
          captchaType: captchaTypes.email,
          captcha: state.captcha,
          email: state.email
        };
        if (props.bindRequired) {
          merge(body, {
            captchaScene: CAPTCHA_SCENE.bindEmail
          });
        }
        const [, ex] = await checkCaptcha(null, body);
        if (ex) {
          loading.hide();
          state.captchaInputRef.setErrorMsg(props.fields.captchaInput?.fields?.errorMsg?.value ?? 'Verification failed');
          state.verifying = false;
          return;
        }
        if (codeTimeout) {
          clearTimeout(codeTimeout);
        }
        state.captchaInputRef.setSuccessMsg(props.fields.captchaInput?.fields?.successMsg?.value ?? 'Verification success');

        setTimeout(() => {
          merchandiseCheckoutMethods.goNext({ email: state.email });
        }, 1000);
      }
    };
    onMounted(() => {
      [getCaptcha, checkCaptcha] = getCaptchaExecutor(GET_CAPTCHA_SCENE.CHECKOUT, appStore.hasLoggedIn && !props.bound.email);
      window.addEventListener('popstate', _methods.onPopState);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('popstate', _methods.onPopState);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      transitions
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-checkout-verify {
  &__resend {
    @include h9;
    line-height: 1.5;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
  &__countries {
    margin-top: 24px;
  }
  &__actions {
    .e-site-button {
      width: 100%;
    }
  }
}
</style>
