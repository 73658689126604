<script setup>
import { computed, inject, ref, watch } from 'vue';
import api from '@/api';
import { toFistLetterUpper } from '@/utils/string-utils';

const props = defineProps({
  carSeries: {
    type: Object
  },
  carModel: {
    type: Object
  }
});
const emit = defineEmits(['update:carModel']);
const { fields, methods, prevStep, nextStep } = inject('reservation');
const powertrainFields = computed(() => fields.powertrainStep?.fields);
const nextButton = computed(() => {
  const btnName = `to${toFistLetterUpper(nextStep.value?.name)}Button`;
  if (fields[btnName]) {
    return {
      ...fields[btnName],
      disabled: !props.carModel
    };
  }
  return null;
});
const onSelect = (e, item) => {
  emit('update:carModel', item);
};
const onPrevClick = () => {
  methods.onExit();
};
const onNextClick = () => {
  methods.onNext();
};
</script>

<template>
  <div class="s-reservation-powertrain" v-if="carSeries?.models?.length > 0 && powertrainFields">
    <div class="s-reservation-powertrain__content">
      <jss-rich-text class="text-primary" :field="powertrainFields.title" />
      <jss-rich-text class="text-desc mg-b-24" :field="powertrainFields.body" />
      <div class="s-reservation-powertrain__items" v-if="carSeries?.models?.length > 0">
        <div class="s-reservation-powertrain__item" :class="[{ active: carModel === item }]" @click="onSelect($event, item)" v-for="(item, index) in carSeries.models" :key="index">
          <div class="s-reservation-powertrain__item-selector">
            <site-radio :checked="carModel === item" />
          </div>
          <div class="s-reservation-powertrain__item-name fs-26 ls-2" v-html="item.name" v-if="!$isNullOrEmpty(item.name)" />
          <div class="s-reservation-powertrain__item-price" v-html="item.price" v-if="!$isNullOrEmpty(item.price)" />
          <div class="s-reservation-powertrain__item-desc" v-html="item.description" v-if="!$isNullOrEmpty(item.description)" />
        </div>
      </div>
    </div>
    <reservation-toolbar :prev-button="fields.exitButton" :next-button="nextButton" @prev-click="onPrevClick" @next-click="onNextClick" />
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-reservation-powertrain {
  $c: &;
  &__content {
    min-height: calc(100vh - nth($header-height, 1) - 104px);
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 24px 32px 24px;
    border: 1px solid $grey-89;
    transition: all 0.2s cubic-bezier(0, 0, 0.4, 0.2);
    cursor: pointer;
    &-name {
      line-height: 24px;
    }
    &-desc {
      font-size: 12px;
      color: $grey-next;
    }
    &:hover {
      border-color: $grey-next;
    }
    &.active {
      border-color: $black;
    }
  }
  @include tablet-landscape {
    min-height: calc(100vh - #{nth($header-height, 2)} - 204px);
  }
  @include desktop {
    min-height: calc(100vh - #{nth($header-height, 3)} - 204px);
  }
}
</style>
