import { S_BOUND_INFO, S_LOGIN_INFO } from '@/utils/web-storage-keys';
import { webStorage } from '@/utils/web-storage';
import api from '@/api';

export const getBoundInfo = async (token) => {
  const cache = webStorage.get(S_BOUND_INFO);
  if (!cache[token]) {
    [cache[token]] = await api.cidp.bindable(null, null, {
      headers: {
        authorization: token
      }
    });
  }
  return cache[token];
};
export const getCurrentBoundInfo = async () => {
  const loginInfo = webStorage.get(S_LOGIN_INFO);
  return getBoundInfo(loginInfo?.token);
};
