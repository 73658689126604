<template>
  <div class="s-collapse">
    <template v-for="(item, index) in dataSource" :key="index">
      <div class="s-collapse__item" v-if="!item?.nextLevels?.every((x) => x.nextLevels.every((y) => y.nextLevels.every((z) => $isNullOrEmpty(z.configurationFeature?.featureName))))">
        <div class="s-collapse__header" @click="handleCollapse(item)">
          <div class="s-collapse__label">
            {{ item?.levelName }}
          </div>
          <Icon name="down" class="s-collapse__arrow" :class="{ open: item.open }" />
        </div>
        <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
          <div class="s-collapse__content" v-show="item.open">
            <div class="s-collapse__content__inner">
              <template v-for="val in item?.nextLevels">
                <template v-for="v in val?.nextLevels">
                  <template v-for="j in v?.nextLevels" :key="j?.levelName">
                    <div class="s-collapse__content__inner__item" @click="showDescription(j)">
                      <div>{{ val.levelName }}:</div>
                      <div>{{ j.configurationFeature.featureName }}</div>
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </div>
        </transition>
      </div>
    </template>
    <Modal class="s-collapse__modal" ref="modalRef" closeLabel>
      <div class="s-collapse__description">
        <div class="s-collapse__description-img">
          <img :src="$adaptiveValueField(description.pcImg, description.phoneImg)" :alt="description.familyEnglishName" />
        </div>
        <div class="s-collapse__description-main">
          <div class="s-collapse__description-main-title">{{ description.featureName }}</div>
          <div class="s-collapse__description-main-body">{{ description.saleLongComment }}</div>
          <div class="s-collapse__description-main-btn">
            <SiteButton v-bind="closeBtn" @click="hideDescription()" />
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { transitions } from '@/utils/transitions';
import { largeThanTablet } from '@/utils/dom-utils';

export default {
  name: 'collapse',
  props: {
    dataSource: {
      type: Array
    },
    closeBtn: {
      type: Object
    }
  },
  setup() {
    const state = reactive({
      modalRef: null,
      description: null
    });

    const methods = {
      largeThanTablet,
      handleCollapse(item) {
        item.open = !item.open;
      },
      showDescription: (item) => {
        state.description = item.configurationFeature;
        state.modalRef.open();
      },
      hideDescription: () => {
        state.modalRef.close();
      }
    };
    return {
      ...toRefs(state),
      ...methods,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.s-collapse {
  $c: &;

  &__item {
    border-bottom: 2px solid $grey-light;
    font-size: 16px;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    cursor: pointer;

    #{$c}__arrow {
      transition: all 0.3s;
      svg,
      path {
        width: 12px;
      }
      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    color: $grey-next;

    &__inner {
      margin-bottom: $space-40;
      &__item {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        div:first-child {
          width: 200px;
          flex-shrink: 0;
        }
      }
    }
  }

  &__modal {
    .e-modal__content {
      width: calc(100% - 32px);
      height: fit-content;
      max-height: calc(100% - 128px);
      margin: 88px 16px 40px;
      #{$c}__description {
        display: flex;
        flex-direction: column;
        &-img {
          height: 20vh;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        &-main {
          height: calc(80vh - 128px);
          padding: 40px 24px;
          &-title {
            font-size: 18px;
            font-weight: bold;
            line-height: 23px;
            margin-bottom: 24px;
            text-transform: uppercase;
          }
          &-body {
            max-height: calc(80vh - 328px);
            font-size: 18px;
            font-weight: lighter;
            line-height: 26px;
            margin-bottom: 16px;
            overflow: auto;
            word-wrap: break-word;
          }
          &-btn {
            .e-site-button {
              width: 100%;
            }
          }
        }
      }
      @include tablet-landscape {
        width: 70vw;
        height: 50vh;
        margin: 0;
        #{$c}__description {
          height: 100%;
          flex-direction: row;
          &-img {
            width: 50%;
            height: 100%;
          }
          &-main {
            width: 50%;
            height: 100%;

            &-body {
              max-height: calc(50vh - 200px);
            }
          }
        }
      }
    }
  }
}
</style>
