import { webStorage } from '@/utils/web-storage';
import { S_CATEGORIES, S_SERVER_CART_INFO } from '@/utils/web-storage-keys';
import { APP_CONST, GOODS_DISABLE_TYPE } from '@/utils/constants';
import { isArray, isNullOrEmpty, isNumber } from '@/utils/obj-utils';
import api from '@/api';
import { equalString, ifEmpty } from '@/utils/string-utils';
import { merge } from 'lodash';
import { getCurrentAlpha2Code } from '@/utils/site-utils';

export const getCategoriesByCode = async (classifyCode) => {
  if (isNullOrEmpty(classifyCode)) return [];
  const { lang } = APP_CONST;
  const cache = webStorage.get(S_CATEGORIES) ?? {};
  if (!isArray(cache[lang])) {
    const [res] = await api.shop.category.queryByCode({
      classifyCode
    });
    cache[lang] = res ?? [];
    webStorage.set(S_CATEGORIES, cache);
  }
  return cache[lang];
};
export const getCategory = async (classifyCode, id) => {
  const categories = await getCategoriesByCode(classifyCode);
  if (!isNumber(id)) id = Number(id);
  return categories.find((x) => x.id === id);
};
export const getServerCartInfo = async (forceUpdate = false) => {
  const { lang, market } = APP_CONST;
  const alpha2Code = getCurrentAlpha2Code();
  const cache = webStorage.get(S_SERVER_CART_INFO) ?? {};
  if (!isArray(cache[lang]) || forceUpdate) {
    const [res, ex] = await api.shop.cart.query(null, {
      market: APP_CONST.market.code
    });
    if (ex) {
      return;
    }
    const buildItem = (item, disabled) => {
      const { id, name, description, skuId, skuImage, mainImage, quantity, sellStock, weight, specList, priceInfoList, status, spuStatus } = item;
      const itemInfo = {
        market: market.code,
        spuId: id,
        skuId,
        quantity,
        checked: !disabled,
        disabled,
        detail: {
          name,
          description,
          image: ifEmpty(skuImage, mainImage),
          stock: sellStock,
          specs: specList.map((x) => ({
            name: x.propertyName,
            value: x.propertyValue
          })),
          status,
          spuStatus,
          weight
        }
      };
      if (priceInfoList?.length > 0) {
        const priceInfo = priceInfoList.find((x) => equalString(x.country, alpha2Code));
        if (!isNaN(priceInfo?.price))
          merge(itemInfo.detail, {
            price: priceInfo.price,
            currency: priceInfo.currency
          });
      }
      checkDisableStatus(itemInfo);
      return itemInfo;
    };
    const result = [];
    for (let item of res.ableCartItems) {
      result.push(buildItem(item, false));
    }
    for (let item of res.disableCartItems) {
      result.push(buildItem(item, true));
    }
    cache[lang] = result;
    webStorage.set(S_SERVER_CART_INFO, result);
  }
  return cache[lang] ?? [];
};
export const checkDisableStatus = (cartItem) => {
  if (cartItem?.detail?.stock === 0) {
    cartItem.detail.disableType = GOODS_DISABLE_TYPE.outOfStock;
    cartItem.disabled = true;
    return true;
  } else if (cartItem?.detail?.status === 0 || [60291001, 60291002, 60291004].includes(cartItem?.detail?.spuStatus)) {
    cartItem.detail.disableType = GOODS_DISABLE_TYPE.offShelf;
    cartItem.disabled = true;
    return true;
  } else if (isNaN(cartItem?.detail?.price)) {
    cartItem.detail.disableType = GOODS_DISABLE_TYPE.noPrice;
    cartItem.disabled = true;
    return true;
  }
  return false;
};
