<script setup>
/**@typedef ReservationDealerFields
 * @property {SimpleField} title
 * @property {SimpleField} body
 * */
import { computed, getCurrentInstance, inject, nextTick, ref } from 'vue';
import { getPageAlpha2Code, scrollToTop } from '@/utils/site-utils';
import { getSalesAgentList } from '@/services/agentService';
import services from '@/services';
import { toFistLetterUpper } from '@/utils/string-utils';

const props = defineProps({
  dealers: {
    type: Array
  },
  dealer: {
    type: Object
  }
});
const emit = defineEmits(['update:dealers', 'update:dealer']);
const { fields, methods, currentStep, nextStep, contactButton } = inject('reservation');
const dealerFields = computed(() => fields.dealerStep?.fields);
const { proxy } = getCurrentInstance();
const { $jss } = proxy;
const formRef = ref();
const init = async () => {
  const page = $jss.routeData();
  const alpha2Code = getPageAlpha2Code(page);
  return await getSalesAgentList(page, alpha2Code);
};
const nextButton = computed(() => {
  let btnName = `to${toFistLetterUpper(nextStep.value?.name)}Button`;
  if (props.dealer?.stripeOpen === 0) {
    return contactButton.value;
  }
  if (fields[btnName]) {
    return {
      ...fields[btnName],
      disabled: !props.dealer
    };
  }
  return null;
});
const onFormChange = async (name, value) => {
  const page = $jss.routeData();
  const alpha2Code = getPageAlpha2Code(page);
  if (name === 'zipCode') {
    const geoResult = await services.map.geoDecode(page, {
      componentRestrictions: {
        postalCode: value,
        country: alpha2Code
      }
    });
    if (!geoResult) return;
    const dealerList = await getSalesAgentList(props.page, alpha2Code, {
      latitude: geoResult.lat,
      longitude: geoResult.lng
    });
    emit('update:dealers', dealerList);
    if (dealerList?.length) {
      const selectedOption = dealerList.find((x) => x.code === props.dealer?.code);
      emit('update:dealer', selectedOption);
    }
    await nextTick();
    scrollToTop();
  }
};
const onDealerClick = (val) => {
  emit('update:dealer', val);
};
const onPrevClick = () => {
  methods.onExit();
};
const onNextClick = (e) => {
  if (!props.dealer?.stripeOpen) return true;
  else {
    const zipCode = formRef.value.getItemValueByName('zipCode');
    methods.fillZipCode(zipCode);
    methods.onNext(e);
    return false;
  }
};
defineExpose({ init });
</script>

<template>
  <div class="s-reservation-dealer" v-if="dealerFields">
    <div class="s-reservation-dealer__content">
      <div class="s-reservation-dealer__form">
        <jss-rich-text class="text-primary" :field="dealerFields.title" />
        <jss-rich-text class="text-desc" :field="dealerFields.body" />
        <dynamic-form :form="dealerFields.form" ref="formRef" @change="onFormChange" />
      </div>
      <dealer-radio-list :dealers="dealers" :dealer="dealer" @dealer-click="onDealerClick" />
    </div>
    <reservation-toolbar :prev-button="fields.exitButton" :next-button="nextButton" @prev-click="onPrevClick" @next-click="onNextClick" />
  </div>
</template>

<style scoped lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-reservation-dealer {
  min-height: calc(100vh - nth($header-height, 1) - 104px);
  &__form {
    position: sticky;
    top: 0;
    z-index: 1;
    background: $white;
    padding-top: 24px;
  }
  @include tablet-landscape {
    min-height: calc(100vh - #{nth($header-height, 2)} - 204px);
    .e-dealer-radio-list {
      max-height: calc(100vh - 450px);
      padding-right: 16px;
      overflow-y: auto;
    }
  }
  @include desktop {
    min-height: calc(100vh - #{nth($header-height, 3)} - 204px);
  }
}
</style>
