<template>
  <div class="s-sc-filter-menu">
    <div class="s-sc-filter-menu__line" />
    <div class="s-sc-filter-menu__content">
      <filter-menu-section :title="fields.filterTitle?.value" v-model:opened="isModelOpen">
        <radio-list :options="modelList" @change="onModelChange" :selected-option="model" :column-count-mobile="1" :column-count-tablet="1" :column-count-desktop="1" />
      </filter-menu-section>
      <template v-for="fl in filterLabels" :key="fl.code">
        <filter-menu-section :title="fl.text" v-if="filters[fl.code]?.length > 0" :opened="true">
          <div class="s-sc-filter-menu__options">
            <div class="s-sc-filter-menu__option" :class="[{ checked: item.selected }]" v-for="item in filters[fl.code]" :key="item.code">
              <div class="s-sc-filter-menu__option-label">{{ $toCapitalize(item.value) }}</div>
              <checkbox :value="item.selected" @change="onFilterToggle(item)" />
            </div>
          </div>
        </filter-menu-section>
      </template>
    </div>
    <div class="s-sc-filter-menu__ctas">
      <site-button v-bind="fields?.resetFilterCta" @click="handleReset" />
      <site-button v-bind="fields?.resultCta" @click="getResult" />
    </div>
  </div>
</template>

<script>
/**
 * @typedef FilterRef
 * @property {() => Object} init
 * */
import { computed, getCurrentInstance, inject, reactive, toRefs } from 'vue';
import api from '@/api';
import { equalString } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { formatModel, getPageAlpha2Code } from '@/utils/site-utils';
import querystring from 'querystring';
import { transitions } from '@/utils/transitions';
import { debounce } from 'lodash';

export default {
  name: 'filter-menu',
  emits: ['handleFilter', 'update:model'],
  props: {
    fields: {
      type: Object
    },
    language: {
      type: String
    },
    series: {
      type: String
    }
  },
  setup(props, ctx) {
    const toast = inject('toast');
    const { proxy } = getCurrentInstance();
    const visibleFilterNames = ['lambda-car-config-color', 'lambda-car-config-interior-theme', 'lambda-car-config-hub-model'];
    const state = reactive({
      page: null,
      alpha2Code: null,
      modelList: [],
      filters: {
        exteriorColors: [],
        themes: [],
        wheelsList: []
      },
      model: null,
      filterLabels: [],
      isModelOpen: true
    });
    const selectedExteriorColors = computed(() => state.filters.exteriorColors.filter((x) => x.selected).map((x) => x.code));
    const selectedThemes = computed(() => state.filters.themes.filter((x) => x.selected).map((x) => x.code));
    const selectedWheelsList = computed(() => state.filters.wheelsList.filter((x) => x.selected).map((x) => x.code));
    const _methods = {
      fetchFilter: debounce(async () => {
        const body = {
          country: state.alpha2Code,
          model: state.model.code,
          series: props.series
        };
        if (selectedExteriorColors.value.length > 0) {
          body.exteriorPaintOptionId = selectedExteriorColors.value;
        }
        if (selectedThemes.value.length > 0) {
          body.themeOptionId = selectedThemes.value;
        }
        if (selectedWheelsList.value.length > 0) {
          body.wheelsOptionId = selectedWheelsList.value;
        }
        const [res, ex] = await api.stock.filter(null, body);
        if (ex) {
          toast.showEx(ex);
          return;
        }
        _methods.updateFilter('exteriorColors', res.exteriorPaintInfoList);
        _methods.updateFilter('themes', res.themeInfoList);
        _methods.updateFilter('wheelsList', res.wheelsInfoList);
      }, 50),
      updateFilter(name, val) {
        const filter = state.filters[name];
        if (!filter.some((x) => x.selected)) {
          state.filters[name] =
            val?.map((x) => ({
              ...x,
              selected: false
            })) ?? [];
        }
      }
    };
    const methods = {
      /**
       * 获取车型列表
       * @returns {Promise<boolean>}
       */
      async getModelList() {
        const { $jss } = proxy;
        const page = $jss.routeData();
        const [res, ex] = await api.stock.models(null, {
          series: props?.series,
          language: page.itemLanguage
        });
        if (ex) {
          await toast.showEx(ex);
          return false;
        }
        state.modelList = res.map((x) => ({
          code: formatModel(x.model),
          text: formatModel(x.model)
        }));
      },
      async onModelChange(e, index, option) {
        state.model = state.modelList.find((x) => x.code === option.code);
        await _methods.fetchFilter();
      },
      async onFilterToggle(option) {
        option.selected = !option.selected;
        await _methods.fetchFilter();
      },
      /**
       * 筛选数据
       */
      getResult() {
        ctx.emit('handleFilter', state.model, {
          exteriorPaintOptionId: selectedExteriorColors.value,
          themeOptionId: selectedThemes.value,
          wheelsOptionId: selectedWheelsList.value
        });
      },
      /**
       * 重置
       */
      handleReset() {
        state.model = null;
        state.filters.exteriorColors = [];
        state.filters.themes = [];
        state.filters.wheelsList = [];
      },
      /**
       * 关闭filter滤镜
       */
      handleClose() {
        ctx.emit('close');
      },
      /**
       * 展开/关闭菜单
       * @param target
       * @param data
       */
      isMenuOpen(target, data) {
        data.some((item) => {
          if (item?.id === target?.id) {
            item.open = !item.open;
            return true;
          } else {
            methods.isMenuOpen(target, item?.nextLevels);
          }
        });
      },
      async init(modelName) {
        const { $jss } = proxy;
        const page = $jss.routeData();
        const { filterLabels } = props.fields ?? {};
        state.page = page;
        state.alpha2Code = getPageAlpha2Code(page);
        if (!isNullOrEmpty(filterLabels?.value)) {
          const filterLabelsObj = querystring.parse(filterLabels?.value);
          const keys = Object.keys(filterLabelsObj);
          state.filterLabels = keys.map((x) => ({
            code: x,
            text: filterLabelsObj[x],
            opened: false
          }));
        }
        await methods.getModelList();
        if (!isNullOrEmpty(modelName)) {
          const activeModel = state.modelList.find((x) => equalString(x.code, modelName));
          state.model = activeModel;
          if (!isNullOrEmpty(state.model)) {
            await _methods.fetchFilter();
          }
          return activeModel;
        }
        return null;
      }
    };
    return {
      ...toRefs(state),
      ...methods,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.s-sc-filter-menu {
  $c: &;
  $rl: #{'.e-radio-list'};
  $ck: #{'.e-form-checkbox'};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  &__content {
    padding: 0 32px 60px;
    height: calc(100vh - 196px);
    overflow-y: auto;
  }
  .e-radio-list {
    &__error-box {
      display: none;
    }
  }
  .s-uc-fm-sec {
    + .s-uc-fm-sec {
      border-top: 1px solid $grey-89;
      margin-top: 40px;
    }
  }
  &__line {
    margin: 0 32px;
    border-top: 1px solid $grey-89;
  }
  &__options {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
  }
  &__option {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    $this: &;

    padding: $space-32 0;
    border-bottom: 1px solid $grey-89;
    overflow: hidden;

    &__head {
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 4px;
    }
    &__title {
      text-transform: capitalize;
    }
    &__arrow {
      width: 12px;
      height: 12px;
      background: $white;
      cursor: pointer;
      position: relative;
      &:before {
        content: '';
        width: 100%;
        height: 2px;
        background: $black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: $black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        transition: all 0.3s;
      }
    }

    &__body {
      color: $grey-next;
    }

    &__attr {
      display: flex;
      margin-top: $space-24;
      .e-form-checkbox {
        &.checked {
          #{$ck}__check {
            &:before {
              width: 12px;
              height: 12px;
              opacity: 1;
            }
          }
        }
      }
      &.checked {
        #{$c}__item__attr-name {
          color: $black;
        }
      }
      &-name {
        flex-grow: 1;
        margin-right: 24px;
        padding-top: 4px;
      }
      .e-form-checkbox {
        flex-shrink: 0;
      }
    }

    &.close {
      #{$this}__arrow {
        &:after {
          content: '';
          width: 100%;
          height: 2px;
          background: $black;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }

    &.shut {
      #{$this}__arrow {
        &:after {
          content: '';
          width: 100%;
          height: 2px;
          background: $black;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
    &:last-child {
      border: none;
    }
  }

  &__ctas {
    background: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 24px;
    .e-site-button {
      flex-grow: 1;
    }
  }
  #{$rl}__items {
    margin-top: 24px;
  }
  #{$rl}__item {
    justify-content: space-between;
    &-label {
      order: 1;
      margin-left: 0;
    }
    &-radio {
      order: 2;
      background: transparent;
    }
    &.checked {
      #{$rl}__item-radio {
        background: $white;
        &:before {
          background: $black;
        }
      }
    }
  }
}
</style>
