<template>
  <div class="s-merchandise-checkout-summary-plan">
    <div class="s-merchandise-checkout-summary-plan-header">
      <div class="s-merchandise-checkout-summary-plan-header-title">
        {{ $t('Order summary') }}
      </div>
    </div>
    <div class="s-merchandise-checkout-summary-plan-main" v-if="skuDetails">
      <div class="s-merchandise-checkout-summary-plan-list">
        <div class="s-merchandise-checkout-summary-plan-list-item">
          <div class="s-merchandise-checkout-summary-plan-list-item-img">
            <img :src="skuDetails.skuImageSeo?.skuImages[0]?.pic ?? skuDetails.spuMainImage" :alt="skuDetails.skuBusId" />
          </div>
          <div class="s-merchandise-checkout-summary-plan-list-item-main">
            <div class="s-merchandise-checkout-summary-plan-list-item-top">
              <div class="s-merchandise-checkout-summary-plan-list-item-main-name">
                {{ spuDetails.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="s-merchandise-checkout-summary-plan-delivery">
        <div class="s-merchandise-checkout-summary-plan-delivery-title">{{ $t('Details') }}</div>
        <div class="s-merchandise-checkout-summary-plan-delivery-details">
          <div class="s-merchandise-checkout-summary-plan-delivery-sku">
            <div class="s-merchandise-checkout-summary-plan-delivery-details-title">{{ $tu('Vehicle') }}</div>
            <div class="s-merchandise-checkout-summary-plan-delivery-details-val">
              <span>{{ $t('Model') }}:</span>
              <span>{{ carDetails.model }}</span>
            </div>
            <div class="s-merchandise-checkout-summary-plan-delivery-details-val">
              <span>{{ $t('Vin') }}:</span>
              <span>{{ carDetails.vin }}</span>
            </div>
          </div>
          <div class="s-merchandise-checkout-summary-plan-delivery-billing" v-if="deliveryDetails">
            <div class="s-merchandise-checkout-summary-plan-delivery-details-title">{{ $tu('Personal information') }}</div>
            <div>{{ `${deliveryDetails.personalData.firstName} ${deliveryDetails.personalData.lastName}` }}</div>
            <div>{{ deliveryDetails.billingData.address }}</div>
            <div>{{ deliveryDetails.billingData.city }}</div>
            <div>{{ deliveryDetails.billingData.country.text }}</div>
            <div>{{ `+${deliveryDetails.personalData.mobile?.area.code} ${deliveryDetails.personalData.mobile?.number}` }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-merchandise-checkout-summary-plan-price">
      <div class="s-merchandise-checkout-summary-plan-price-top">
        <JssRichText
          class="s-merchandise-checkout-summary-plan-price-top-left"
          :field="fields.summaryLabel"
          @click="!largeThanTablet() && toggleDis()"
          @mouseover="largeThanTablet() && showDis()"
          @mouseout="largeThanTablet() && hideDis()"
        />
        <div class="s-merchandise-checkout-summary-plan-price-top-right">{{ price }}</div>
        <div class="s-merchandise-checkout-summary-plan-price-top-disclaimer" v-html="$formatString(fields.priceDisclaimer?.value, { price: price })" v-if="showDisclaimer && fields.priceDisclaimer" />
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive } from 'vue';
import { largeThanTablet } from '@/utils/dom-utils';

export default {
  name: 'merchandise-checkout-summary-plan',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    },
    spuDetails: {
      type: Object
    },
    skuDetails: {
      type: Object
    },
    carDetails: {
      type: Object
    },
    price: {
      type: String
    },
    currency: {
      type: String,
      default: 'EUR'
    },
    deliveryDetails: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      showDisclaimer: false
    });
    const methods = {
      largeThanTablet,
      toggleDis: () => {
        state.showDisclaimer = !state.showDisclaimer;
      },
      showDis: () => {
        state.showDisclaimer = true;
      },
      hideDis: () => {
        state.showDisclaimer = false;
      }
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-checkout-summary-plan {
  height: 100vh;
  display: flex;
  flex-direction: column;
  &-header {
    padding: 0 24px;
    &-title {
      font-size: 26px;
      line-height: 32px;
      margin-top: 40px;
    }
    &-count {
      font-size: 12px;
      line-height: 20px;
      margin-top: 20px;
    }
  }
  &-main {
    flex: 1;
    padding: 24px;
    overflow: auto;
  }
  &-delivery {
    margin-top: 72px;
    padding-top: 24px;
    border-top: 1px solid $grey-night;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
    &-details {
      display: flex;
      flex-direction: column;
      gap: 24px;
      &-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: $white;
      }
      &-val {
        display: flex;
        gap: 5px;
      }
      > div {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-next;
      }
    }
  }
  &-list {
    &-item {
      display: flex;
      flex-direction: row;
      gap: 27px;
      margin-bottom: 24px;
      &-img {
        width: 80px;
        height: 100px;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-name {
          margin-bottom: 5px;
          font-size: 12px;
          font-weight: 700;
          line-height: 20px;
          color: $white !important;
        }
        > div {
          font-size: 12px;
          line-height: 20px;
          color: $grey-taubmans;
        }
      }
    }
  }
  &-price {
    width: 100%;
    color: $white;
    padding: 16px 24px 24px;
    border-top: 1px solid var(--shades-nshadeb, $grey-dark);
    background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    &-top {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-left {
        font-size: 12px;
        line-height: 24px;
        display: flex;
        cursor: pointer;
        > p,
        div,
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
        }
      }
      &-right {
        font-size: 16px;
        line-height: 24px;
      }
      &-disclaimer {
        position: absolute;
        bottom: 52px;
        padding: 24px;
        background-color: $grey-light;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 4px;
      font-size: 12px;
      line-height: 20px;
      margin-top: 12px;
      .e-icon {
        display: flex;
        align-items: center;
      }
    }
  }
  @include tablet-landscape {
    &-header {
      padding: 0 grid-width(2);
      &-title {
        margin-top: 0;
      }
    }
    &-main {
      margin-top: 50px;
      padding: 0 grid-width(2);
    }
    &-delivery {
      margin-top: 66px;
      padding-top: 24px;
      border-top: 1px solid $grey-night;
      display: flex;
      flex-direction: column;
      gap: 24px;
      &-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
      &-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        &-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          color: $white;
        }
        > div {
          flex: 1;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: $grey-next;
        }
      }
    }
    &-price {
      padding: 24px grid-width(2);
      &-top {
        &-left {
          font-size: 16px;
          line-height: 24px;
        }
        &-right {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
