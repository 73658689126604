<script setup>
/**@typedef OneFormTradeInRef
 * @property {() => void} open
 * */
import { reactive, inject, computed } from 'vue';
import useAppStore from '@/store/appStore';
import { sitecoreProps } from '@/utils/constants';
import { isNullOrEmpty } from '@/utils/obj-utils';
import utils from '@/utils';
const emit = defineEmits(['closed']);
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreProps
});
/**@type AppStore*/
const appStore = useAppStore();
const rootState = inject('one-form-state');
const state = reactive({
  /**@type ModalRef*/
  modalRef: null,
  /**@type DynamicForm*/
  formRef: null
});
const secFields = computed(() => utils.oneForm.buildInheritFields(props.fields));
const form = computed(() => utils.oneForm.buildFormItems(secFields.value.formItems));
const addTradeIn = async () => {
  const [valid, formData] = await state.formRef.validate();
  if (!valid) return;
  rootState.tradeInData = formData;
  state.modalRef.close();
};
const open = async (data) => {
  if (!isNullOrEmpty(data)) {
    const updateData = {},
      keys = Object.keys(data);
    for (let key of keys) {
      updateData[key] = {
        controlProps: {
          value: data[key]
        }
      };
    }
    state.formRef.updateItems(updateData);
  }
  await state.modalRef.open();
};
const onClose = () => {
  emit('closed');
};
defineExpose({ open });
</script>

<template>
  <modal class="s-of-trade-in-modal" animation="right-slide-in" closable :remove-on-hidden="false" @close-click="onClose" :ref="(e) => (state.modalRef = e)" v-if="secFields">
    <div class="s-of-trade-in-modal__content">
      <div class="s-of-trade-in-modal__main">
        <div class="mg-b-24">
          <jss-rich-text class="fs-20 mg-b-8" :field="secFields.title" />
          <jss-rich-text class="__ft-pm" :field="secFields.body" />
        </div>
        <dynamic-form :form="form" :ref="(e) => (state.formRef = e)" />
      </div>
      <div class="s-of-trade-in-modal__buttons">
        <site-button v-bind="secFields.button" @click="addTradeIn" />
      </div>
    </div>
  </modal>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.s-of-trade-in-modal {
  $m: '.e-modal';

  &#{$m} {
    align-items: flex-end;
    #{$m}__content {
      width: 100%;
      height: calc(100vh - 62px);
      padding: 64px 0 0 0;
      overflow: hidden;
    }
  }
  &__content {
    height: 100%;
    overflow-y: auto;
    .e-site-button {
      width: 100%;
    }
    .s-dynamic-form {
      padding-top: 8px;
      padding-bottom: 0;
    }
  }
  &__main {
    padding: 0 grid-width-m(1);
  }
  &__buttons {
    position: sticky;
    background-color: $white;
    bottom: 0;
    padding: 16px grid-width-m(1) 40px grid-width-m(1);
  }
  @include tablet-landscape {
    &#{$m} {
      #{$m}__content {
        width: grid-width(8);
        height: 100vh;
      }
    }
    &__main {
      padding: 0 32px;
    }
    &__buttons {
      padding: 16px 32px 40px 32px;
    }
  }
}
</style>
