<template>
  <div class="s-vehicle-card" :class="{ 'grid-layout': layout === 'grid' }">
    <!--    左侧轮播图-->
    <div class="swiper s-vehicle-card__preview" ref="swiperEl">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in dataSource?.images" :key="index">
          <img :src="item?.externalUrl" alt="" />
        </div>
      </div>
      <div class="s-vehicle-card__pagination" ref="paginationEl"></div>
    </div>
    <div class="s-vehicle-card__detail">
      <div class="s-vehicle-card__model">
        <div class="s-vehicle-card__model__type">{{ $formatModel(dataSource?.model) }}</div>
      </div>
      <template v-for="item in configuration" :key="item.levelName">
        <div class="s-vehicle-card__attr" v-if="!$isNullOrEmpty(configArray?.find((c) => equalString(c.label, item.levelName))?.val)">
          <div class="s-vehicle-card__attr__label">{{ item.levelName }}:</div>
          <div class="s-vehicle-card__attr__value">
            <span v-for="(val, index) in item.nextLevels" :key="index">{{ configArray?.find((c) => equalString(c.label, item.levelName))?.val }}</span>
          </div>
        </div>
      </template>
      <div class="s-vehicle-card__price" v-if="dataSource?.showPrice">{{ $formatMoney(dataSource?.price, dataSource?.currency || 'EUR') }}</div>
      <div class="s-vehicle-card__toolbar">
        <div class="s-vehicle-card__ctas">
          <SiteButton :fields="detailCtaProps" @click="showDetails" />
        </div>
        <div class="s-vehicle-card__delivery">
          {{ dataSource?.deliveryDate ?? deliveryText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import { reactive, toRefs, onMounted, inject, getCurrentInstance, watch } from 'vue';
import { useRouter } from 'vue-router';
import { appendQuery, getBetterUrl, redirectTo } from '@/utils/uri-utils';
import { deepCopy } from '@/utils/obj-utils';
import { equalString } from '@/utils/string-utils';
import api from '@/api';
import { getGlobalConfigs } from '@/utils/site-utils';
import { tryParseJson } from '@/utils/dom-utils';
import { gtmPush } from '@/utils/gtm-utils';

export default {
  name: 'stock-vehicle-card',
  props: {
    dataSource: {
      type: Object
    },
    deliveryText: {
      type: String
    },
    detailCta: {
      type: Object
    },
    orderCta: {
      type: Object
    },
    layout: {
      type: String
    },
    country: {
      type: String
    }
  },
  setup(props) {
    let swiper = null;
    const toast = inject('toast');
    const loading = inject('loading');
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const state = reactive({
      swiperEl: null,
      paginationEl: null,
      configuration: tryParseJson(props?.dataSource?.configuration),
      filterIds: tryParseJson(props?.dataSource?.filterIds),
      labelEl: [],
      detailCtaProps: deepCopy(props?.detailCta?.fields),
      orderCtaProps: deepCopy(props?.orderCta?.fields),
      configArray: []
    });
    const params = {
      vin: props?.dataSource?.vin
    };
    // if (state.detailCtaProps?.link?.value) {
    //   state.detailCtaProps.link.value.href = appendQuery(state?.detailCtaProps?.link?.value?.href, params);
    // }
    if (state.orderCtaProps?.link?.value) {
      state.orderCtaProps.link.value.href = null;
    }
    const methods = {
      equalString,
      buildSwiper() {
        if (swiper) {
          swiper.destroy(true, true);
        }
        swiper = new Swiper(state.swiperEl, {
          spaceBetween: 1,
          pagination: {
            el: state.paginationEl,
            bulletElement: 'div',
            clickable: true
          }
        });
      },
      async onOrder() {
        loading.show();
        const [res, ex] = await api.order.stockDetailByVin(null, {
          country: props?.country,
          vin: props?.dataSource?.vin
        });
        if (!res) {
          loading.hide();
          await toast.showEx(ex);
          return;
        }
        const page = $jss.routeData();
        const [btoLink] = getGlobalConfigs(page, 'btoLink');
        const btoHref = btoLink?.value?.href ?? 'order';
        window.location.href = appendQuery(getBetterUrl(btoHref, page.itemLanguage, true), {
          transfer_code: res.transferCode,
          filter_id: res?.filterId,
          vin: res?.vin,
          vid: res?.vid
        });
      },
      getFeatures: (item, _this) => {
        if (equalString(item.level, '3')) {
          item.nextLevels.forEach((l) => {
            if (!_this.val) {
              _this.val = `${l.configurationFeature.featureName}`;
            } else {
              _this.val += `, ${l.configurationFeature.featureName}`;
            }
          });
        } else {
          item.nextLevels.forEach((l) => {
            methods.getFeatures(l, _this);
          });
        }
      },
      getConfigDesc: () => {
        state.configArray.forEach((c) => {
          const configuration = state.configuration.find((i) => equalString(i.levelName, c.label));
          configuration?.nextLevels?.forEach((l) => {
            methods.getFeatures(l, c);
          });
        });
      },
      showDetails: () => {
        const page = $jss.routeData();
        const [stockDetailLink] = getGlobalConfigs(page, 'stockDetailLink');
        const detailHref = appendQuery(stockDetailLink?.value?.href, params);
        let currency = 'EUR',
          region = 'GB',
          language = 'en';
        if (page.itemLanguage !== 'en') {
          [language, region] = page.itemLanguage.split('-');
        }
        gtmPush({
          event: 'select_car',
          list_name: 'stock_car_lister',
          cars: [
            {
              model_name: props.dataSource.model,
              version_name: props.dataSource.series,
              filter_id: props.dataSource.filterId,
              transfer_code: props.dataSource.transferCode,
              vehicle_type: 'stock',
              price: props.dataSource.price,
              index: 0
            }
          ],
          region,
          language,
          currency
        });
        redirectTo({ url: detailHref, router });
      }
    };

    watch(
      () => state.configuration,
      (v) => {
        if (v?.length > 0) {
          v.forEach((i) => {
            state.configArray.push({
              label: i.levelName,
              val: ''
            });
          });
          methods.getConfigDesc();
        }
      },
      { deep: true, immediate: true }
    );

    onMounted(() => {
      methods.buildSwiper();
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.s-vehicle-card {
  $c: &;
  display: block;
  width: 100%;
  &__preview {
    overflow: hidden;
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    #{$c}__pagination {
      position: absolute;
      left: 50%;
      bottom: $space-32;
      transform: translate(-50%, 0);
      display: flex;
      align-items: center;
      z-index: 1;

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background: $white;
        margin-left: 16px;
        border-radius: 100%;
        cursor: pointer;

        &:first-child {
          margin: 0;
        }
      }

      .swiper-pagination-bullet-active {
        background: $yellow;
      }
    }
  }
  &__detail {
    padding: $space-40 $space-24;
    border: 2px solid $grey-light;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    #{$c}__model {
      display: flex;
      justify-content: space-between;
      margin-bottom: $space-24;

      &__type {
        font-size: 32px;
        letter-spacing: 2px;
        line-height: 1;
      }

      &__year {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
      }
    }

    #{$c}__attr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 24px;

      &__label {
        width: 112px;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__value {
        flex: 1;
        font-size: 16px;
        color: $grey-next;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: $space-20;
      }
    }

    #{$c}__price {
      font-size: 24px;
      line-height: 32px;
      margin-top: $space-24;
    }

    #{$c}__ctas {
      margin-top: $space-32;
      display: flex;
      .e-site-button {
        width: 100%;
        //&:last-child {
        //  margin-left: $space-8;
        //}
      }
    }

    #{$c}__delivery {
      font-size: 12px;
      line-height: 20px;
      margin-top: 16px;
      color: rgba($grey-neutral, 0.8);
      &:empty {
        display: none;
      }
    }
  }
  @include tablet-landscape {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    &.grid-layout {
      height: 100%;
      display: flex;
      flex-direction: column;
      #{$c}__preview {
        flex-shrink: 0;
      }

      #{$c}__detail {
        flex-grow: 1;
        position: relative;
        padding: 32px 32px 148px;
      }
      #{$c}__toolbar {
        position: absolute;
        bottom: 32px;
        width: calc(100% - 64px);
      }
    }
    &__preview {
      @include grid-block(1, 7);
    }
    &__detail {
      @include grid-block(8, 12);
      padding: 0 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      #{$c}__price {
        margin-top: 12px;
      }
      #{$c}__ctas {
        margin-top: 18px;
      }
    }
  }
  @include desktop-normal {
    &__preview {
      #{$c}__price {
        margin-top: 24px;
      }
      #{$c}__ctas {
        margin-top: 32px;
      }
    }
    &__delivery {
      margin-top: 32px;
    }
  }
}
</style>
