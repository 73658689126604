<template>
  <div class="s-tooltip__m-tooltip" @mouseover="onShow" @mouseleave="onHide" @click.stop="isMobile || isTablet ? onShow : ''">
    <div
      ref="titleRef"
      class="s-tooltip__m-title"
      :class="{ 's-tooltip__show-tip': visible }"
      :style="isMobile() || isTablet() ? `width: 100vw; top: ${top}px; left: 0px;` : `max-width: ${maxWidth}px; top: ${top}px; left: ${left}px;`"
    >
      <div class="s-tooltip__u-title" :style="isMobile() || isTablet() ? `width: 100vw; top: ${top}px; left: 0px;` : ``">
        <icon name="close-common" class="s-tooltip__close" @click.stop="onHide" />
        <slot name="title">{{ title }}</slot>
      </div>
      <div class="s-tooltip__m-arrow" :style="isMobile() || isTablet() ? `left: ${left}px;` : ``">
        <span class="s-tooltip__u-arrow"></span>
      </div>
    </div>
    <div ref="contentRef" class="s-tooltip__u-content">
      <slot>{{ content }}</slot>
    </div>
  </div>
</template>
<script>
import { isMobile, isTablet } from '@/utils/dom-utils';
import { onMounted, onUnmounted, reactive, toRefs } from 'vue';
import { debounce } from 'lodash';
export default {
  name: 'Tooltip',
  props: {
    maxWidth: {
      // 提示框内容最大宽度
      type: Number,
      default: 120
    },
    content: {
      // 展示的文本
      type: String,
      default: '暂无内容' // string | slot
    },
    title: {
      // 提示的文本
      type: String,
      default: '暂无提示' // string | slot
    }
  },
  setup(props) {
    const state = reactive({
      hideTimer: null,
      top: 0, // 提示框top定位
      left: 0, // 提示框left定位
      visible: false,
      contentRef: null,
      titleRef: null
    });
    onMounted(() => {
      methods.getPosition();
      document.addEventListener('scroll', methods.getScrollFn);
      document.addEventListener('click', (e) => {
        if (e.target.className != 'c-comparison__icon') {
          state.visible = false;
        }
      });
    });
    onUnmounted(() => {
      document.removeEventListener('click', () => {
        return null;
      });
      document.removeEventListener('scroll', methods.getScrollFn);
    });
    const methods = {
      isMobile,
      isTablet,
      getPosition: () => {
        const rect = state.contentRef?.getBoundingClientRect();
        const targetTop = rect.top;
        const targetLeft = rect.left;
        const targetWidth = rect.width;
        const titleWidth = state.titleRef?.offsetWidth; // 提示文本宽度
        const titleHeight = state.titleRef?.offsetHeight; // 提示文本高度
        state.top = targetTop - titleHeight;
        state.left = targetLeft - (titleWidth - targetWidth) / 2;
        if (isMobile() || isTablet()) {
          state.left = targetLeft + 7.5;
        }
      },
      getScrollFn() {
        state.hideTimer = setTimeout(() => {
          state.visible = false;
        }, 100);
      },
      onShow: debounce(() => {
        clearTimeout(state.hideTimer);
        methods.getPosition();
        state.visible = true;
      }, 200),
      onHide: debounce(() => {
        state.hideTimer = setTimeout(() => {
          state.visible = false;
        }, 100);
      }, 200)
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-tooltip {
  &__m-tooltip {
    display: inline-block;
  }
  &__close {
    width: 12px;
    height: 12px;
    color: #000000;
    -webkit-tap-highlight-color: transparent;
  }
  &__m-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 999;
    padding-bottom: 12px;
    pointer-events: none;
    opacity: 0;
    transform-origin: 50% 75%;
    transform: scale(0.8); // 缩放变换
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari and Chrome */
    transition: transform 0.25s, opacity 0.25s;
    padding: 32px 12px 12px;
    // gap: 10px;
    .s-tooltip__u-title {
      padding: 32px 16px 16px;
      word-break: break-all;
      word-wrap: break-word;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #4f4f4f;
      .e-icon {
        display: inline-block;
        font-size: 0;
        letter-spacing: -3px;
        right: 20px;
        top: 40px;
        position: absolute;
        cursor: pointer;
      }
    }
    .s-tooltip__m-arrow {
      position: absolute;
      z-index: 9;
      bottom: 12px;
      left: 50%;
      transform: translate(-50%, 100%);
      width: 15.55px;
      height: 10px;
      border-radius: 0 0 5px 5px;
      overflow: hidden;
      .s-tooltip__u-arrow {
        position: absolute;
        left: 50%;
        top: 0px;
        transform: translate(-50%, -50%) rotate(45deg);
        margin: 0 auto;
        width: 11px;
        height: 11px;
        background: #fff;
        border-radius: 0 0 3px 0;
        z-index: 8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
  &__show-tip {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1); // 缩放变换
    -ms-transform: scale(1); /* IE 9 */
    -webkit-transform: scale(1); /* Safari and Chrome */
  }
  &__u-content {
    cursor: pointer;
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    -webkit-tap-highlight-color: transparent;
  }
  @include tablet-landscape {
    &__m-tooltip {
      display: inline-block;
    }
    &__m-title {
      position: fixed;
      z-index: 999;
      padding-bottom: 12px;
      pointer-events: none;
      opacity: 0;
      transform-origin: 50% 75%;
      transform: scale(0.8); // 缩放变换
      -ms-transform: scale(0.8); /* IE 9 */
      -webkit-transform: scale(0.8); /* Safari and Chrome */
      transition: transform 0.25s, opacity 0.25s;
      padding: 32px 24px 12px;
      .s-tooltip__u-title {
        padding: 32px 24px 24px 24px;
        margin: 0 36px;
        word-break: break-all;
        word-wrap: break-word;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        color: #4f4f4f;
        .e-icon {
          display: inline-block;
          font-size: 0;
          letter-spacing: -3px;
          right: 68px;
          top: 42px;
          position: absolute;
          cursor: pointer;
        }
      }
      .s-tooltip__m-arrow {
        position: absolute;
        z-index: 9;
        bottom: 12px;
        left: 50%;
        transform: translate(-50%, 100%);
        width: 15.55px;
        height: 10px;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        .s-tooltip__u-arrow {
          position: absolute;
          left: 50%;
          top: 0px;
          transform: translate(-50%, -50%) rotate(45deg);
          margin: 0 auto;
          width: 11px;
          height: 11px;
          background: #fff;
          border-radius: 0 0 3px 0;
          z-index: 8;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
    &__show-tip {
      pointer-events: auto;
      opacity: 1;
      transform: scale(1); // 缩放变换
      -ms-transform: scale(1); /* IE 9 */
      -webkit-transform: scale(1); /* Safari and Chrome */
    }
    &__u-content {
      cursor: pointer;
      font-size: 14px;
      line-height: 1.5;
      color: #000000;
    }
  }
}
</style>
