<script setup>
import { nextTick, onMounted, reactive } from 'vue';
import Swiper from 'swiper';
let swiper;
const state = reactive({
  swiperEl: null,
  paginationEl: null,
  images: []
});
const buildSwiper = () => {
  swiper = new Swiper(state.swiperEl, {
    slidesPerView: 1,
    pagination: {
      el: state.paginationEl,
      clickable: true
    }
  });
  console.log('swiper', swiper);
};
const init = (images) => {
  state.images = images;
  if (images.length > 0) {
    nextTick(() => {
      buildSwiper();
    });
  }
};
defineExpose({ init });
</script>

<template>
  <div class="one-form-swiper" v-if="state.images.length > 0">
    <div class="swiper" :ref="(e) => (state.swiperEl = e)">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-aspect-ratio="'16_9'" v-for="(src, i) in state.images" :key="i">
          <img :src="src" alt="" />
        </div>
      </div>
      <div class="swiper-pagination" :ref="(e) => (state.paginationEl = e)" />
    </div>
  </div>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.one-form-swiper {
  .swiper {
    .swiper-slide {
      aspect-ratio: 16/9;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .swiper-pagination {
      position: absolute;
      right: 32px;
      bottom: 24px;
      left: 32px;
      z-index: 1;
      height: 1px;
      background: rgba($grey-89, 0.6);
      display: flex;
      .swiper-pagination-bullet {
        flex-grow: 1;
        flex-shrink: 0;
        background-color: transparent;
        transition: background-color 0.2s cubic-bezier(0.355, 0.005, 0.26, 1);
        &-active {
          background-color: $white;
        }
      }
    }
  }
  @include tablet-landscape {
    .swiper {
      .swiper-pagination {
        left: grid-width(1);
        right: grid-width(1);
      }
    }
  }
}
</style>
