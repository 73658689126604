<template>
  <modal
    class="s-toast"
    :class="[{ confirm: showCancel && !$isNullOrEmpty(cancelText), 'has-icon': !$isNullOrEmpty(icon), middle }]"
    ref="modalRef"
    :animation="$deviceComputes.isMobileOrTablet.value ? 'bottom-slide-in' : 'bottom-fade-in'"
    :closable="closable"
    :sticky="sticky"
    @close-click="onCloseClick"
    @closed="onClosed"
  >
    <div class="s-toast__icon" v-if="icon">
      <icon :name="icon" />
    </div>
    <div class="s-toast__content" :class="[{ centered }]" v-html="message" />
    <div class="s-toast__buttons" :class="[{ single: $isNullOrEmpty(okText) || $isNullOrEmpty(cancelText) }]" v-if="okText || cancelText">
      <site-button v-bind="okProps" @click="onOk" />
      <site-button class="s-toast__cancel" v-bind="cancelProps" @click="onCancel" />
    </div>
  </modal>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';

export default {
  name: 'Toast',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let timeout = null;
    const state = reactive({
      /**@type ModalRef*/
      modalRef: null,
      closable: false,
      centered: false,
      middle: false,
      sticky: true,
      icon: null,
      message: null,
      showOk: false,
      okText: null,
      showCancel: false,
      cancelText: null,
      onOk() {},
      onCloseClick() {},
      onCancel() {},
      onClosed() {}
    });
    const computes = {
      okProps: computed(() => ({
        fields: {
          text: {
            value: state.okText
          },
          buttonType: {
            fields: {
              phrase: {
                value: 'primary-button'
              }
            }
          }
        }
      })),
      cancelProps: computed(() => ({
        fields: {
          theme: {
            fields: {
              phrase: {
                value: 'black'
              }
            }
          },
          text: {
            value: state.cancelText
          },
          buttonType: {
            fields: {
              phrase: {
                value: 'secondary-button'
              }
            }
          }
        }
      }))
    };
    const methods = {
      open(options) {
        state.icon = options?.icon;
        state.message = options?.message;
        state.closable = options?.closable ?? false;
        state.showOk = options?.showOk ?? false;
        state.showCancel = options?.showCancel ?? false;
        state.okText = options?.okText ?? null;
        state.cancelText = options?.cancelText ?? null;
        state.centered = !!options?.centered;
        state.middle = !!options?.middle;
        state.sticky = options?.sticky ?? false;
        state.onOk = () => {
          if (timeout) {
            clearTimeout(timeout);
          }
          state.modalRef.close();
          options?.onOk && options?.onOk();
        };
        state.onCancel = () => {
          if (timeout) {
            clearTimeout(timeout);
          }
          state.modalRef.close();
          options?.onCancel && options?.onCancel();
        };
        state.onCloseClick = () => {
          if (timeout) {
            clearTimeout(timeout);
          }
          state.modalRef.close();
          options?.onCancel && options?.onCancel();
        };
        state.onClosed = () => {
          options?.onClosed && options?.onClosed();
        };
        state.modalRef.open();
        if (!isNaN(options.duration)) {
          if (timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(() => {
            state.modalRef.close();
          }, options.duration ?? 3600);
        }
        return {
          updateMessage(message) {
            state.message = message;
          },
          close() {
            state.modalRef.close();
          }
        };
      },
      onOkClick(e) {
        state.onOk && state.onOk(e);
      },
      onCancelClick(e) {
        state.onCancel && state.onCancel(e);
      }
    };
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-toast {
  $c: &;
  $m: '.e-modal';
  &.e-modal {
    align-items: flex-end;
  }
  &.e-modal {
    &__close {
      top: 15px;
      right: 20px;
      svg {
        height: 18px;
      }
    }
    #{$m}__content {
      width: 100%;
      padding: 40px 24px 24px 24px;
    }
    &.confirm {
      #{$m}__close {
        svg {
          height: 32px;
        }
      }
      #{$m}__content {
        padding: 72px 40px 24px 40px;
      }
      #{$c}__buttons {
        margin-top: 40px;
        .e-site-button {
          height: 56px;
        }
      }
    }
    &.middle {
      align-items: center;
      #{$m}__content {
        width: grid-width-m(10);
      }
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &__content {
    &.centered {
      text-align: center;
    }
  }
  &__buttons {
    margin-top: $space-16;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .e-site-button {
      width: 100%;
    }
    &.single {
      justify-content: center;
    }
  }
  @include tablet-landscape {
    &.e-modal {
      align-items: center;
      #{$m}__close {
        top: 10px;
        right: $space-12;
      }
      #{$m}__content {
        width: grid-width(8);
      }
      &.has-icon,
      &.confirm {
        #{$m}__content {
          width: grid-width(8);
        }
      }
      &.middle {
        #{$m}__content {
          width: grid-width(8);
        }
      }
    }
    &.confirm {
      #{$c}__buttons {
        flex-direction: row;
        gap: 24px;
        .e-site-button {
          width: calc(50% - 12px);
          overflow: hidden;
        }
        &.single {
          .e-site-button {
            width: 100%;
          }
        }
      }
    }
  }
  @include desktop {
    &.e-modal {
      #{$m}__content {
        width: grid-width(6);
      }
      &.has-icon,
      &.confirm {
        #{$m}__content {
          width: grid-width(8);
        }
      }
    }
  }
}
</style>
