<script setup>
import { getCurrentInstance, ref, watch } from 'vue';
import { initStoreMap } from '@/utils/maps-utils';
const props = defineProps({
  store: {
    type: Object
  },
  iconField: {
    type: Object
  }
});
let map;
const { proxy } = getCurrentInstance();
const { $jss } = proxy;
const rootEl = ref();
const initMap = async (store) => {
  const page = $jss.routeData();
  map = await initStoreMap(rootEl.value, page, store, props.iconField);
  return map;
};
watch(
  () => props.store,
  (store) => {
    initMap(store);
  }
);
</script>

<template>
  <div class="s-store-map-view" ref="rootEl" />
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-store-map-view {
  width: 100%;
  height: 100vw;
  background: $blue-night;
  @include desktop {
    height: 50vw;
  }
}
</style>
