<script setup>
import { computed, getCurrentInstance, inject, nextTick, ref } from 'vue';
import { appendQuery, concatUrls, getBetterUrl } from '@/utils/uri-utils';
import { loadStripe } from '@/utils/stripe-utils';
import { webStorage } from '@/utils/web-storage';
import { S_RESERVATION_DETAIL } from '@/utils/web-storage-keys';
import { gtmPush } from '@/utils/gtm-utils';
import useAppStore from '@/store/appStore';
import { getGlobalConfigs } from '@/utils/site-utils';
import { useRoute } from 'vue-router';
import { isNullOrEmpty } from '@/utils/obj-utils';

const props = defineProps({
  carSeries: {
    type: Object
  },
  payFailed: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['update:payFailed']);
let stripeHelper, stripe, confirmPayment, orderInfo;
const { proxy } = getCurrentInstance();
const { $jss } = proxy;
const route = useRoute();
const toast = inject('toast');
const loading = inject('loading');
const fields = inject('fields');
/**@type AppStore*/
const appStore = useAppStore();
const paymentFields = computed(() => fields?.paymentStep?.fields);
const payWidgetRef = ref();
const initStripe = async (orderInfo) => {
  const page = $jss.routeData();
  stripeHelper = await loadStripe(page, toast);
  if (!stripeHelper) return;
  stripe = stripeHelper.initSdk(orderInfo);
  confirmPayment = stripeHelper.initWidget(stripe, payWidgetRef.value, orderInfo);
};
const onPay = async () => {
  loading.show();
  try {
    const redirectUrl = appendQuery(window.location.href, {}, ['payment_intent', 'payment_intent_client_secret', 'redirect_status']);
    const error = await confirmPayment(redirectUrl);
    if (error) {
      loading.hide();
    }
  } catch {
    loading.hide();
  }
};
const checkPayment = async (page, clientSecret, redirectStatus) => {
  orderInfo = webStorage.get(S_RESERVATION_DETAIL);
  const { productName, productVariant } = page?.fields || {};
  const { reservationCfmLink } = props.carSeries || {};
  let cfmHref = reservationCfmLink?.value?.href;
  console.log('cfmHref', cfmHref);
  if (isNullOrEmpty(cfmHref)) {
    const parts = route.path.split(/[\\/]/g);
    if (parts.length > 1) {
      cfmHref = concatUrls(`/${parts[parts.length - 2]}/${parts[parts.length - 1]}/confirmation`);
    }
  }
  const reservationCfmHref = getBetterUrl(cfmHref, page.itemLanguage, true);
  gtmPush({
    event: 'generate_lead',
    lead_id: clientSecret?.replace(/_secret/gi, ''),
    user_id: appStore.loginInfo?.lotusId,
    product_name: productName?.value,
    product_variant: productVariant?.value
  });
  if (redirectStatus === 'succeeded') {
    stripeHelper = await loadStripe(page, toast);
    stripe = stripeHelper.initSdk(orderInfo);
    await watching(clientSecret, reservationCfmHref);
  } else {
    emit('update:payFailed', true);
    await nextTick();
    stripeHelper = await loadStripe(page, toast);
    stripe = stripeHelper.initSdk(orderInfo);
    setTimeout(() => {
      confirmPayment = stripeHelper.initWidget(stripe, payWidgetRef.value, orderInfo);
      loading.hide();
    }, 500);
  }
};
const watching = async (clientSecret, detailHref) => {
  console.log('clientSecret', stripe);
  const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
  switch (paymentIntent.status) {
    case 'succeeded':
      if (!detailHref || !orderInfo?.orderId) return;
      setTimeout(() => {
        window.location = appendQuery(detailHref, {
          orderId: orderInfo.orderId
        });
      }, 1000);
      break;
    case 'processing':
      console.log('Your payment is processing.');
      setTimeout(() => {
        watching();
      }, 2000);
      break;
    case 'requires_payment_method':
    default:
      emit('update:payFailed', true);
      await nextTick();
      confirmPayment = stripeHelper.initWidget(stripe, payWidgetRef.value, orderInfo);
      loading.hide();
      break;
  }
};
defineExpose({ initStripe, checkPayment });
</script>

<template>
  <div class="s-reservation-payment" v-if="paymentFields">
    <div class="c-checkout-payment__failed mg-b-24" v-if="payFailed">
      <jss-rich-text class="c-build-to-order__failed-overline" :field="paymentFields.failedOverline" />
      <jss-rich-text class="c-build-to-order__failed-title" :field="paymentFields.failedTitle" />
      <jss-rich-text class="c-build-to-order__failed-message" :field="paymentFields.failedErrorMessage" />
      <jss-rich-text class="c-build-to-order__failed-subtitle" :field="paymentFields.failedSubtitle" />
      <jss-rich-text class="c-build-to-order__failed-body" :field="paymentFields.failedBody" />
    </div>
    <div v-else>
      <jss-rich-text class="text-primary" :field="paymentFields.title" />
    </div>
    <div ref="payWidgetRef" />
    <reservation-toolbar :prev-button="payFailed ? fields.contactButton : null" :next-button="payFailed ? fields.retryButton : fields.payButton" @next-click="onPay" />
  </div>
</template>

<style lang="scss"></style>
