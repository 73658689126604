<script setup>
import { computed, inject, onMounted, ref } from 'vue';
import Swiper from 'swiper';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { appendQuery, getBetterUrl } from '@/utils/uri-utils';
import { onWidthChanged } from '@/hooks/onWidthChanged';
import { debounce } from 'lodash';
import { toThousands } from '@/utils/math-utils';
import querystring from 'querystring';
import { encodeData } from '@/utils/site-utils';
import { formatDate } from '@/utils/date-utils';
import { equalString, formatString } from '@/utils/string-utils';

const props = defineProps({
  fields: {
    type: Object
  },
  page: {
    type: Object
  },
  item: {
    type: Object
  }
});
let swiper;
const { deviceComputes } = inject('device-common');
const rootEl = ref();
const swiperEl = ref();
const isApproved = computed(() => props.item.lotusApproval === 1);
const storeLink = computed(() => {
  const { cardStoreLink } = props.fields;
  let href = cardStoreLink?.value?.href;
  if (isNullOrEmpty(href) || isNullOrEmpty(props.item?.storeName)) {
    return '';
  }
  href = getBetterUrl(href);
  if (isNullOrEmpty(props.item?.storeCode)) {
    return props.item?.storeName;
  }
  const storeName = encodeURIComponent(props.item?.storeName);
  href = formatString(href, {
    storeName,
    storeCode: props.item?.storeCode,
    address: storeName,
    longitude: props.item?.longitude,
    latitude: props.item?.latitude
  });
  return `<a href="${href}" target="_blank">${props.item?.storeName}</a>`;
});
const infoLabels = computed(() => {
  const { cardInfoLabels } = props.fields;
  if (isNullOrEmpty(cardInfoLabels.value)) return [];
  const temp = querystring.parse(cardInfoLabels.value);
  const keys = Object.keys(temp);
  return keys.map((x) => ({
    code: x,
    text: temp[x]
  }));
});
const viewDetailButton = computed(() => {
  const { cardViewDetailButton } = props.fields;
  if (!cardViewDetailButton?.fields) return null;
  const { link, ...rest } = cardViewDetailButton.fields;
  if (isNullOrEmpty(link?.value?.href)) return null;
  let { href, ...linkRest } = link.value;
  return {
    fields: {
      ...rest,
      link: {
        value: {
          ...linkRest,
          href: appendQuery(href, {
            d: encodeData({
              id: props.item.id
            })
          })
        }
      }
    }
  };
});
const buildSwiper = () => {
  if (swiper) swiper.destroy();
  swiper = new Swiper(swiperEl.value, {
    autoHeight: true,
    pagination: {
      el: '.swiper-pagination'
    }
  });
};
const formatItemField = (key, val) => {
  if (equalString(key, 'productionTime')) return formatDate(val, 'yyyy');
  return val;
};
onWidthChanged({
  callback: debounce(() => {
    buildSwiper();
  }, 100)
});
onMounted(() => {
  if (props.item?.picList?.length > 1) {
    buildSwiper();
  }
});
</script>

<template>
  <div class="s-uc-card" ref="rootEl">
    <div class="s-uc-card__banner" v-if="fields.cardShowBanner.value && isApproved">
      <icon :field="fields.cardApprovedIcon" size="tiny" />
      <jss-text class="s-uc-card__banner-text" :field="fields.cardApprovedTitle" />
    </div>
    <div class="s-uc-card__swiper" ref="swiperEl">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="pic in item.picList" :key="pic.id">
          <div class="s-uc-card__img" v-aspect-ratio="'3_2'">
            <img :src="pic.url" alt="" />
          </div>
        </div>
      </div>
      <div class="swiper-pagination" />
    </div>
    <div class="s-uc-card__content">
      <div class="__ft-h3" v-html="item.model" />
      <div class="s-uc-card__price" v-html="$formatMoney(item.price, item.currency)" />
      <div class="s-uc-card__pairs __ft-pm">
        <template v-for="infoLabel in infoLabels" :key="infoLabel.code">
          <div class="s-uc-card__pair" v-if="!$isNullOrEmpty(item[infoLabel.code])">
            <span> {{ infoLabel.text }}:</span>
            <span>{{ formatItemField(infoLabel.code, item[infoLabel.code]) }}</span>
          </div>
        </template>
      </div>
      <div class="s-uc-card__store">
        <icon name="location" size="tiny" />
        <template-string class="s-uc-card__store-text" :field="fields.cardStoreText" :data="{ storeName: storeLink }" tag="span" v-if="!$isNullOrEmpty(storeLink)" />
        <template v-if="item.distance > 0">
          <template-string :field="fields.cardDistanceText" :data="{ distance: $formatDistance(item.distance, 'km', 0) }" tag="span" />
        </template>
      </div>
      <div class="s-uc-card__actions">
        <site-button v-bind="viewDetailButton" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-uc-card {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  &__banner {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 36px;
    background: radial-gradient(60.41% 90% at 49.82% 140%, rgba(46, 46, 46, 0.64) 0%, rgba(0, 0, 0, 0.8) 100%);
    padding: 8px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: $white;
    .e-icon {
      transform: translateX(-1px);
    }
    &-text {
      font-size: 8px;
      font-weight: 700;
      letter-spacing: 2px;
    }
  }
  &__swiper {
    position: relative;
    .swiper-pagination {
      position: absolute;
      z-index: 1;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: 16px;
    }
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background: $white;
      border-radius: 100%;
      cursor: pointer;
      &-active {
        background: $yellow;
      }
    }
  }
  &__img {
    width: 100%;
    aspect-ratio: 3/2;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
    padding: 40px 24px 124px 24px;
    border: 1px solid $grey-89;
    border-top: none;
  }
  &__price {
    font-family: lotusFontFamily('Overpass Medium'), sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
  }
  &__pairs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__pair {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
    > span {
      color: $grey-next;
      &:first-child {
        color: $black;
      }
    }
  }
  &__store {
    color: $grey-next;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    &-text {
      > a {
        color: $black;
        text-decoration: underline;
      }
      + span {
        display: flex;
        align-items: center;
        gap: 4px;
        &:before {
          content: '';
          height: 10px;
          border-left: 1px solid currentColor;
        }
      }
    }
  }
  &__actions {
    position: absolute;
    bottom: 40px;
    width: calc(100% - 64px);
    display: flex;
    flex-direction: column;
  }
  @include tablet-landscape {
    &__content {
      padding: 40px 32px 124px 32px;
    }
  }
}
</style>
