<template>
  <modal class="s-cancel-order-modal" ref="modalRef" :animation="$deviceComputes.isMobileOrTablet.value ? 'bottom-slide-in' : 'bottom-fade-in'" @closed="onClosed" closable>
    <div class="s-cancel-order-modal__title" v-html="$formatString(fields?.title.value, fData)" />
    <div class="s-cancel-order-modal__body" v-html="$formatString(fields?.body.value, fData)" />
    <dynamic-form :form="fields.form" :scrollToForm="false" ref="formRef" />
    <div class="s-cancel-order-modal__buttons">
      <template v-for="buttonItem in buttonItems" :key="buttonItem.id">
        <site-button :fields="buttonItem.button" :data-code="buttonItem.buttonCode" @click="onButtonClick($event, buttonItem)" />
      </template>
    </div>
  </modal>
</template>

<script>
/**
 * @typedef NoticeModalFields
 * @property {SimpleField} title
 * @property {SimpleField} body
 * @property {ButtonField} buttons
 * */
/**
 * @typedef CancelOrderModal
 * @property {NoticeModalFields} fields
 * */
import { sitecoreProps } from '@/utils/constants';
import { computed, inject, reactive, toRefs, onMounted } from 'vue';
import useAppStore from '@/store/appStore';
import { concatString } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { debounce } from 'lodash';
import { formatMoney } from '@/utils/math-utils';
import { getGlobalCfgValues } from '@/utils/site-utils';
import api from '@/api';

export default {
  name: 'CancelOrderModal',
  props: {
    fields: {
      type: Object
    },
    subTotal: {
      type: Number
    },
    orderCode: {
      type: String
    },
    page: {
      type: Object
    },
    ...sitecoreProps
  },
  setup(props, context) {
    const appMethods = inject('appMethods');
    const loading = inject('loading');
    const [currencyVal] = getGlobalCfgValues(props.page, ['currency']);
    const toast = inject('toast');
    /**@type AppStore*/
    const appStore = useAppStore();
    const state = reactive({
      /**@type ModalRef*/
      modalRef: null,
      activeButton: null,
      options: [],
      selectedOption: null,
      textareaVal: null,
      textareaValLength: 0,
      errorMsg: null,
      formRef: null,
      onClosed: () => {}
    });
    const computes = {
      buttonItems: computed(() => {
        const { buttons } = props.fields || {};
        if (!buttons?.length) return [];
        const result = [];
        for (let buttonItem of buttons) {
          if (!buttonItem?.fields) continue;
          const { buttonCode, link, ...otherFields } = buttonItem.fields;
          const buttonCodeVal = buttonCode.value;
          result.push({
            link: link,
            buttonCode: buttonCodeVal,
            button: otherFields
          });
        }
        return result;
      }),
      fData: computed(() => {
        const { firstName, middleName, lastName, email } = appStore?.loginInfo || {};
        if (isNullOrEmpty(firstName)) return appStore?.loginInfo?.nickName;
        return {
          name: concatString([firstName, middleName, lastName], ' '),
          email
        };
      })
    };
    const methods = {
      async open() {
        return new Promise((resolve) => {
          if (!state.modalRef) resolve(null);
          state.onClosed = () => {
            resolve(state.activeButton);
            state.activeButton = null;
          };
          state.modalRef.open();
        });
      },
      onButtonClick: debounce(async (e, buttonItem) => {
        if (!buttonItem) return;
        if (buttonItem.buttonCode === 'submit') {
          const [valid, formData] = await state.formRef.validate();
          if (valid) {
            loading.show();
            const [res, ex] = await api.shop.refundByCustomer(null, {
              orderCode: props.orderCode,
              refundReasonId: formData.reason.code,
              otherReason: formData.description,
              refundFee: props.subTotal,
              refundType: 51181001
            });
            if (ex) {
              await toast.showEx(ex);
              loading.hide();
              if (ex.code === 60000000) {
                await appMethods.logoutToLogin();
              }
              return;
            }
            loading.hide();
            state.activeButton = buttonItem;
            await state.modalRef?.close();
            context.emit('update');
          }
        } else {
          state.activeButton = buttonItem;
          await state.modalRef?.close();
        }
      }, 100),
      onChange(e, option) {
        state.selectedOption = option;
      },
      inputChange(e, val) {
        state.textareaVal = val;
        state.textareaValLength = val.length;
      }
    };
    onMounted(() => {});
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      appStore
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-cancel-order-modal {
  $c: &;
  $m: #{'.e-modal'};
  $btn: '.e-site-button';
  z-index: 4 !important;
  .e-modal__close {
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &.e-modal {
    align-items: flex-end;
    #{$m}__content {
      width: 100%;
      padding: 80px 24px 58px;
    }
  }
  &__title {
    margin-bottom: 24px;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    gap: 12px;
    .e-site-button {
      width: 100% !important;
      order: 2;
      &[data-code='refuse'],
      &[data-code='close'],
      &[data-code='no'] {
        color: $grey-next !important;
        #{$btn}__icon {
          display: none;
        }
      }
      &[data-code='cancel'] {
        order: 3;
      }
    }
  }
  @include tablet-landscape {
    &.e-modal {
      align-items: center;
      #{$m}__content {
        width: grid-width(10);
        padding: 96px 61px 64px;
      }
    }
    &__body {
      margin-bottom: 24px;
    }
    &__buttons {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .e-site-button {
        width: fit-content !important;
        flex-grow: 1;
        flex-shrink: 0;
        overflow: hidden;
        &[data-code='cancel'] {
          order: 1;
        }
      }
    }
  }
}
</style>
