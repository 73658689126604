<script setup>
import { reactive, inject, toRefs, computed, nextTick } from 'vue';
import { sitecoreProps } from '@/utils/constants';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';
import { debounce } from 'lodash';
import { sleep } from '@/utils/dom-utils';
import utils from '@/utils';

const emit = defineEmits(['change']);
const rootState = inject('one-form-state');
const rootMethods = inject('one-form-methods');
const props = defineProps({
  fields: {
    type: Object
  },
  index: {
    type: Number
  },
  ...sitecoreProps
});
const state = reactive({
  /**@type OneFormSectionRef*/
  sectionRef: null,
  collapsed: false,
  readonly: false,
  invisible: props.fields?.invisible.value ?? false,
  preSelected: false
});
const onVehicleOpClick = (option) => {
  const unwrappedVehicle = unWrapSitecoreItem(option.fields);
  if (!props.fields?.multiSelectable.value) {
    rootState.selectedVehicles = [unwrappedVehicle];
  } else {
    const index = rootState.selectedVehicles.findIndex((x) => x.vehicleName === unwrappedVehicle.vehicleName);
    if (index >= 0) {
      rootState.selectedVehicles.splice(index, 1);
    } else {
      rootState.selectedVehicles.push(unwrappedVehicle);
    }
  }
  emit('change', 'vehicle', rootState.selectedVehicles);
};
const confirmSelection = debounce(async () => {
  await state.sectionRef.collapse();
  await rootMethods.expandNext(props.index);
}, 50);
const isSelected = (vehicle) => {
  return !!rootState.selectedVehicles.find((x) => x.vehicleName === vehicle.fields.vehicleName.value);
};
const init = async () => {
  const selectedVehicles = unWrapSitecoreItem(props.fields.selectedOptions);
  if (props.fields?.multiSelectable.value) {
    rootState.selectedVehicles = selectedVehicles;
  } else if (selectedVehicles.length > 0) {
    rootState.selectedVehicles = [selectedVehicles[0]];
  }
  if (props.fields?.locked.value) {
    state.sectionRef.lock();
  }
  if (rootState.selectedVehicles.length > 0) {
    await nextTick();
    state.collapsed = true;
    state.preSelected = true;
  }
  emit('change', 'vehicle', rootState.selectedVehicles);
  return false;
};
const expand = async () => {
  await state.sectionRef.intoView();
  state.collapsed = false;
};
defineExpose({ init, expand, state: toRefs(state) });
</script>

<template>
  <one-form-section
    :fields="fields"
    :index="index"
    v-model:collapsed="state.collapsed"
    v-model:readonly="state.readonly"
    :done="rootState.selectedVehicles?.length > 0"
    :ref="(e) => (state.sectionRef = e)"
  >
    <template #collapsed>{{ rootState.selectedVehicles?.map((x) => x.vehicleName).join(', ') }}</template>
    <div class="s-of-vehicle">
      <jss-rich-text class="s-of-vehicle__title" :field="fields.title" />
      <div class="s-of-vehicle__options">
        <template v-for="option in fields.options" :key="option.id">
          <div class="s-of-vehicle__option" :class="[{ selected: isSelected(option) }]" @click="onVehicleOpClick(option)">
            <adaptive-image class="s-of-vehicle__image" :field="option.fields.image" v-aspect-ratio="'9_5'" />
            <div class="s-of-vehicle__option-content">
              <jss-rich-text class="s-of-vehicle__option-title" :field="option.fields.title" />
              <jss-rich-text class="s-of-vehicle__option-desc" :field="option.fields.description" />
            </div>
            <icon class="s-of-vehicle__option-icon" name="check" v-if="isSelected(option)" />
          </div>
        </template>
      </div>
      <site-button v-bind="fields.nextButton" :disabled="rootState.selectedVehicles.length === 0" @click="confirmSelection" />
    </div>
  </one-form-section>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.s-of-vehicle {
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .e-site-button {
    width: 100%;
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
  }
  &__options {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__option {
    $op: &;
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid $grey-89;
    padding: 8px 16px;
    border-radius: 1px;
    user-select: none;
    cursor: pointer;
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 4px;
      padding: 8px 0;
    }
    &-desc {
      font-size: 12px;
      color: $grey-next;
    }
    &-icon {
      position: absolute;
      top: 8px;
      right: 8px;
      color: $yellow;
    }
    &.selected {
      background-image: radial-gradient(56.75% 129.55% at 19.45% 33.52%, rgba(24, 24, 24, 0.8) 0%, #000000 100%);
      border-color: $black;
      color: $white;
      #{$op}-title {
        color: $yellow;
      }
      #{$op}-desc {
        color: $grey-taubmans;
      }
      #{$op}-icon {
        filter: drop-shadow(0px 0px 12px $yellow);
      }
    }
  }
  &__image {
    width: grid-width-m(3);
    aspect-ratio: 9/5;
    .e-background-image {
      &__img {
        img {
          object-fit: contain;
        }
      }
    }
  }
  @include tablet-landscape {
    &__options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &__option {
      flex-direction: column;
      align-items: flex-start;
      &.selected {
        background-image: radial-gradient(133.49% 44.53% at 46.17% 34.8%, rgba(46, 46, 46, 0.8) 0%, #000000 100%);
      }
    }
    &__image {
      width: 100%;
    }
  }
}
</style>
