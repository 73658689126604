<template>
  <div class="e-spinner-jumping" :class="[{ [`size-${size}`]: size }, ...$handleComClass($props['class'])]" />
</template>

<script>
export default {
  name: 'spinner-jumping',
  props: {
    size: {
      type: String
    },
    class: null
  }
};
</script>

<style lang="scss">
.e-spinner-jumping {
  position: relative;
  width: 50px;
  perspective: 200px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    animation: jumping 0.5s infinite alternate;
    background: currentColor;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
    animation-delay: 0.15s;
  }

  @keyframes jumping {
    0% {
      transform: scale(1) translateY(0px) rotateX(0deg);
      box-shadow: 0 0 0 currentColor;
    }

    100% {
      transform: scale(1.2) translateY(-25px) rotateX(45deg);
      background: #000;
      box-shadow: 0 25px 40px #000;
    }
  }
  &.size-small {
    width: 30px;
    &:before,
    &:after {
      width: 10px;
      height: 10px;
    }
    @keyframes jumping {
      0% {
        transform: scale(1) translateY(0px) rotateX(0deg);
        box-shadow: 0 0 0 currentColor;
      }

      100% {
        transform: scale(1.2) translateY(-15px) rotateX(45deg);
        background: #000;
        box-shadow: 0 10px 20px #000;
      }
    }
  }
}
</style>
