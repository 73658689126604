<script setup>
import { computed, inject, onMounted, reactive, ref, watch } from 'vue';
import { merge, debounce, difference, trim } from 'lodash';
import { transitions } from '@/utils/transitions';
import { isNullOrEmpty } from '@/utils/obj-utils';
import api from '@/api';
import { qtUtils } from '@/utils/ali-tracker-utils';
const props = defineProps({
  dealer: {
    type: Object
  },
  customerType: {
    type: Object
  }
});
const emit = defineEmits(['update:customerType']);
const loading = inject('loading');
const { fields, methods } = inject('reservation');
const detailsFields = computed(() => fields.detailsStep.fields);
const personalFormRef = ref();
const addressFormRef = ref();
const companyFormRef = ref();
const billingFormRef = ref();
const dealerSelectorRef = ref();
const consentFormRef = ref();
const diffAddr = ref(false);
const customerTypeVal = computed(() => props.customerType?.fields.code.value);
const addressFormCfg = reactive({
  country: {
    controlProps: {
      disabled: true
    }
  }
});
const onCustomerTypeClick = (ct) => emit('update:customerType', ct);
const onPrevClick = () => {
  methods.onExit();
};
const onNextClick = debounce(async () => {
  let companyValid = true,
    companyData = null,
    billingValid = true,
    billingData = null;
  loading.show();
  const [personalValid, personalData] = await personalFormRef.value.validate();
  const [addressValid, addressData] = await addressFormRef.value.validate();
  const [consentValid, consentData] = await consentFormRef.value.validate();
  if (customerTypeVal.value === 'business') {
    [companyValid, companyData] = await companyFormRef.value.validate();
  }
  billingData = addressData;
  if (diffAddr.value) {
    [billingValid, billingData] = await billingFormRef.value.validate();
  }
  const dealerValid = dealerSelectorRef.value.validate();
  const isValid = personalValid && companyValid && addressValid && billingValid && consentValid && dealerValid;
  qtUtils.trackClick(`car_order_step_details_pg_clk`, {
    city: addressData?.city,
    country: addressData?.country?.code,
    is_success: isValid,
    reservation_type: customerTypeVal.value
  });
  if (isValid) {
    const allConsents = [...personalFormRef.value.getVerifiedConsents(), ...addressFormRef.value.getVerifiedConsents(), ...consentFormRef.value.getVerifiedConsents()];
    const newsletterConsents = consentData?.termsCondition ? consentFormRef.value.getItemConsents('termsCondition') : [];
    const bindConsents = difference(allConsents, newsletterConsents);
    const leadTypes = consentFormRef.value.getLeadTypes();
    await methods.submit(personalData, companyData, addressData, billingData, consentData, bindConsents, newsletterConsents, leadTypes);
  }
  loading.hide();
}, 500);
const onChangeDealer = () => {
  methods.openDealerModal();
};
const onAddressFormInit = async (formCfg) => {
  if (addressFormRef.value?.hasItem('state')) {
    const { unCode } = formCfg.country?.controlProps.selectedOption?.data || {};
    if (!isNullOrEmpty(unCode)) {
      const [res, ex] = await api.meta.states(null, {
        pCode: unCode
      });
      if (res?.length) {
        addressFormRef.value.updateItem('state', {
          controlProps: {
            options: res.map((x) => ({
              code: x.code,
              text: x.codeName,
              data: x
            }))
          }
        });
      }
    }
  }
};
const onAddressFormChange = async (name, value) => {
  await methods.onDetailUpdate(name, value);
};
const fillAddress = (data) => {
  autofillAddress(addressFormRef.value, data);
  if (diffAddr.value) {
    autofillAddress(billingFormRef.value, data);
  }
};
const autofillAddress = (formRef, data) => {
  if (!formRef || isNullOrEmpty(data)) return;
  const updateObj = {};
  const address1 = formRef.getItemValueByName('address1');
  const addressCity = formRef.getItemValueByName('city');
  const addressZipcode = formRef.getItemValueByName('zipCode');
  if (isNullOrEmpty(address1) && !isNullOrEmpty(data?.address)) {
    merge(updateObj, {
      address: {
        controlProps: {
          value: data?.address
        }
      }
    });
  }
  if (isNullOrEmpty(addressCity) && !isNullOrEmpty(data?.city)) {
    merge(updateObj, {
      city: {
        controlProps: {
          value: data?.city
        }
      }
    });
  }
  if (isNullOrEmpty(addressZipcode) && !isNullOrEmpty(data?.zipCode)) {
    merge(updateObj, {
      zipCode: {
        controlProps: {
          value: data?.zipCode
        }
      }
    });
  }
  !isNullOrEmpty(updateObj) && formRef.updateItems(updateObj);
};
watch(
  () => props.carSeries,
  (_carSeries) => {}
);
watch(
  () => props.dealer,
  (_dealer) => {
    if (!_dealer) return;
    const city = trim(_dealer.districtName);
    const data = { city };
    autofillAddress(addressFormRef.value, data);
    if (diffAddr.value) {
      autofillAddress(billingFormRef.value, data);
    }
  }
);
defineExpose({ fillAddress });
onMounted(() => {
  if (detailsFields.value.customerTypes?.length > 0) {
    emit('update:customerType', detailsFields.value.customerTypes[0]);
  }
});
</script>

<template>
  <div class="s-reservation-details-form">
    <jss-rich-text class="text-primary" :field="detailsFields.title" />
    <customer-type-selector :customer-types="detailsFields.customerTypes" :value="customerType" @item-click="onCustomerTypeClick" v-if="!detailsFields.hideCustomerTypes?.value">
      <jss-text :field="detailsFields.customerTypeLabel" />
    </customer-type-selector>
    <jss-rich-text class="fs-26 mg-b-24" :field="detailsFields.personalTitle" />
    <jss-rich-text class="fs-14 fw-700 mg-b-24" :field="detailsFields.generalTitle" />
    <dynamic-form ref="personalFormRef" :form="detailsFields.personalForm" />
    <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
      <div v-show="customerTypeVal === 'business'">
        <jss-rich-text class="fs-14 fw-700 mg-b-24" :field="detailsFields.companyTitle" />
        <dynamic-form ref="companyFormRef" :form="detailsFields.companyForm" />
      </div>
    </transition>
    <jss-rich-text class="fs-14 fw-700 mg-b-24" :field="customerTypeVal === 'individual' ? detailsFields.addressTitle : $tryValueField(detailsFields.addressCoTitle, detailsFields.addressTitle)" />
    <dynamic-form ref="addressFormRef" :form="detailsFields.addressForm" :data="addressFormCfg" @init-form="onAddressFormInit" @change="onAddressFormChange" />
    <checkbox class="s-reservation-details-form__check" v-model:value="diffAddr" :mandatoryText="detailsFields.diffAddressText.value" v-if="!$isNullOrEmpty(detailsFields.diffAddressText.value)" />
    <transition @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
      <div v-show="diffAddr">
        <jss-rich-text class="fs-14 fw-700 mg-t-24 mg-b-24" :field="detailsFields.billingTitle" tag="div" />
        <dynamic-form ref="billingFormRef" :form="detailsFields.billingForm" />
      </div>
    </transition>
    <dealer-selector class="mg-t-40 mg-b-20" v-bind="fields.dealerSelector" :dealer="dealer" ref="dealerSelectorRef" @change="onChangeDealer" />
    <jss-rich-text class="text-secondary mg-b-24" :field="detailsFields.consentTitle" />
    <dynamic-form ref="consentFormRef" :form="detailsFields.consentForm" />
    <reservation-toolbar :prev-button="fields.exitButton" :next-button="fields?.toPaymentButton" @prev-click="onPrevClick" @next-click="onNextClick" />
  </div>
</template>

<style scoped lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-reservation-details-form {
}
</style>
