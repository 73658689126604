<template>
  <div class="s-merchandise-checkout-steps">
    <div class="s-merchandise-checkout-steps__mobile" v-if="$deviceComputes.isMobileOrTablet.value">
      <div class="s-merchandise-checkout-steps__left" v-if="exitText?.value">
        <div class="s-merchandise-checkout-steps__exit" @click="onExitClick">
          <Icon name="back" type="tiny" />
          <div class="s-merchandise-checkout-steps__exit-text" v-html="exitText.value" />
        </div>
      </div>
      <SiteButton class="s-merchandise-checkout-steps__left" v-bind="exitButton" v-if="exitButton" />
      <div class="s-merchandise-checkout-steps__progress">{{ $t('Step') }} {{ current + 1 }}/{{ steps.length }}</div>
    </div>
    <div class="s-merchandise-checkout-steps__laptop" v-else>
      <div class="s-merchandise-checkout-steps__step" :class="[{ finished: index < current, current: index === current }]" v-for="(step, index) in steps" :key="step.id">
        <div class="s-merchandise-checkout-steps__step-text" v-html="step?.fields?.title?.value" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'merchandise-checkout-steps',
  props: {
    steps: {
      type: Array
    },
    current: {
      type: Number
    },
    exitText: {
      type: String
    },
    exitButton: {
      type: Object
    }
  },
  setup(props) {
    const methods = {
      onExitClick() {
        window.history.back();
      }
    };
    return {
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-checkout-steps {
  $c: &;
  margin-bottom: 48px;
  &__mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__exit {
    display: flex;
    align-items: center;
    .e-icon {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__laptop {
    display: flex;
    padding-left: grid-width(2);
    padding-right: grid-width(2);
    overflow: hidden;
  }
  &__step {
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: visible;
    position: relative;
    text-align: center;
    padding-bottom: 54px;
    font-size: 12px;
    &-text {
      position: absolute;
      top: 0;
      left: 4px;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      white-space: nowrap;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid $grey-taubmans;
      position: absolute;
      bottom: 28px;
    }
    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid $grey-taubmans;
      background: $white;
      position: absolute;
      left: 0;
      bottom: 24px;
    }
    &:last-child {
      flex-grow: 0;
      width: 8px;
      &:before {
        width: 0;
      }
    }
    &.current {
      &:after {
        border-color: $black;
        background: $yellow;
      }
      &:first-child {
        &:before {
          border-bottom-color: $black;
        }
        &:after {
          background: $yellow;
        }
      }
    }
    &.finished {
      &:before {
        border-bottom-color: $black;
      }
      &:after {
        border-color: $black;
        background: $black;
      }
    }
  }
  @include tablet-landscape {
    padding: 0 grid-width(2);
    margin-bottom: 60px;
  }
}
</style>
