<template>
  <div class="s-merchandise-return-confirmation" v-if="fields" v-show="step === 2">
    <JssRichText class="s-merchandise-return-confirmation__title" :field="fields.title" />
    <JssRichText class="s-merchandise-return-confirmation__description" :field="fields.description" />
    <SiteButton v-bind="fields.button" @click="submit" />
    <JssRichText class="s-merchandise-return-confirmation__text" :field="fields.customerText" />
  </div>
</template>
<script>
import { reactive, onMounted, toRefs, inject } from 'vue';
import { formatMoney } from '@/utils/math-utils';
import { getGlobalCfgValues, getGlobalConfigs } from '@/utils/site-utils';
import { redirectTo, appendQuery, getBetterUrl } from '@/utils/uri-utils';
import { debounce } from 'lodash';
import api from '@/api';
export default {
  name: 'MerchandiseReturnConfirmation',
  props: {
    step: {
      type: Number
    },
    fields: {
      type: Object
    },
    returnItems: {
      type: Object
    },
    total: {
      type: Number
    },
    page: {
      type: Object
    },
    orderCode: {
      type: String
    },
    formData: {
      type: Object
    }
  },
  setup(props) {
    const loading = inject('loading');
    const [shopOrderDetailsLink] = getGlobalConfigs(props.page, 'shopOrderDetailsLink');
    let shopOrderDetailsLinkHref = shopOrderDetailsLink?.value?.href;
    const appMethods = inject('appMethods');
    const toast = inject('toast');
    const state = reactive({});
    const methods = {
      submit: debounce(async () => {
        loading.show();
        let returnItems = [];
        props.returnItems?.map((item) => {
          let returnItem = {};
          returnItem = {
            skuId: item.skuId,
            num: item.num
          };
          returnItems.push(returnItem);
        });
        const [res, ex] = await api.shop.refundByCustomer(null, {
          orderCode: props.orderCode,
          refundReasonId: props.formData.reason.code,
          refundReason: props.formData.reason.text,
          otherReason: props.formData.description,
          refundFee: props.total,
          refundType: 51181002,
          refundItems: returnItems
        });
        if (ex) {
          await toast.showEx(ex);
          if (ex.code === 60000000) {
            await appMethods.logoutToLogin();
          }
          return;
        }
        loading.hide();
        let url = getBetterUrl(
          appendQuery(shopOrderDetailsLinkHref, {
            orderCode: props.orderCode
          }),
          props.page.itemLanguage,
          true
        );
        window.location = url;
      }, 100)
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-return-confirmation {
  padding-bottom: 118px;
  &__description {
    margin-top: 48px;
  }
  .e-site-button {
    width: 100%;
    margin-top: 32px;
  }
  &__text {
    margin-top: 8px;
    a {
      text-decoration: underline;
    }
  }
  @include tablet-landscape {
    padding-bottom: 0px;
    &__description {
      margin-top: 32px;
    }
    .e-site-button {
      margin-top: 108px;
    }
    &__text {
      margin-top: 32px;
    }
  }
}
</style>
