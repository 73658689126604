<template>
  <div class="s-merchandise-checkout-summary">
    <div class="s-merchandise-checkout-summary-header">
      <div class="s-merchandise-checkout-summary-header-title">
        {{ $t('Order summary') }}
      </div>
    </div>
    <div class="s-merchandise-checkout-summary-main">
      <div class="s-merchandise-checkout-summary-list">
        <div class="s-merchandise-checkout-summary-list-item" v-for="item in skuList" :key="item.id">
          <div class="s-merchandise-checkout-summary-list-item-img">
            <img :src="item.skuImage" :alt="item.skuBusId" />
          </div>
          <div class="s-merchandise-checkout-summary-list-item-main">
            <div class="s-merchandise-checkout-summary-list-item-top">
              <div class="s-merchandise-checkout-summary-list-item-main-name">
                {{ item.name }}
              </div>
              <div class="s-merchandise-checkout-summary-list-item-main-des">
                {{ item.description }}
              </div>
            </div>
            <div class="s-merchandise-checkout-summary-list-item-bottom">
              <div class="s-merchandise-checkout-summary-list-item-main-price">{{ $formatShopMoney(item.depositPrice ? item.depositPrice : item.itemPrice, currency) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="s-merchandise-checkout-summary-delivery" v-if="deliveryDetails">
        <div class="s-merchandise-checkout-summary-delivery-title">{{ $t('Delivery details') }}</div>
        <div class="s-merchandise-checkout-summary-delivery-details">
          <div class="s-merchandise-checkout-summary-delivery-shipping">
            <div class="s-merchandise-checkout-summary-delivery-details-title">{{ $tu('Personal information') }}</div>
            <div>{{ `${deliveryDetails.personalData.firstName} ${deliveryDetails.personalData.lastName}` }}</div>
            <div>{{ deliveryDetails.pickup ? deliveryDetails.dealer?.addressDetail : deliveryDetails.shippingData.address }}</div>
            <div>{{ deliveryDetails.pickup ? deliveryDetails.dealer?.cityName : deliveryDetails.shippingData.city }}</div>
            <div>{{ deliveryDetails.pickup ? deliveryDetails.dealer?.countryName : deliveryDetails.shippingData.country.text }}</div>
            <div>{{ `+${deliveryDetails.personalData.mobile?.area.code} ${deliveryDetails.personalData.mobile?.number}` }}</div>
          </div>
          <div class="s-merchandise-checkout-summary-delivery-billing">
            <div class="s-merchandise-checkout-summary-delivery-details-title">{{ $t('Billing address').toUpperCase() }}</div>
            <div>{{ deliveryDetails.diffAddr ? deliveryDetails.billingData.address : deliveryDetails.shippingData.address }}</div>
            <div>{{ deliveryDetails.diffAddr ? deliveryDetails.billingData.city : deliveryDetails.shippingData.city }}</div>
            <div>{{ deliveryDetails.diffAddr ? deliveryDetails.billingData.country.text : deliveryDetails.shippingData.country.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-merchandise-checkout-summary-price">
      <div class="s-merchandise-checkout-summary-price-top">
        <JssRichText
          class="s-merchandise-checkout-summary-price-top-left"
          :field="fields.summaryLabel"
          @click="!largeThanTablet() && toggleDis()"
          @mouseover="largeThanTablet() && showDis()"
          @mouseout="largeThanTablet() && hideDis()"
        />
        <div class="s-merchandise-checkout-summary-price-top-right">{{ $formatShopMoney(totalPrice, currency) }}</div>
        <div
          class="s-merchandise-checkout-summary-price-top-disclaimer"
          v-html="$formatString(fields.priceDisclaimer?.value, { price: $formatShopMoney(totalPrice, currency) })"
          v-if="showDisclaimer && fields.priceDisclaimer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive } from 'vue';
import { largeThanTablet } from '@/utils/dom-utils';

export default {
  name: 'merchandise-checkout-summary',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    },
    skuList: {
      type: Array
    },
    totalPrice: {
      type: Number
    },
    currency: {
      type: String,
      default: 'EUR'
    },
    deliveryDetails: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      showDisclaimer: false
    });
    const methods = {
      largeThanTablet,
      toggleDis: () => {
        state.showDisclaimer = !state.showDisclaimer;
      },
      showDis: () => {
        state.showDisclaimer = true;
      },
      hideDis: () => {
        state.showDisclaimer = false;
      }
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-checkout-summary {
  height: calc(100vh - #{mobile-header-height()});
  display: flex;
  flex-direction: column;
  &-header {
    padding: 0 24px;
    &-title {
      font-size: 26px;
      line-height: 32px;
      margin-top: 40px;
    }
    &-count {
      font-size: 12px;
      line-height: 20px;
      margin-top: 20px;
    }
  }
  &-main {
    flex: 1;
    padding: 24px;
    overflow: auto;
  }
  &-delivery {
    margin-top: 72px;
    padding-top: 24px;
    border-top: 1px solid $grey-night;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
    &-details {
      display: flex;
      flex-direction: column;
      gap: 24px;
      &-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: $white;
      }
      > div {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-next;
      }
    }
  }
  &-list {
    &-item {
      display: flex;
      flex-direction: row;
      gap: 27px;
      margin-bottom: 24px;
      &-img {
        width: 80px;
        height: 100px;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-name {
          margin-bottom: 5px;
          font-size: 12px;
          font-weight: 700;
          line-height: 20px;
          color: $white !important;
        }
        > div {
          font-size: 12px;
          line-height: 20px;
          color: $grey-taubmans;
        }
      }
    }
  }
  &-price {
    width: 100%;
    color: $white;
    padding: 16px 24px 24px;
    border-top: 1px solid var(--shades-nshadeb, $grey-dark);
    background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    &-top {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-left {
        font-size: 12px;
        line-height: 24px;
        display: flex;
        cursor: pointer;
        > p,
        div,
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
        }
      }
      &-right {
        font-size: 16px;
        line-height: 24px;
      }
      &-disclaimer {
        position: absolute;
        bottom: 52px;
        padding: 24px;
        background-color: $grey-light;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 4px;
      font-size: 12px;
      line-height: 20px;
      margin-top: 12px;
      .e-icon {
        display: flex;
        align-items: center;
      }
    }
  }
  @include tablet-landscape {
    height: calc(100vh - #{tablet-header-height()});
    &-header {
      padding: 0 grid-width(2);
      &-title {
        margin-top: 0;
      }
    }
    &-main {
      margin-top: 50px;
      padding: 0 grid-width(2);
    }
    &-delivery {
      margin-top: 66px;
      padding-top: 24px;
      border-top: 1px solid $grey-night;
      display: flex;
      flex-direction: column;
      gap: 24px;
      &-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
      &-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        &-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          color: $white;
        }
        > div {
          flex: 1;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: $grey-next;
        }
      }
    }
    &-price {
      padding: 24px grid-width(2);
      &-top {
        &-left {
          font-size: 16px;
          line-height: 24px;
        }
        &-right {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
  @include desktop {
    height: calc(100vh - #{desktop-header-height()});
  }
}
</style>
