<template>
  <div class="s-spinner-circle"></div>
</template>

<script>
export default {
  name: 'spinner-circle'
};
</script>

<style lang="scss">
@import '../styles/variable';
.s-spinner-circle {
  position: relative;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left-color: $yellow;
  border-radius: 100%;

  animation: circle infinite 2s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
