<template>
  <div class="s-merchandise-detail-gallery">
    <div class="close" @click="closeGallery">
      <icon name="close" />
    </div>
    <div class="s-merchandise-detail-gallery-content">
      <div class="s-merchandise-detail-gallery-content-top" ref="topEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in galleryImageList" :key="index.id">
            <BackgroundImage class="image" :fullscreenEnabled="false" :image="item.media" />
          </div>
        </div>
      </div>
      <div class="s-merchandise-detail-gallery-content-bottom" ref="bottomEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in galleryImageList" :key="index.id">
            <BackgroundImage class="image" :fullscreenEnabled="false" :image="item.media" />
          </div>
        </div>
      </div>
    </div>
    <div class="progress-content">
      <div class="progress" ref="progressEl"></div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue';
import Swiper from 'swiper';
import { isMobile } from '@/utils/dom-utils';

export default {
  name: 'MerchandiseDetailGallery',
  props: {
    fields: {
      type: Object
    },
    galleryImageList: {
      type: Object
    },
    activeIndex: {
      type: Number
    }
  },
  setup(props, context) {
    let swiper = null;
    let bottomSwiper = null;
    const state = reactive({
      bottomEl: null,
      topEl: null,
      progressEl: null
    });

    const buildTopSwiper = () => {
      let pageWidth = document.documentElement.clientWidth || document.body.offsetWidth || document.documentElement.offsetWidth;
      let progressWidth = pageWidth - 48;
      if (swiper) {
        swiper.destroy(true, true);
      }

      swiper = new Swiper(state.topEl, {
        slidesPerView: 1,
        on: {
          slideChange: (swiper) => {
            let index = swiper.activeIndex;
            if (isMobile()) {
              state.progressEl.style.width = (index + 1) * (progressWidth / props.galleryImageList?.length) + 'px';
            }
          }
        },
        thumbs: {
          swiper: bottomSwiper
        }
      });
    };

    const buildBottomSwiper = () => {
      if (bottomSwiper) {
        bottomSwiper.destroy(true, true);
      }

      bottomSwiper = new Swiper(state.bottomEl, {
        slidesPerView: 'auto',
        spaceBetween: 16,
        watchSlidesProgress: true,
        watchSlidesVisibility: true, //防止不可点击
        direction: isMobile() ? 'horizontal' : 'vertical'
      });
    };

    const methods = {
      closeGallery() {
        context.emit('changeShowGallery', false);
      },
      resizePageSet() {
        buildBottomSwiper();
        buildTopSwiper();
      }
    };

    onMounted(() => {
      let pageWidth = document.documentElement.clientWidth || document.body.offsetWidth || document.documentElement.offsetWidth;
      let progressWidth = pageWidth - 48;
      if (isMobile()) {
        state.progressEl.style.width = progressWidth / props.galleryImageList?.length + 'px';
      }
      buildBottomSwiper();
      buildTopSwiper();
      swiper?.slideTo(props.activeIndex, 0);
      window.addEventListener('resize', methods.resizePageSet);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.s-merchandise-detail-gallery {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background: #f2f1f0;
  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &-bottom {
      width: 100%;
      height: 50px;
      position: absolute;
      left: 0;
      bottom: 50px;
      .swiper-wrapper {
        .swiper-slide {
          width: 50px;
          height: 50px;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(255, 255, 255, 0.4);
            left: 0;
            top: 0;
          }
          &-thumb-active {
            &::after {
              display: none;
            }
          }
          .image {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
    &-top {
      width: 100%;
      height: 100vw;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      .swiper-wrapper {
        .swiper-slide {
          .image {
            width: 100%;
            height: 100%;
            .background-image {
              > img {
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
  .progress-content {
    width: calc(100% - 48px);
    height: 1px;
    background: #e3e3e3;
    position: absolute;
    left: 24px;
    bottom: 25px;
    .progress {
      width: 0;
      height: 1px;
      background: $black;
      transition: all 0.3s;
    }
  }
}

@include tablet-landscape {
  .s-merchandise-detail-gallery {
    &-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      &-bottom {
        width: 80px;
        height: calc(100vh - 214px);
        position: absolute;
        left: 24px;
        top: 107px;
        overflow: hidden;
        .swiper-wrapper {
          height: 100%;
          .swiper-slide {
            width: 80px;
            height: 80px;
            position: relative;
            .image {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
      &-top {
        width: 55vw;
        height: 36.7vw;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        overflow: hidden;
        .swiper-wrapper {
          width: 100%;
          height: 100%;
          .swiper-slide {
            .image {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .progress-content {
      display: none;
    }
  }
}
</style>
