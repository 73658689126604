<script setup>
import { debounce } from 'lodash';

const props = defineProps({
  prevButton: {
    type: Object
  },
  nextButton: {
    type: Object
  }
});
const onPrevClick = debounce((e) => {
  emit('prev-click', e);
}, 50);
const onNextClick = debounce((e) => {
  emit('next-click', e);
}, 50);
const emit = defineEmits(['prev-click', 'next-click']);
</script>

<template>
  <div class="s-reservation-toolbar">
    <div class="s-reservation-toolbar__prev">
      <site-button v-bind="prevButton" @click="onPrevClick" />
    </div>
    <div class="s-reservation-toolbar__next">
      <site-button v-bind="nextButton" @click="onNextClick" />
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-reservation-toolbar {
  position: sticky;
  bottom: 84px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: $white;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    display: block;
    width: 4px;
    height: 100%;
    background: $white;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    width: 4px;
    height: 100%;
    background: $white;
  }
  &__prev {
    display: none;
  }
  &__next {
    .e-site-button {
      &__text {
        font-weight: 700;
      }
    }
  }
  .e-site-button {
    width: 100%;
  }
  @include tablet-landscape {
    flex-direction: row;
    align-items: center;
    padding: 24px 0;
    bottom: 0;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
    .e-site-button {
      width: fit-content;
    }
    &__prev {
      display: block;
      width: calc(50% - 12px);
      text-align: left;
      .e-site-button {
        color: $grey-neutral !important;
        font-size: 12px;
        font-weight: 400;
        background: transparent !important;
        border-color: transparent !important;
        &__text {
          order: 2 !important;
        }
        &__icon {
          order: 1 !important;
          transform: translateX(0);
        }
        &.disabled {
          border-color: $grey-light !important;
          background: $grey-light !important;
          color: $grey-next !important;
        }
        &:hover {
          .e-site-button__icon {
            opacity: 1 !important;
            transform: translateX(8px) !important;
          }
        }
      }
    }
    &__next {
      width: calc(50% - 12px);
      text-align: right;
      &.disabled {
        border-color: $grey-light !important;
        background: $grey-light !important;
        color: $grey-next !important;
      }
    }
  }
}
</style>
