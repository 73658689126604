<script setup>
import { computed, inject, reactive } from 'vue';
const props = defineProps({
  fields: {
    type: Object
  }
});
const lFormItems = computed(() => props.fields?.lightForms?.formItems);
const sFields = computed(() => props.fields?.successBlock?.fields);
const rootState = inject('one-form-state');
const state = reactive({
  firstDetailEl: null
});
const onViewDetail = () => {
  console.log('state.firstDetailEl', state.firstDetailEl);
  state.firstDetailEl.scrollIntoView();
};
</script>

<template>
  <div class="s-of-success" v-if="sFields">
    <adaptive-image class="s-of-success__image" :field="sFields.successImage">
      <jss-rich-text class="mg-b-16" :field="sFields.title" />
      <template-string class="mg-b-60 tl-mg-b-80" :field="sFields.body" :data="{ name: `${rootState.detailFormData?.firstName} ${rootState.detailFormData?.lastName}` }" />
      <div class="s-of-success__view-detail" @click="onViewDetail">
        <jss-text :field="sFields.viewDetailText" />
        <icon :field="sFields.viewDetailIcon" />
      </div>
    </adaptive-image>
    <div class="s-of-success__line" />
    <div class="s-of-success__block" :ref="(e) => (state.firstDetailEl = e)">
      <jss-rich-text class="" :field="sFields.pointTitle" />
      <div class="s-of-success__points">
        <div class="s-of-success__point" v-for="(point, index) in sFields.points" :key="point.id">
          <div class="__ft-h2 text-desc">{{ $padding(index + 1, 2, '0') }}</div>
          <jss-rich-text class="s-of-success__point-title" :field="point.fields.title" />
          <jss-rich-text class="s-of-success__point-body" :field="point.fields.body" />
        </div>
      </div>
    </div>
    <div class="s-of-success__line" />
    <div class="s-of-success__detail">
      <jss-rich-text class="s-of-success__detail-title" :field="sFields.modelTitle" />
      <div class="s-of-success__detail-grid">
        <div class="s-of-success__detail-pair" v-for="(vehicle, i) of rootState.selectedVehicles" :key="i">
          <div class="s-of-success__detail-label">{{ vehicle.vehicleName }}</div>
          <div class="__ft-pm">{{ vehicle.description }}</div>
        </div>
      </div>
    </div>
    <div class="s-of-success__line" />
    <div class="s-of-success__detail">
      <jss-rich-text class="s-of-success__detail-title" :field="sFields.locationTitle" />
      <div class="s-of-success__detail-grid">
        <div class="s-of-success__detail-pair">
          <div class="s-of-success__detail-label">{{ rootState.dealer?.storeName }}</div>
          <div class="__ft-pm">
            {{ rootState.dealer?.addressDetail }}
            <br />
            {{ $concatString([rootState.dealer?.extra?.storeZipCode, rootState.dealer?.cityName, rootState.dealer?.countryName], ',') }}
          </div>
          <a
            class="s-of-success__link-button"
            :href="
              `https://www.google.com/maps/place/${encodeURIComponent(rootState.dealer?.addressDetail)}/@${encodeURIComponent(
                $concatString([rootState.dealer?.latitude, rootState.dealer?.longitude], ',')
              )}`
            "
            v-if="!$isNullOrEmpty(rootState.dealer?.latitude) && !$isNullOrEmpty(rootState.dealer?.longitude)"
          >
            <icon :field="sFields.directionIcon" />
            <jss-text :field="sFields.directionText" />
          </a>
        </div>
        <div class="s-of-success__detail-pair">
          <jss-rich-text class="s-of-success__detail-label" :field="sFields.contactTitle" />
          <div class="flex fdc g4">
            <a class="s-of-success__link-button" :href="`mailto:${rootState.dealer?.extra?.serviceAdvisorEmail}`" v-if="!$isNullOrEmpty(rootState.dealer?.extra?.serviceAdvisorEmail)">
              <icon :field="sFields.emailIcon" />
              <div v-html="rootState.dealer?.extra?.serviceAdvisorEmail" />
            </a>
            <a class="s-of-success__link-button" :href="`tel:${rootState.dealer?.extra?.salesLine}`" v-if="!$isNullOrEmpty(rootState.dealer?.extra?.salesLine)">
              <icon :field="sFields.phoneIcon" :fill="false" />
              <div v-html="rootState.dealer?.extra?.salesLine" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="s-of-success__line" />
    <div class="s-of-success__detail">
      <jss-rich-text class="s-of-success__detail-title" :field="sFields.detailTitle" />
      <div class="s-of-success__detail-grid">
        <div class="s-of-success__detail-pair">
          <div class="s-of-success__detail-label">{{ $tu('First name') }}</div>
          <div class="__ft-pm">{{ rootState.detailFormData?.firstName }}</div>
        </div>
        <div class="s-of-success__detail-pair">
          <div class="s-of-success__detail-label">{{ $tu('Last name') }}</div>
          <div class="__ft-pm">{{ rootState.detailFormData?.lastName }}</div>
        </div>
        <div class="s-of-success__detail-pair">
          <div class="s-of-success__detail-label">{{ $tu('Email') }}</div>
          <div class="__ft-pm">{{ rootState.detailFormData?.email }}</div>
        </div>
        <div class="s-of-success__detail-pair">
          <div class="s-of-success__detail-label">{{ $tu('Phone number') }}</div>
          <div class="__ft-pm">+{{ rootState.detailFormData?.phone.area.code }}{{ rootState.detailFormData?.phone.number }}</div>
        </div>
      </div>
    </div>
    <template v-if="lFormItems?.length > 0 && !$isNullOrEmpty(rootState.lightFormsData)">
      <div class="s-of-success__line" />
      <div class="s-of-success__detail">
        <jss-rich-text class="s-of-success__detail-title" :field="sFields.lightFormsTitle" />
        <div class="s-of-success__detail-grid">
          <template v-for="(formItem, i) in lFormItems" :key="i">
            <jss-rich-text :field="formItem.fields.mandatoryText" v-if="rootState.lightFormsData[formItem.fields.dataName.value]" />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
@use "sass:math";
@import '../../styles/variable';
@import '../../styles/function';
@import '../../styles/mixin';
.s-of-success {
  $c: &;
  $img: '.e-background-image';
  background-color: $black;
  color: $white;
  &__image {
    overflow-y: visible;
    #{$img}__img {
      height: 100vw;
      &.background-image {
        position: relative;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 30%);
      }
    }
    #{$img}__content {
      position: static;
      padding: 40px grid-width-m(1);
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  &__view-detail {
    cursor: pointer;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    .e-icon {
      width: 12px;
      height: 12px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__line {
    margin: 0 grid-width-m(1);
    border-top: 1px solid $grey-dark;
  }
  &__block {
    padding: 40px grid-width-m(1);
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  &__points {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  &__point {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-body {
      color: $grey-taubmans;
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px grid-width-m(1);
    &-grid {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &-pair {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    &-label {
      font-size: 14px;
      font-weight: 700;
    }
  }
  &__link-button {
    display: flex;
    align-items: center;
    gap: 8px;
    .e-icon {
      width: 24px;
      height: 24px;
      color: $yellow;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      color: $yellow;
    }
  }
  @include tablet-landscape {
    &__image {
      overflow: hidden;
      height: #{math.div(100 * 9, 16)}vw;
      margin-bottom: 160px;
      #{$img}__img {
        height: 100%;
        &.background-image {
          position: absolute;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
        }
      }
      #{$img}__content {
        padding: 24px grid-width(2);
        width: grid-width(10);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 32px;
      }
    }
    &__view-detail {
      position: absolute;
      bottom: 40px;
    }
    &__line {
      margin: 0 grid-width(2);
    }
    &__block {
      padding: 60px grid-width(2);
      gap: 56px;
    }
    &__points {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: grid-width(1);
    }
    &__detail {
      flex-direction: row;
      gap: grid-width(1);
      padding: 60px grid-width(2);
      &-title {
        width: grid-width(8);
      }
      &-grid {
        width: grid-width(11);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
        column-gap: grid-width(1);
      }
    }
  }
}
</style>
