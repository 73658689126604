import api from '@/api';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { getCurrentAlpha2Code, getGlobalConfigs, getPageAlpha2Code, loadGMap, scrollToTop } from '@/utils/site-utils';
import { getAgentList } from '@/services/agentService';
import { nextTick } from 'vue';

export const getZipcodeList = async (page, keyword, alpha2Code = null) => {
  const [gmapKey] = getGlobalConfigs(page, 'gmapKey');
  if (isNullOrEmpty(gmapKey?.value)) return [];
  if (isNullOrEmpty(alpha2Code)) alpha2Code = getPageAlpha2Code(page) ?? 'NL';
  console.log('alpha2Code', page, alpha2Code);
  const [res] = await api.map.autoComplete({
    input: keyword,
    component: `country:${alpha2Code.toLowerCase()}`,
    language: page.itemLanguage,
    type: 'postal_code',
    key: gmapKey.value
  });
  return res;
};
export const geoDecode = async (page, options) => {
  const gmaps = await loadGMap(page);
  if (!gmaps) {
    return;
  }
  const geocoder = new gmaps.Geocoder();
  return new Promise((resolve) => {
    geocoder.geocode(options, async function(results, status) {
      if (status === gmaps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        resolve({
          lat,
          lng
        });
      }
      resolve(null);
    });
  });
};
