<template>
  <div class="s-merchandise-checkout-payment" v-if="fields" v-show="stepCode === 'payment'">
    <Heading class="s-merchandise-checkout-payment__title text-primary" :field="fields.title" :type="fields.titleType" rich />
    <div class="s-merchandise-checkout-payment__subtitle" v-html="$formatString(fields.subtitle?.value, { price })"></div>
    <JssRichText class="s-merchandise-checkout-payment__description" :field="fields.description" />
    <div class="c-checkout-payment__failed" v-if="failed">
      <JssRichText class="c-build-to-order__failed-overline" :field="fields.failedOverline" />
      <JssRichText class="c-build-to-order__failed-title" :field="fields.failedTitle" />
      <JssRichText class="c-build-to-order__failed-message" :field="fields.failedErrorMessage" />
      <JssRichText class="c-build-to-order__failed-subtitle" :field="fields.failedSubtitle" />
      <JssRichText class="c-build-to-order__failed-body" :field="fields.failedBody" />
    </div>
    <div class="c-build-to-order__failed-widget" ref="widgetRef" />
  </div>
</template>
<script>
import { getCurrentInstance, inject, reactive, toRefs } from 'vue';
import { loadStripe } from '@/utils/stripe-utils';
import { appendQuery } from '@/utils/uri-utils';
import { debounce } from 'lodash';
import { isNullOrEmpty } from '@/utils/obj-utils';
export default {
  name: 'MerchandiseCheckoutPayment',
  props: {
    page: {
      type: Object
    },
    step: {
      type: Number
    },
    stepCode: {
      type: String
    },
    fields: {
      type: Object
    },
    paymentRes: {
      type: Object
    },
    price: {
      type: String
    },
    failed: {
      type: Boolean
    }
  },
  setup(props) {
    let stripeHelper, stripe, confirmPayment;
    const { proxy } = getCurrentInstance();
    const { $jss } = proxy;
    const loading = inject('loading');
    const toast = inject('toast');

    const state = reactive({
      widgetRef: null
    });

    const methods = {
      async initWidget(payInfo) {
        const page = $jss.routeData();
        stripeHelper = await loadStripe(page, toast);
        stripe = stripeHelper.initSdk(payInfo);
        setTimeout(() => {
          confirmPayment = stripeHelper.initWidget(stripe, state.widgetRef, payInfo);
        }, 500);
      },
      onPay: debounce(async () => {
        loading.show();
        try {
          const addParams = {};
          if (!isNullOrEmpty(props.paymentRes?.publicKey)) {
            addParams.pk = props.paymentRes?.publicKey;
          }
          const redirectUrl = appendQuery(window.location.href, addParams, ['payment_intent', 'payment_intent_client_secret', 'redirect_status']);
          const error = await confirmPayment(redirectUrl);
          console.log(error);
          if (error) {
            loading.hide();
          }
        } catch {
          loading.hide();
        }
      }, 1000)
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-checkout-payment {
  &__title {
    margin-bottom: 48px;
    font-size: 26px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  &__subtitle {
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 24px;
  }
  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $grey-next;
    margin-bottom: 60px;
  }
}
</style>
