import { webStorage } from '@/utils/web-storage';
import {
  S_CAR_MODELS,
  S_CHAT_CONFIG,
  S_COUNTRIES,
  S_DATE_PICKER_CONFIG,
  S_DELIVERY_TIME,
  S_GLOBAL_CONFIG,
  S_LANG,
  S_LANGUAGES,
  S_LOCATION_TYPES,
  S_PROVINCE_LIST,
  S_REGISTRABLE_COUNTRIES,
  S_SHIPPING_COUNTRIES,
  S_SITE_ERRORS,
  S_STORE_TYPES,
  S_VEHICLE_CONFIG_LIST,
  S_WEBSHOP_SHIPPING_COUNTRIES
} from '@/utils/web-storage-keys';
import api from '@/api';
import config from '@/temp/config';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import { formatDate } from '@/utils/date-utils';
import { equalString, ifEmpty } from '@/utils/string-utils';
import { getLangFromUrl } from '@/utils/uri-utils';
import { getCurrentAlpha2Code, getGlobalConfigs } from '@/utils/site-utils';
import { sortByChar } from '@/utils/array-utils';
import { APP_CONST } from '@/utils/constants';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';
import utils from '@/utils';

export const getSiteError = async (ex) => {
  const errors = (await getSiteErrors()) ?? [];
  let error = null;
  if (ex?.message === 'Network Error') {
    error = errors.find((x) => x.code === 'network_error');
  } else {
    if (ex?.code) {
      error = errors.find((x) => x.code == ex?.code);
    }
    if (!error) {
      error = errors.find((x) => x.code == ex);
    }
    if (!error) {
      error = errors.find((x) => x.code === 'default_error');
    }
  }
  return error?.message ?? ex?.message ?? 'Uncertain Error!';
};
export const getSiteErrors = async () => {
  const lang = webStorage.get(S_LANG);
  let cache = webStorage.get(S_SITE_ERRORS) ?? {};
  if (!cache[lang]) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.apiErrors,
      isDeep: true
    });
    if (res?.children) {
      cache[lang] = res.children;
      webStorage.set(S_SITE_ERRORS, cache);
    }
  }
  return cache[lang] ?? [];
};
/**
 * @returns {Promise<Array<Country>>}
 */
export const getCountries = async () => {
  const lang = webStorage.get(S_LANG);
  const cache = webStorage.get(S_COUNTRIES) ?? {};
  if (!cache[lang]) {
    const [res, ex] = await api.meta.countries();
    if (ex) {
      return [];
    }
    if (res) {
      cache[lang] = res;
      webStorage.set(S_COUNTRIES, cache);
    }
  }
  return cache[lang] ?? [];
};
export const getCountryOptions = async () => {
  const countries = await getCountries();
  return countries.map((x) => ({
    code: x.alpha2Code,
    text: x.countryName,
    data: x
  }));
};
export const getCountry = async (prediction) => {
  const countries = await getCountries();
  return countries.find(prediction);
};
export const getShippingCountryOptions = async (carSeries) => {
  const options = await getCountryOptions();
  const shippingCountries = await getShippingCountries(carSeries);
  return options.filter((x) => shippingCountries.some((y) => y.alpha2Code === x.data.alpha2Code));
};
export const getWebshopShippingCountryOptions = async () => {
  const options = await getCountryOptions();
  const webshopShippingCountries = await getWebShopShippingCountriesOptions();
  return options.filter((x) => webshopShippingCountries.some((y) => y.alpha2Code === x.data.alpha2Code));
};
export const countryNeedDoubleOptin = async (alpha2Code) => {
  const sCountry = await getShippingCountry(alpha2Code);
  return sCountry?.needDoubleOptin === '1';
};
export const getShippingCountry = async (alpha2Code, carSeries = null) => {
  const shippingCountries = await getShippingCountries(carSeries);
  return shippingCountries.find((x) => x.alpha2Code === alpha2Code);
};
const getScCountries = async (cacheKey, rootId) => {
  const lang = getLangFromUrl();
  let cache = webStorage.get(cacheKey) ?? {};
  if (isNullOrEmpty(cache[lang])) {
    const [res, ex] = await api.web.item({
      id: rootId,
      isDeep: true
    });
    if (ex) {
      return [];
    }
    cache[lang] = res?.children?.filter((x) => !!x.alpha2Code) ?? [];
    webStorage.set(S_SHIPPING_COUNTRIES, cache);
  }
  if (isNullOrEmpty(cache[lang])) return [];
  return cache[lang] ?? [];
};
export const getShippingCountries = async (carSeries = null) => {
  const list = await getScCountries(S_SHIPPING_COUNTRIES, config.app.web.itemIds.shippingCountry);
  if (isNullOrEmpty(carSeries)) return list;
  return list?.filter((x) => x.carModels.some((y) => equalString(y.fields.code, carSeries) || equalString(y.fields.series, carSeries)));
};
export const getShippingRegions = async (page, carSeries = null) => {
  const shippingCountries = await getShippingCountries(carSeries);
  let [areas] = getGlobalConfigs(page, 'areas');
  const result = [];
  for (let area of areas) {
    for (let region of area.fields.regions) {
      if (shippingCountries.some((sc) => equalString(region.fields.alpha2Code?.value, sc.alpha2Code))) {
        result.push(region);
      }
    }
  }
  return result;
};
export const getWebShopShippingCountriesOptions = async () => {
  return getScCountries(S_WEBSHOP_SHIPPING_COUNTRIES, config.app.web.itemIds.webshopShippingCountry);
};
export const getRegistrableCountries = async () => {
  return getScCountries(S_REGISTRABLE_COUNTRIES, config.app.web.itemIds.registrableCountry);
};
export const getRegistrableCountryOptions = async () => {
  const options = await getCountryOptions();
  const registrableCountries = await getRegistrableCountries();
  return options.filter((x) => registrableCountries.some((y) => y.alpha2Code === x.data.alpha2Code));
};
export const bindConsents = async (consents, body) => {
  if (consents?.length > 0) {
    const [res, ex] = await api.consent.bind(null, {
      termsAndConditions: consents.map((x) => ({
        revisionNumber: x,
        title: window.document.title,
        formUrl: window.location.href,
        effectiveFromDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
      })),
      countryRegion: '',
      phone: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      channel: 'Official Website',
      receiveEmail: true,
      receiveSMS: true,
      receivePost: true,
      receiveCall: true,
      ...body
    });
    return [res, ex];
  }
};

export const getEncryptor = () => {
  const CryptoJS = require('crypto-js');
  return {
    async encrypt(str) {
      if (isNullOrEmpty(str)) return;
      const [res, ex] = await api.cidp.getSecretByType();
      if (ex) {
        return [null, ex];
      }
      const { iv, secret } = res;
      const _key = CryptoJS.enc.Utf8.parse(secret);
      const _iv = CryptoJS.enc.Utf8.parse(iv);
      const _str = CryptoJS.SHA256(str).toString() + ',' + new Date().getTime();
      const encryptedStr = CryptoJS.AES.encrypt(_str, _key, {
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return [encryptedStr.toString(), null];
    }
  };
};
export const getCarSeriesList = async () => {
  const lang = getLangFromUrl();
  const cache = webStorage.get(S_CAR_MODELS) ?? {};
  if (isNullOrEmpty(cache[lang])) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.carModels,
      isDeep: true
    });
    if (ex) {
      return [];
    }
    const result = [];
    if (isArray(res?.children)) {
      for (let series of res?.children) {
        const { children, ...newSeries } = series;
        newSeries.models = [];
        if (isArray(children)) {
          for (let model of children) {
            const { children: attrFolderList, ...newModel } = model;
            if (isArray(attrFolderList)) {
              for (let attrFolder of attrFolderList) {
                newModel[attrFolder.code] = attrFolder.children ?? [];
              }
            }
            newSeries.models.push(newModel);
          }
        }
        result.push(newSeries);
      }
    }
    cache[lang] = result;
    webStorage.set(S_CAR_MODELS, cache);
  }
  return cache[lang];
};
/**
 * @param {String} series
 * @return {Promise<CarSeries>}
 */
export const getCarSeries = async (series) => {
  const carSeriesItems = await getCarSeriesList();
  return carSeriesItems.find((x) => equalString(x.series, series));
};
/**
 * @param {String} seriesCode
 * @return {Promise<CarSeries>}
 */
export const getCarSeriesByCode = async (seriesCode) => {
  const carSeriesItems = await getCarSeriesList();
  return carSeriesItems.find((x) => equalString(x.code, seriesCode));
};
/**
 * @param {String} series
 * @param {String} key
 * @return {Promise<CarModel>}
 */
export const getCarModel = async (series, key) => {
  const carSeries = await getCarSeries(series);
  return carSeries?.models?.find((x) => equalString(x.interactionName, key) || equalString(x.name, key));
};
/**
 * @param {String} seriesCode
 * @param {String} modelCode
 * @return {Promise<CarModel>}
 */
export const getCarModelByCode = async (seriesCode, modelCode) => {
  const carSeries = await getCarSeriesByCode(seriesCode);
  return carSeries?.models?.find((x) => equalString(x.code, modelCode));
};
export const getCarModelByFilterId = async (series, filterId) => {
  const carSeries = await getCarSeries(series);
  return carSeries?.models?.find((x) => equalString(x.filterId, filterId));
};
export const getFormulaNos = async (page, series) => {
  const carSeries = await getCarSeries(series);
  let [formulaNo] = getGlobalConfigs(page, 'formulaNo');
  const formulaNoVal = carSeries?.formulaNo ?? formulaNo?.value;
  return [formulaNoVal, carSeries?.paymentFormulaNo ?? formulaNoVal];
};
export const getFormulaNosByCode = async (page, seriesCode) => {
  const carSeries = await getCarSeriesByCode(seriesCode);
  let [formulaNo] = getGlobalConfigs(page, 'formulaNo');
  const formulaNoVal = carSeries?.formulaNo ?? formulaNo?.value;
  return [formulaNoVal, carSeries?.paymentFormulaNo ?? formulaNoVal];
};
export const getDownPayment = async (page, seriesCode) => {
  const carSeries = await getCarSeriesByCode(seriesCode);
  const [downPayment] = getGlobalConfigs(page, 'downPayment');
  return Number(carSeries?.downPayment ?? downPayment?.value);
};
/**
 *
 * @returns {Promise<ChatConfiguration>}
 */
export const getChatConfiguration = async () => {
  let result = webStorage.get(S_CHAT_CONFIG);
  if (!result) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.chatConfig
    });
    if (ex) {
      return [];
    }
    result = res;
    webStorage.set(S_CHAT_CONFIG, result);
  }
  return result;
};
export const getProvinceList = async (alpha2Code) => {
  const cache = webStorage.get(S_PROVINCE_LIST) ?? {};
  if (!cache[alpha2Code]?.length) {
    const [res, ex] = await api.order.getProvinceList(null, {
      countryCode: alpha2Code
    });
    if (ex || !res?.length) {
      return [];
    }
    cache[alpha2Code] = res[0]?.stateList || [];
  }
  webStorage.set(S_PROVINCE_LIST, cache);
  return cache[alpha2Code];
};
export const getProvinceOptions = async (alpha2Code) => {
  const list = await getProvinceList(alpha2Code);
  return list.map((x) => ({
    code: x.stateCode,
    text: x.state
  }));
};

export const getRemoteGlobalConfig = async () => {
  let result = webStorage.get(S_GLOBAL_CONFIG);
  if (!result) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.globalConfig
    });
    if (ex) {
      return [];
    }
    result = res;
    webStorage.set(S_GLOBAL_CONFIG, result);
  }
  return result;
};
export const getLanguages = async () => {
  const lang = getLangFromUrl();
  let cache = webStorage.get(S_LANGUAGES) || {};
  if (!cache[lang]?.length) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.languages,
      isDeep: true
    });
    if (ex) {
      return [];
    }
    cache[lang] = res?.children ?? [];
  }
  return cache[lang];
};
export const getLangOptions = async () => {
  const list = await getLanguages();
  const options = list.map((x) => ({
    code: x.code,
    text: ifEmpty(x.fullText, x.text, x.code),
    data: x
  }));
  sortByChar(options, (x) => x.text);
  return options;
};
export const getMarketLangOptions = async () => {
  const alpha2Code = getCurrentAlpha2Code();
  const list = await getLangOptions();
  return list.filter((x) => new RegExp(`-${alpha2Code}$`, 'gi').test(x.code));
};
export const getStoreTypes = async () => {
  const lang = getLangFromUrl();
  let cache = webStorage.get(S_STORE_TYPES) ?? {};
  if (isNullOrEmpty(cache[lang])) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.storeType,
      isDeep: true
    });
    if (ex) {
      return [];
    }
    cache[lang] = res?.children ?? [];
    webStorage.set(S_STORE_TYPES, cache);
  }
  if (isNullOrEmpty(cache[lang])) return [];
  return cache[lang];
};
export const getDeliveryTimes = async () => {
  return getSitecoreData('deliveryTime', S_DELIVERY_TIME, true, (res) => {
    return res?.children ?? [];
  });
};
export const getDeliveryTime = async (code) => {
  const list = await getDeliveryTimes();
  return list.find((x) => equalString(x.code, code))?.text;
};
export const getVehicleConfigList = async () => {
  const { lang } = APP_CONST;
  const cache = webStorage.get(S_VEHICLE_CONFIG_LIST) ?? {};
  if (!cache[lang]?.length) {
    const [res] = await api.vehicleConfig.list();
    cache[lang] = isArray(res) ? res : [];
    webStorage.set(S_VEHICLE_CONFIG_LIST, cache);
  }
  return cache[lang];
};
export const setVehicleConfigList = async (list) => {
  const { lang } = APP_CONST;
  const cache = webStorage.get(S_VEHICLE_CONFIG_LIST) ?? {};
  cache[lang] = list;
  webStorage.set(S_VEHICLE_CONFIG_LIST, cache);
};
export const getSitecoreData = async (configKey, storageKey, isDeep = true, formatter = (value) => value) => {
  const { lang } = APP_CONST;
  const cache = webStorage.get(storageKey) ?? {};
  if (!cache[lang]) {
    const [res] = await api.web.item({
      id: config.app.web.itemIds[configKey],
      isDeep
    });
    if (isNullOrEmpty(res)) return;
    let data = unWrapSitecoreItem(res);
    if (utils.obj.isFn(formatter)) {
      data = formatter(data);
    }
    cache[lang] = data;
    webStorage.set(storageKey, cache);
  }
  return cache[lang];
};
export const getDatePickerConfig = async () => {
  return getSitecoreData('datePickerConfig', S_DATE_PICKER_CONFIG);
};
export const getLocationTypes = async () => {
  return await getSitecoreData('locationTypes', S_LOCATION_TYPES, true, (data) => data?.children ?? []);
};
