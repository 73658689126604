<script setup>
import { computed } from 'vue';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { appendQuery, getBetterUrl, getUrls } from '@/utils/uri-utils';

const props = defineProps({
  page: {
    type: Object
  },
  product: {
    type: Object
  }
});
let [goodsDetailHref] = getUrls(props.page, 'shopLifeMerchandiseDetailLink');
goodsDetailHref = getBetterUrl(goodsDetailHref);
const productLink = computed(() => {
  return {
    href: isNullOrEmpty(goodsDetailHref)
      ? null
      : appendQuery(goodsDetailHref, {
          spuBusId: props.product.spuBusId
        }),
    title: props.product.name
  };
});
</script>

<template>
  <a class="s-shop-product-card" v-bind="productLink" v-if="product">
    <div class="s-shop-product-card__img-wrapper">
      <div class="s-shop-product-card__img" v-aspect-ratio="'25_21'">
        <img :src="product.mainImage" :alt="product.name" />
      </div>
    </div>
    <div class="s-shop-product-card__content">
      <ul class="s-shop-product-card__tags">
        <li v-for="(tag, index) of product.tagList" :key="index">{{ tag.tagName }}</li>
        <li v-if="product.sellOut === 1">{{ $t('Out of stock') }}</li>
      </ul>
      <div class="s-shop-product-card__main">
        <div class="s-shop-product-card__name">{{ product.name }}</div>
        <div class="s-shop-product-card__price">{{ $formatShopMoney(product.minPrice, product.currency) }}</div>
      </div>
    </div>
  </a>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-shop-product-card {
  display: block;
  background-color: $grey-light;
  color: $black;
  position: relative;
  overflow: hidden;
  &__img {
    aspect-ratio: 25/21;
    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &-wrapper {
      padding: 126px 30px;
    }
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 24px;
  }
  &__tags {
    list-style-type: none;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    color: black;
    > li {
      font-size: 12px;
      line-height: 20px;
      opacity: 0.6;
    }
  }
  &__name {
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
  }
  &__price {
    opacity: 0.6;
  }
  @include tablet-landscape {
    &__img {
      &-wrapper {
        padding: 60px 70px 80px 70px;
      }
    }
  }
}
</style>
