<script setup>
import { computed, inject, reactive, toRef, toRefs } from 'vue';
import useAppStore from '@/store/appStore';
import utils from '@/utils';

const emit = defineEmits(['change']);
const props = defineProps({
  fields: {
    type: Object
  },
  index: {
    type: Number
  }
});
/**@type AppStore*/
const appStore = useAppStore();
const rootState = inject('one-form-state');
const rootMethods = inject('one-form-methods');
const state = reactive({
  /**@type OneFormSectionRef*/
  sectionRef: null,
  collapsed: props.index > 0,
  readonly: false,
  invisible: props.fields?.invisible.value ?? false,
  preSelected: false,
  timeSheet: ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00']
});
const expand = async () => {
  state.collapsed = false;
};
const setTime = (time) => {
  rootState.selectedTime = time;
};
const onConfirm = async () => {
  await state.sectionRef.collapse();
  rootMethods.expandNext(props.index);
};
defineExpose({ expand, state: toRefs(state) });
</script>

<template>
  <one-form-section :fields="fields" :index="index" v-model:collapsed="state.collapsed" v-model:readonly="state.readonly" :done="!!rootState.selectedDate" :ref="(e) => (state.sectionRef = e)">
    <template #collapsed>{{ rootState.selectedDate }}&nbsp; {{ rootState.selectedTime }}</template>
    <div class="s-of-date">
      {{ rootState.selectedDate }}
      <jss-rich-text class="fs-20 mg-b-24" :field="fields.title" />
      <date-pick-view v-model:date="rootState.selectedDate" />
      <jss-rich-text class="mg-b-4" :field="fields.timeTitle" />
      <div class="s-of-date__time-sheet">
        <template v-for="time in state.timeSheet" :key="time">
          <div class="s-of-date__time" :class="[{ active: rootState.selectedTime === time }]" @click="setTime(time)">{{ time }}</div>
        </template>
      </div>
      <site-button v-bind="fields.nextButton" @click="onConfirm" />
    </div>
  </one-form-section>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.s-of-date {
  &__time-sheet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin: 40px 0;
    user-select: none;
  }
  &__time {
    text-align: center;
    padding: 12px;
    border: 1px solid $grey-89;
    cursor: pointer;
    &.active {
      background: radial-gradient(77.27% 77.27% at 50.83% 100%, rgba(46, 46, 46, 0.8) 0%, #000000 100%);
      color: $yellow;
    }
  }
  .e-site-button {
    width: 100%;
  }
}
</style>
