<template>
  <div class="s-spinner-ball-grid-pulse" :class="[{ [`size-${size}`]: size }, ...$handleComClass($props['class'])]">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'spinner-ball-grid-pulse',
  props: {
    size: {
      type: String
    },
    class: null
  }
};
</script>

<style lang="scss">
.s-spinner-ball-grid-pulse {
  display: block;
  font-size: 0;
  color: #000;
  position: relative;
  width: 36px;
  height: 36px;
  > div {
    position: relative;
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
    width: 8px;
    height: 8px;
    margin: 2px;
    border-radius: 100%;
    animation-name: ball-grid-pulse;
    animation-iteration-count: infinite;

    &:nth-child(1) {
      animation-duration: 0.65s;
      animation-delay: 0.03s;
    }

    &:nth-child(2) {
      animation-duration: 1.02s;
      animation-delay: 0.09s;
    }

    &:nth-child(3) {
      animation-duration: 1.06s;
      animation-delay: -0.69s;
    }

    &:nth-child(4) {
      animation-duration: 1.5s;
      animation-delay: -0.41s;
    }

    &:nth-child(5) {
      animation-duration: 1.6s;
      animation-delay: 0.04s;
    }

    &:nth-child(6) {
      animation-duration: 0.84s;
      animation-delay: 0.07s;
    }

    &:nth-child(7) {
      animation-duration: 0.68s;
      animation-delay: -0.66s;
    }

    &:nth-child(8) {
      animation-duration: 0.93s;
      animation-delay: -0.76s;
    }

    &:nth-child(9) {
      animation-duration: 1.24s;
      animation-delay: -0.76s;
    }
  }
  &.la-dark {
    color: #333;
  }
  @keyframes ball-grid-pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    50% {
      opacity: 0.35;
      transform: scale(0.45);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}
</style>
