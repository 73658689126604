<template>
  <div class="s-merchandise-shopping-cart-pop" :class="show ? 'active' : null" data-code="cart-pop" @click="closeCart">
    <div class="s-merchandise-shopping-cart-pop-content" :class="showCartDetail ? 'active' : null">
      <div class="s-merchandise-shopping-cart-pop-content-close" @click="closeShoppingCart">
        <icon name="close" />
      </div>
      <div>
        <jss-rich-text class="s-merchandise-shopping-cart-pop-content-title" :field="title" tag="div" />
        <div class="s-merchandise-shopping-cart-pop-content-product" v-if="showCartDetail">
          <div class="s-merchandise-shopping-cart-pop-content-product-detail">
            <div class="s-merchandise-shopping-cart-pop-content-product-detail-image">
              <img :src="skuImage" :alt="name" />
            </div>
            <div class="s-merchandise-shopping-cart-pop-content-product-detail-content">
              <div class="s-merchandise-shopping-cart-pop-content-product-detail-content-name">{{ name }}</div>
              <div class="s-merchandise-shopping-cart-pop-content-product-detail-content-attr">{{ attr }}</div>
              <div class="s-merchandise-shopping-cart-pop-content-product-detail-content-content">
                <div class="s-merchandise-shopping-cart-pop-content-product-content-detail-content-unit" v-html="`${$t('Qty')}:`" />
                <div class="s-merchandise-shopping-cart-pop-content-product-content-detail-content-quantity">{{ quantity }}</div>
              </div>
              <div class="s-merchandise-shopping-cart-pop-content-product-detail-content-price">{{ $formatShopMoney(price, currency) }}</div>
            </div>
          </div>
          <div class="delete" @click="removeMerchandise">
            <icon :field="icon" />
          </div>
        </div>
      </div>
      <div class="s-merchandise-shopping-cart-pop-content-bottom" v-if="showCartDetail">
        <div class="s-merchandise-shopping-cart-pop-content-bottom-header">
          <div class="total">
            <jss-rich-text class="s-merchandise-shopping-cart-pop-content-bottom-header-title" :field="subtotalTitle" tag="div" />
            <div class="icon-content" @mouseenter="iconRemarksHover" @mouseleave="iconRemarksOut" @click="iconRemarksClick" v-if="!$isNullOrEmpty(disclaimer?.fields?.text?.value)">
              <icon name="warn" />
            </div>
            <section class="edtion-tip-dialog" v-if="!$isNullOrEmpty(disclaimer?.fields?.text?.value)" v-show="showDisclaimer">
              <div class="frame-size-tip-close" @click="iconRemarksHide">
                <Icon name="close" />
              </div>
              <jss-rich-text class="frame-size-tip" v-if="disclaimer?.fields?.text?.value" :field="disclaimer?.fields?.text" tag="div" />
            </section>
          </div>
          <div class="s-merchandise-shopping-cart-pop-content-bottom-header-total">
            {{ $formatShopMoney(totalPrice, currency) }}
          </div>
        </div>
        <SiteButton
          :fields="{
            text: {
              value: viewCartButton.fields.text.value + '(' + cartTotalQuantity + ')'
            },
            icon: viewCartButton.fields.icon,
            buttonType: viewCartButton.fields.buttonType,
            link: viewCartButton.fields.link
          }"
          v-if="showCartDetail"
          class="maxbutton viewCart"
        />
        <SiteButton v-bind="checkoutButton" class="maxbutton checkout" v-if="showCartDetail" @click="checkout" />
      </div>
      <jss-rich-text class="s-merchandise-shopping-cart-pop-emptyText" :field="emptyText" v-if="!showCartDetail" tag="div" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import useAppStore from '@/store/appStore';
import { getGlobalConfigs, buildShopCheckoutUrl, getGlobalCfgValues } from '@/utils/site-utils';

export default {
  name: 'MerchandiseShoppingCartPop',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    },
    title: {
      type: Object
    },
    name: {
      type: String
    },
    skuImage: {
      type: String
    },
    attr: {
      type: String
    },
    quantity: {
      type: Number
    },
    cartTotalQuantity: {
      type: Number
    },
    price: {
      type: Number
    },
    subtotalTitle: {
      type: Object
    },
    disclaimer: {
      type: Object
    },
    checkoutButton: {
      type: Object
    },
    viewCartButton: {
      type: Object
    },
    icon: {
      type: Object
    },
    show: {
      type: Boolean
    },
    showCartDetail: {
      type: Boolean
    },
    emptyText: {
      type: Object
    },
    totalPrice: {
      type: Number
    },
    weightRef: {
      type: Object
    },
    currency: {
      type: String
    }
  },
  setup(props, context) {
    const appStore = useAppStore();
    const state = reactive({
      showDisclaimer: false
    });
    const [shopWeightLimit] = getGlobalConfigs(props.page, 'shopWeightLimit');
    const methods = {
      iconRemarksHover() {
        state.showDisclaimer = true;
      },
      iconRemarksOut(event, index) {
        state.showDisclaimer = false;
      },
      iconRemarksClick() {
        state.showDisclaimer = true;
      },
      iconRemarksHide() {
        state.showDisclaimer = false;
      },
      closeShoppingCart() {
        context.emit('changeShowShoppingCart', false);
      },
      closeCart(event) {
        if (event.target.getAttribute('data-code') === 'cart-pop') {
          context.emit('changeShowShoppingCart', false);
        }
      },
      removeMerchandise() {
        context.emit('removeMerchandise', false);
      },
      checkout() {
        if (appStore.cartTotalWeight > shopWeightLimit?.value) {
          props.weightRef.open();
          return false;
        }
        let param = [];
        if (appStore.cartInfo?.length) {
          appStore.cartInfo.forEach((item) => {
            let paramItem = {
              skuId: item.skuId,
              quantity: item.quantity
            };
            param.push(paramItem);
          });
        }
        window.location = buildShopCheckoutUrl(props.page, param, true);
      }
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';

.s-merchandise-shopping-cart-pop {
  width: 100%;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.6s;
  &.active {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
  &-emptyText {
    font-size: 16px;
    line-height: 1;
    color: #757575;
    margin-top: 24px;
  }
  &-content {
    width: 100%;
    height: calc(100vh - 82px);
    background: $white;
    position: absolute;
    bottom: 0;
    padding: 24px 24px 38px 24px;
    display: flex;
    flex-direction: column;
    &.active {
      justify-content: space-between;
    }
    &-close {
      width: 32px;
      height: 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 24px;
      top: 24px;
    }
    &-title {
      font-size: 26px;
      line-height: 1;
      letter-spacing: 2px;
      margin-top: 74px;
    }
    &-product {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 24px;
      .delete {
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      &-detail {
        display: flex;
        &-image {
          width: 96px;
          height: 105px;
          background: #f2f1f0;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 90px;
            height: 90px;
            object-fit: cover;
          }
        }
        &-content {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          &-name {
            font-size: 12px;
            line-height: 20px;
            font-weight: 700;
            color: $black;
          }
          &-attr {
            font-size: 12px;
            line-height: 20px;
            color: #757575;
            margin-top: 6px;
          }
          &-content {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 20px;
            color: #757575;
          }
          &-price {
            font-size: 12px;
            line-height: 20px;
            color: #757575;
          }
        }
      }
    }
    &-bottom {
      border-top: 1px solid #e3e3e3;
      justify-content: flex-end;
      &-header {
        display: flex;
        align-items: center;
        padding-top: 22px;
        position: relative;
        justify-content: space-between;
        .total {
          display: flex;
          align-items: center;
        }
        &-title {
          font-size: 18px;
          line-height: 28px;
          color: $black;
          font-weight: 700;
        }
        .icon-content {
          width: 16px;
          height: 16px;
          margin-left: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        .edtion-tip-dialog {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 30px;
          background: #f7f6f6;
          color: $black;
          padding: 40px;
          .frame-size-tip-close {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .frame-size-tip {
            font-size: 12px;
            line-height: 20px;
            color: $black;
          }
        }
        &-total {
          font-size: 18px;
          line-height: 28px;
          color: $black;
          font-weight: 700;
        }
      }
      .viewCart {
        margin-top: 24px;
      }
      .checkout {
        margin-top: 11px;
      }
    }
  }
}

@include tablet-landscape {
  .s-merchandise-shopping-cart-pop {
    transform: translateY(0) translateX(100%);
    &-emptyText {
      margin-top: 32px;
    }
    &-content {
      width: grid-width(9.5);
      height: 100vh;
      right: 0;
      top: 0;
      padding: 32px;
      justify-content: flex-start;
      &-close {
        right: 32px;
        top: 32px;
      }
      &-title {
        font-size: 32px;
        margin-top: 58px;
      }
      &-product {
        margin-top: 32px;
        .delete {
          width: 24px;
          height: 24px;
        }
        &-detail {
          &-image {
            width: 120px;
            height: 120px;
          }
        }
      }
      &-bottom {
        margin-top: 32px;
        &-header {
          padding-top: 27px;
          .edtion-tip-dialog {
            .frame-size-tip-close {
              display: none;
            }
          }
        }
        .checkout {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
