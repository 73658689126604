<script setup>
import { reactive, inject, onMounted, toRefs, computed, nextTick, watch } from 'vue';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';
import useAppStore from '@/store/appStore';
import utils from '@/utils';
import { equalString, ifEmpty } from '@/utils/string-utils';
import { isNullOrEmpty, isNullOrWhitespace } from '@/utils/obj-utils';
import { debounce } from 'lodash';

const emit = defineEmits(['change']);
const props = defineProps({
  fields: {
    type: Object
  },
  index: {
    type: Number
  }
});
/**@type AppStore*/
const appStore = useAppStore();
const toast = inject('toast');
const rootState = inject('one-form-state');
const state = reactive({
  /**@type DynamicForm*/
  formRef: null,
  /**@type OneFormSectionRef*/
  sectionRef: null,
  customerTypes: [],
  collapsed: props.index > 0,
  readonly: false,
  invisible: props.fields?.invisible.value ?? false
});
const form = computed(() => utils.oneForm.buildFormItems(props.fields?.formItems));
const toggleBusinessFields = debounce((ct) => {
  const updateOptions = Object.create(null);
  for (let item of form.value.fields.items) {
    const dataName = item.fields?.dataName.value;
    if (isNullOrWhitespace(dataName)) continue;
    const customerTypeVal = item.fields?.customerType?.fields.code.value;
    let visible = true;
    if (!isNullOrEmpty(customerTypeVal) && !equalString(customerTypeVal, ct?.code)) {
      visible = false;
    }
    updateOptions[dataName] = {
      visible
    };
  }
  console.log('updateOptions', updateOptions);
  state.formRef.updateItems(updateOptions);
}, 100);
const onCtChange = (ct) => {
  rootState.customerType = ct;
  toggleBusinessFields(ct);
};
const validate = () => {
  if (state.invisible) {
    return [true, utils.site.getFormDefaultValues(form.value)];
  }
  if (state.collapsed && !rootState.detailFormValid) {
    toast.showError(ifEmpty(props.fields?.unFilledErrMsg.value, 'Please fill the detail form'));
    state.sectionRef.intoView().then(() => {
      state.collapsed = false;
    });
    return [false, null];
  }
  return state.formRef.validate();
};
const onFormChange = (key, value, item) => {
  emit('change', 'detail-form', { key, value, item });
};
const expand = async () => {
  state.collapsed = false;
};
watch(
  () => state.collapsed,
  (_collapsed) => {
    if (!_collapsed) {
      nextTick(() => {
        toggleBusinessFields(rootState.customerType);
      });
    }
  }
);
onMounted(() => {
  const customerTypes = unWrapSitecoreItem(props.fields?.customerTypes);
  console.log('form', form);
  if (customerTypes?.length > 0) {
    state.customerTypes = customerTypes;
    rootState.customerType = customerTypes[0];
  }
});
defineExpose({ validate, expand, state: toRefs(state) });
</script>

<template>
  <one-form-section :fields="fields" :index="index" v-model:collapsed="state.collapsed" v-model:readonly="state.readonly" :ref="(e) => (state.sectionRef = e)">
    <div class="s-of-detail-form">
      <jss-rich-text class="fs-20 mg-b-32" :field="fields.title" />
      <ct-selector :options="state.customerTypes" :value="rootState.customerType" @change="onCtChange" />
      <dynamic-form :form="form" :ref="(e) => (state.formRef = e)" @change="onFormChange" />
    </div>
  </one-form-section>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.s-of-detail-form {
  margin-bottom: -32px;
  .s-dynamic-form {
    padding-top: 40px;
    padding-bottom: 0;
  }
}
</style>
