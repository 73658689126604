<script setup>
import { computed } from 'vue';
import { buildConfigField } from '@/utils/sitecore-utils';
import { useRoute } from 'vue-router';
import { canUseDOM } from '@/utils/dom-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';

const props = defineProps({
  fields: {
    type: Object
  }
});
const route = useRoute();
const returnFields = computed(() => {
  let href = route.redirectedFrom?.fullPath ?? (canUseDOM() ? global.document.referrer ?? global.opener?.document?.url : null);
  if (isNullOrEmpty(href)) href = '/';
  return {
    buttonType: buildConfigField('tertiary-button'),
    buttonTheme: buildConfigField('white'),
    text: { value: props.fields?.returnText.value },
    reverse: { value: true },
    link: {
      value: {
        href
      }
    }
  };
});
</script>

<template>
  <div class="s-of-intro" v-if="fields">
    <div class="s-of-intro__side">
      <div class="s-of-intro__side-main">
        <site-button :fields="returnFields" size="small" />
        <adaptive-image :field="fields.image" :is-background="false" v-if="$deviceComputes.isMobileOrTablet.value" />
        <div class="s-of-intro__words">
          <jss-rich-text :field="fields.title" />
          <jss-rich-text :field="fields.body" />
        </div>
      </div>
    </div>
    <div class="s-of-intro__main">
      <adaptive-image :field="fields.image" :is-background="false" v-if="$deviceComputes.largeThanTablet.value" />
      <div class="s-of-intro__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.s-of-intro {
  background-color: $black;
  &__side {
    padding: 0 grid-width-m(1);
    &-main {
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 32px 0;
    }
  }
  &__words {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__content {
    background-color: $white;
    padding: 24px 0 0;
  }
  @include tablet-landscape {
    @include grid-container;
    &__side {
      @include grid-block(3, 8);
      padding: 0;
    }
    &__main {
      @include grid-block(13, 10);
      padding: 0;
    }
    &__content {
      background-color: $white;
      padding: 0;
    }
  }
}
</style>
