<template>
  <div class="s-principle-item" :class="rootClasses">
    <Icon size="small" :svg="fields.icon.value.svgCode" v-if="fields.icon" />
    <div class="s-principle-item__index" v-else>{{ displayIndex }}</div>
    <JssText :field="fields.body || fields.text" tag="div" class="s-principle-item__body" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { overallSettingClasses } from '@/utils/site-utils';

export default {
  name: 'PrincipleItem',
  props: {
    fields: {
      type: Object
    },
    index: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const computes = {
      displayIndex: computed(() => (props.index > 9 ? '' + (props.index + 1) : '0' + (props.index + 1))),
      rootClasses: computed(() => [...overallSettingClasses(props)])
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-principle-item {
  @include component-overall-settings;
  @include component-themes;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 100px;
  height: 100px;
  padding-top: $space-24;
  &__index {
    @include h5;
  }
  &__body {
    @include h9;
    margin-top: $space-10;
  }
  @include tablet-landscape {
    width: 120px;
    height: 120px;
  }
}
</style>
