<template>
  <div class="s-merchandise-recommend-list" v-if="list.length">
    <div class="s-merchandise-recommend-list__content">
      <div class="s-merchandise-recommend-list__content__top">
        <jss-rich-text class="s-merchandise-recommend-list__content__top__title" :field="title" tag="div" />
        <div class="s-merchandise-recommend-list__content__top__pagination" v-if="list.length > 3">
          <div class="s-merchandise-recommend-list__content__top__pagination__left" ref="prevEl">
            <Icon name="left" size="small" />
          </div>
          <div class="s-merchandise-recommend-list__content__top__pagination__right" ref="nextEl">
            <Icon name="right" size="small" />
          </div>
        </div>
      </div>
      <div ref="swiperEl" class="s-merchandise-recommend-list__content__swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in list" :key="item.id">
            <RouterLink class="s-merchandise-recommend-list__content__swiper__item" :to="`/${lang}${shopLifeMerchandiseDetailLinkHref}?spuBusId=${item.spuBusId}`">
              <div class="s-merchandise-recommend-list__content__swiper__item__tag">
                <div class="s-merchandise-recommend-list__content__swiper__item__tag__item" v-for="tag in item.tagList" :key="tag.id">
                  {{ tag.tagName }}
                </div>
                <div class="s-merchandise-recommend-list__content__swiper__item__tag__item">{{ item.sellOut === 1 ? $t('Out of stock') : null }}</div>
              </div>
              <BackgroundImage class="s-merchandise-recommend-list__content__swiper__item__image" :image="item.cover" />
              <div class="s-merchandise-recommend-list__content__swiper__item__bottom">
                <div class="s-merchandise-recommend-list__content__swiper__item__name">{{ item.name }}</div>
                <div class="s-merchandise-recommend-list__content__swiper__item__attr">
                  <div class="s-merchandise-recommend-list__content__swiper__item__price" v-if="item.minPrice">
                    {{ $formatShopMoney(item.minPrice, currency) }}
                  </div>
                  <!-- <div class="c-merchandise-landing__content__swiper__item__color">
                    2 colors
                  </div> -->
                </div>
              </div>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick, computed } from 'vue';
import Swiper from 'swiper';
import api from '@/api';
import { isMobile } from '@/utils/dom-utils';
import { getCurrentAlpha2Code, getGlobalCfgValues, getPageAlpha2Code, getGlobalConfigs } from '@/utils/site-utils';
import { useRouter } from 'vue-router';

export default {
  name: 'MerchandiseRecommendList',
  props: {
    /**@type MerchandiseLandingFields*/
    title: {
      type: Object
    },
    shopLifeMerchandiseDetailLinkHref: {
      type: String
    },
    page: {
      type: Object
    },
    currency: {
      type: String
    }
  },
  setup(props) {
    let swiper = null;
    const router = useRouter();
    const state = reactive({
      list: [],
      swiperEl: null,
      prevEl: null,
      nextEl: null
    });
    const computes = {
      lang: computed(() => router.currentRoute.value?.params.lang ?? {})
    };
    const buildSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }
      swiper = new Swiper(state.swiperEl, {
        slidesPerView: 'auto',
        observer: true,
        observeParents: true,
        spaceBetween: isMobile() ? 20 : 24,
        navigation: {
          prevEl: state.prevEl,
          nextEl: state.nextEl
        }
      });
    };

    const methods = {
      async getRecommendedList(spuId) {
        const alpha2Code = getPageAlpha2Code(props.page);
        const currentAlpha2Code = getCurrentAlpha2Code();
        const [res] = await api.shop.getRecommendedList({
          spuId: spuId,
          pageNum: 1,
          pageSize: 100000,
          country: alpha2Code,
          currency: props?.currency
        });
        let list = [];
        if (res?.records?.length) {
          state.list = res.records;
          state.list.map((item) => {
            item.cover = {
              value: {
                src: item.mainImage
              }
            };
            if (item.currency) {
              state.currency = item.currency;
            }
            if (item?.tagList?.length) {
              item.tagList.map((option) => {
                if (option.tagCode === props?.fields?.newTagCode?.value) {
                  item.isNew = true;
                } else {
                  item.isNew = false;
                }
              });
            } else {
              item.isNew = false;
            }
          });
          nextTick(() => {
            buildSwiper();
          });
        }
      }
    };

    onMounted(() => {
      methods.getRecommendedList();
    });

    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-recommend-list {
  user-select: none;
  width: 100%;
  overflow: hidden;
  @include grid-container;
  &__content {
    width: 100%;
    @include grid-block(2, 12);
    overflow: hidden;
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__title {
        font-size: 32px;
        color: $black;
      }
      &__pagination {
        display: none;
      }
    }
    &__swiper {
      margin-top: 32px;
      padding-right: grid-width(3);
      .swiper-slide {
        width: grid-width(19);
        height: calc(grid-width(19) * 1.1 + 30px);
        background: #f2f1f0;
      }
      &__item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0 16px;
        &__tag {
          display: flex;
          gap: 10px;
          justify-content: flex-start;
          width: 100%;
          padding: 0 24px;
          min-height: 20px;
          &__item {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
        &__bottom {
          height: 48px;
          margin-top: 15px;
          width: calc(100% - 48px);
        }
        &__image {
          width: grid-width(14);
          height: calc(grid-width(14));
        }
        &__name {
          width: 100%;
          font-size: 18px;
          line-height: 32px;
          text-align: left;
          color: $black;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__price {
          width: calc(100% - 48px);
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: rgba(0, 0, 0, 0.6);
        }
        &__attr {
          display: flex;
          justify-content: space-between;
        }
        &__color {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.6);
          white-space: nowrap;
        }
      }
    }
  }
}
@include tablet-landscape {
  .s-merchandise-recommend-list {
    &__content {
      @include grid-block(3, 20);
      &__top {
        &__pagination {
          display: flex;
          gap: 27px;
          &__left {
            width: 24px;
            height: 24px;
            cursor: pointer;
            &.swiper-button-disabled {
              svg,
              path {
                fill: rgba(0, 0, 0, 0.5);
              }
            }
          }
          &__right {
            width: 24px;
            height: 24px;
            cursor: pointer;
            &.swiper-button-disabled {
              svg,
              path {
                fill: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      &__swiper {
        padding-right: 0;
        margin-top: 52px;
        .swiper-slide {
          width: calc((100% - 48px) / 3);
          height: calc(grid-width(6.5) * 1.04 + 50px);
        }
        &__item {
          padding: 32px 0 16px;
          &__image {
            height: calc((grid-width(20) - 48px) / 3 - 80px);
            width: calc((grid-width(20) - 48px) / 3 - 80px);
          }
          &__name {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
