<script setup></script>

<template>
  <div class="s-spinner-ball-beat">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<style lang="scss">
.s-spinner-ball-beat {
  display: block;
  font-size: 0;
  color: currentColor;
  position: relative;
  box-sizing: border-box;
  > div {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 100%;
    animation: ball-beat 0.7s -0.15s infinite linear;
    &:nth-child(2n-1) {
      animation-delay: -0.5s;
    }
  }
  @-webkit-keyframes ball-beat {
    50% {
      opacity: 0.2;
      transform: scale(0.75);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-moz-keyframes ball-beat {
    50% {
      opacity: 0.2;
      transform: scale(0.75);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-o-keyframes ball-beat {
    50% {
      opacity: 0.2;
      transform: scale(0.75);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes ball-beat {
    50% {
      opacity: 0.2;
      transform: scale(0.75);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}
</style>
