<script setup>
import { getCurrentInstance, onMounted, reactive, inject, ref, nextTick, computed } from 'vue';
import { appendQuery } from '@/utils/uri-utils';
import { getPageAlpha2Code } from '@/utils/site-utils';
import utils from '@/utils';
import api from '@/api';
import Swiper from 'swiper';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { useRoute } from 'vue-router';
import { equalString, ifEmpty } from '@/utils/string-utils';
import querystring from 'querystring';
const emit = defineEmits(['menu-command']);
const props = defineProps({
  /**@type VehicleConfigListFields*/
  fields: {
    type: Object
  },
  page: {
    type: Object
  },
  item: {
    type: Object
  }
});
const { proxy } = getCurrentInstance();
const { $jss } = proxy;
const toast = inject('toast');
const loading = inject('loading');
const route = useRoute();
let swiper;
const swiperEl = ref();
const paginationEl = ref();
const state = reactive({
  page: null,
  images: [],
  sendButtonProps: null,
  detailButtonProps: null
});
const sendEnabled = computed(() => isNullOrEmpty(props.item?.country) || equalString(state.alpha2Code, props.item?.country));
const itemLabels = computed(() => querystring.parse(ifEmpty(props.fields.itemLabels.value, '{}')));
const onMenuClicked = async (menu) => {
  console.log('menu-clicked', menu.code, props.item);
  switch (menu.code) {
    case 'open-configuration':
      {
        const url = utils.mhp.getMhpConfigUrlByCode(props.page, props.item.configurationCode);
        utils.dom.openWindow(url, '_blank');
      }
      break;
    case 'rename':
      {
        emit('menu-command', 'rename', props.item);
      }
      break;
    case 'delete':
      {
        const confirmed = await toast.confirm(`<div>${props.fields.deleteTitle.value}</div><br /><div>${props.fields.deleteBody.value}</div>`, {
          okText: props.fields.deleteOkText.value,
          cancelText: props.fields.deleteCancelText.value,
          centered: false,
          middle: true
        });
        if (!confirmed) break;
        loading.show();
        const [, ex] = await api.vehicleConfig.remove(null, {
          id: props.item.id
        });
        if (ex) {
          await toast.showEx(ex);
          return;
        }
        emit('menu-command', 'deleted', props.item);
        loading.hide();
      }
      break;
    default:
      break;
  }
};
const buildSwiper = () => {
  swiper = new Swiper(swiperEl.value, {
    height: 'auto',
    slidesPerView: 1,
    pagination: {
      el: paginationEl.value,
      clickable: true
    }
  });
};
const buildButton = (btn, enabled = true) => {
  if (isNullOrEmpty(btn?.fields?.link?.value?.href)) return null;
  const { link, ...rest } = btn.fields;
  let { href, ...linkRest } = link.value;
  if (enabled) {
    href = appendQuery(href, {
      transfer_code: props.item?.transferCode,
      filter_id: props?.item.filterId,
      configuration_code: props.item?.configurationCode,
      redirect_from: route.fullPath
    });
  } else {
    href = null;
  }
  return {
    fields: {
      ...rest,
      link: {
        value: {
          ...linkRest,
          href
        }
      }
    }
  };
};
onMounted(async () => {
  state.page = $jss.routeData();
  state.alpha2Code = getPageAlpha2Code(state.page);
  state.infos = JSON.parse(props.item.infoJson);
  state.configuration = JSON.parse(props.item.orgConfigInfo);
  state.sendButtonProps = buildButton(props.fields.sendButton, sendEnabled.value);
  state.detailButtonProps = buildButton(props.fields.detailButton);
  await nextTick();
  buildSwiper();
});
</script>

<template>
  <div class="s-vehicle-config-item">
    <div class="s-vehicle-config-item__images">
      <div class="swiper" ref="swiperEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-aspect-ratio="'16_9'" v-for="(image, i) in item.images" :key="i">
            <img :src="image" alt="" />
          </div>
        </div>
        <div class="s-vehicle-config-item__swiper-pagination" ref="paginationEl" />
      </div>
    </div>
    <div class="s-vehicle-config-item__main">
      <div class="s-vehicle-config-item__info">
        <div class="s-vehicle-config-item__id">
          <div class="s-vehicle-config-item__id-text">{{ item.configurationName }}</div>
          <pop-menu v-bind="fields.popMenu" @menu-clicked="onMenuClicked" />
        </div>
        <div class="s-vehicle-config-item__model">{{ item.vehicleModelName }}</div>
        <div class="s-vehicle-config-item__details">
          <label-value :label="$ifEmpty(itemLabels.lastUpdate, $t('Last update'))">{{ $formatDate(item.updateTime, 'dd-MM-yyyy HH:mm') }}</label-value>
          <label-value :label="$ifEmpty(itemLabels.totalPrice, $t('Total price'))" v-if="item.totalPrice > 0 && item.currency">{{ $formatMoney(item.totalPrice, item.currency) }}</label-value>
        </div>
      </div>
      <div>
        <div class="s-vehicle-config-item__buttons">
          <SiteButton class="s-vehicle-config-item__button" v-bind="state.sendButtonProps" :disabled="!sendEnabled" />
          <SiteButton class="s-vehicle-config-item__button" v-bind="state.detailButtonProps" />
        </div>
        <jss-rich-text class="mg-t-8 fs-12 __c-red" v-if="!sendEnabled" :field="fields.sendDisabledDesc" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-vehicle-config-item {
  padding: 0 grid-width-m(1);
  &__images {
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: 40px;
    .swiper {
      width: 100%;
      height: 100%;
      .swiper-wrapper {
        height: 100%;
      }
      .swiper-slide {
        width: 100%;
        height: 100% !important;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
  &__swiper-pagination {
    position: absolute;
    z-index: 1;
    left: 24px;
    bottom: 24px;
    width: calc(100% - 48px);
    display: flex;
    justify-content: center;
    gap: 16px;
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $white;
      &-active {
        background-color: $yellow;
      }
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 12px;
    line-height: 20px;
    .e-label-value {
      padding: 0;
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      &__left {
        color: $grey-next;
      }
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__id {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    &-text {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
  &__model {
    font-size: 32px;
  }
  &__buttons {
    margin-top: 24px;
  }
  @include tablet-landscape {
    padding: 0;
    @include grid-container;
    &__images {
      @include grid-block(3, 12);
      margin-bottom: 0;
      overflow: hidden;
    }
    &__swiper-pagination {
      left: grid-width(2);
      width: grid-width(8);
    }
    &__main {
      @include grid-block(16, 7);
      justify-content: space-between;
      padding-bottom: 16px;
      .e-site-button {
        &.primary-button {
          width: grid-width(6);
        }
      }
    }
    &__buttons {
      margin-top: 0;
    }
    &__info {
      gap: 24px;
    }
  }
}
</style>
