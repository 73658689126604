import services from '@/services';
import api from '@/api';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { APP_CONST } from '@/utils/constants';

export const getCarPrices = async (filterId, transferCode, formulaNo, alpha2Code, modelCode, priceIds, benefitPriceIds, accessoryPriceIds, inputName = []) => {
  const { lang } = APP_CONST;
  if (alpha2Code === 'NO') {
    const [resWeight] = await api.order.getFilterWeight(null, {
      filterId: filterId,
      transferCode: transferCode,
      language: lang
    });
    if (!isNullOrEmpty(resWeight)) {
      inputName.push({
        elementName: 'Weight',
        value: resWeight
      });
    }
  }
  if (['AT', 'NL', 'GB'].includes(alpha2Code)) {
    inputName.push({
      elementName: 'Variable',
      value: modelCode
    });
  }
  const [resPower] = await api.cc.power({
    filterId: filterId,
    transferCode: transferCode
  });
  if (resPower > 0) {
    inputName.push({
      elementName: 'Power',
      value: resPower
    });
  }
  const priceBody = {
    formulaNo: formulaNo,
    country: alpha2Code,
    priceCategory: 'Sale Price',
    sourceRange: ['Car Configurator', 'Product Center'],
    source: 'Car Configurator',
    inputName,
    sourceItemId: filterId,
    sourceItemIds: priceIds, // ['188,1567401186576506882', '188,1567401187889324033'],
    additionalSourceItemIds: [...(accessoryPriceIds ?? [])]
  };
  if (benefitPriceIds.length > 0) {
    priceBody.additionalSourceItemIds.push(...benefitPriceIds);
  }
  const [resPrice, exPrice] = await api.configuration.priceDetail(null, priceBody);
  if (exPrice) {
    return [];
  }
  const priceInfo = (resPrice?.priceInfo ?? []).filter((x) => !['PFU', 'IPT'].includes(x.elementName));
  return await services.dict.updateDictionaryValue(priceInfo, 'elementName', 'label', (x) => x.visibility);
};
