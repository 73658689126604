<script setup>
import { isNullOrEmpty } from '@/utils/obj-utils';
import { computed, inject, ref } from 'vue';
import { equalString } from '@/utils/string-utils';

const props = defineProps({
  fields: {
    type: Object
  },
  page: {
    type: Object
  }
});
const state = inject('send-to-dealer-state');
const mainRef = ref();
const financeInterestItem = computed(() => props.fields.form?.fields.items.find((x) => equalString(x.fields.dataName.value, 'financeInterest')));
const gotoDetail = () => {
  mainRef.value.scrollIntoView(true);
};
</script>

<template>
  <div class="s-std-cfm">
    <div class="s-std-cfm__banner">
      <div class="s-std-cfm__banner-img">
        <img :src="state.image" alt="" />
      </div>
      <div class="s-std-cfm__banner-content">
        <jss-rich-text class="fs-32 tl-fs-56 __lh-1 ls-3 mg-b-16" :field="fields.successTitle" />
        <template-string class="mg-b-40" :field="fields.successBody" :data="{ name: $concatString([state.formData?.firstName, state.formData?.lastName], ' '), agent: state.dealer?.storeName }" />
        <div class="s-std-cfm__detail-btn" @click="gotoDetail">
          <jss-rich-text :field="fields.viewDetailText" />
          <icon :field="fields.viewDetailIcon" />
        </div>
      </div>
    </div>
    <div class="s-std-cfm__main" ref="mainRef">
      <div class="s-std-cfm__section">
        <jss-rich-text class="s-std-cfm__section-title __ft-h3" :field="fields.storeTitle" />
        <div class="s-std-cfm__store" v-if="state.dealer">
          <div class="s-std-cfm__store-name">{{ state.dealer.storeName }}</div>
          <div class="s-std-cfm__store-address">
            {{ state.dealer.addressDetail }}
            <br />
            {{ state.dealer.extra?.storeZipCode }} {{ state.dealer.cityName }} {{ state.dealer.countryName }}
          </div>
          <div class="s-std-cfm__store-links">
            <a
              class="s-std-cfm__store-link"
              :href="`https://www.google.com/maps/place/${encodeURIComponent(state.dealer.addressDetail)}/@${encodeURIComponent($concatString([state.dealer.latitude, state.dealer.longitude], ','))}`"
              :title="fields.directionText.value"
              target="_blank"
            >
              <icon :field="fields.directionIcon" />
              <jss-text class="s-std-cfm__store-link-text" :field="fields.directionText" tag="span" />
            </a>
            <template v-if="!$isNullOrEmpty(state.dealer.extra?.salesLine)">
              <a class="s-std-cfm__store-link" :href="`tel:${state.dealer.extra?.salesLine}`">
                <icon :field="fields.phoneIcon" />
                <span class="s-std-cfm__store-link-text">{{ state.dealer.extra?.salesLine }}</span>
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="s-std-cfm__section">
        <jss-rich-text class="s-std-cfm__section-title __ft-h3" :field="fields.detailTitle" />
        <div class="s-std-cfm__detail">
          <div class="s-std-cfm__detail-item">
            <div class="s-std-cfm__detail-item-name">{{ $t('Name') }}</div>
            <div class="s-std-cfm__detail-item-content">{{ $concatString([state.formData?.firstName, state.formData?.lastName], ' ') }}</div>
          </div>
          <div class="s-std-cfm__detail-item">
            <div class="s-std-cfm__detail-item-name">{{ $t('Email address') }}</div>
            <div class="s-std-cfm__detail-item-content">{{ state.formData?.email }}</div>
          </div>
          <div class="s-std-cfm__detail-item">
            <div class="s-std-cfm__detail-item-name">{{ $t('Phone number') }}</div>
            <div class="s-std-cfm__detail-item-content">{{ $concatString([state.formData?.mobile.area.code, state.formData.mobile?.number], '') }}</div>
          </div>
          <div class="s-std-cfm__detail-item" v-if="!$isNullOrEmpty(state.formData?.comments)">
            <div class="s-std-cfm__detail-item-name">{{ $t('Your message') }}</div>
            <div class="s-std-cfm__detail-item-content">{{ state.formData?.comments }}</div>
          </div>
          <div class="s-std-cfm__detail-item" v-if="state.formData?.financeInterest">
            <div class="s-std-cfm__detail-item-name">{{ $t('Your request') }}</div>
            <div class="s-std-cfm__detail-item-content" v-html="financeInterestItem?.fields.mandatoryText?.value" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-std-cfm {
  $c: &;
  background: $black;
  color: $white;
  &__banner {
    position: relative;
    &-img {
      position: relative;
      > img {
        width: 100%;
        height: auto;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, $black, transparent 30%);
      }
    }
    &-content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 32px;
    }
  }
  &__detail-btn {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    .e-icon {
      svg {
        fill: $white;
        width: 16px;
        height: auto;
        > path {
          fill: $white;
        }
      }
    }
    &:hover {
      color: $yellow;
    }
  }
  &__main {
    padding: 0 32px;
  }
  &__section {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &:first-child {
      margin-top: 24px;
    }
    &:before {
      content: '';
      border-top: 1px solid $grey-night;
      margin-bottom: 24px;
    }
  }
  &__store {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    &-name {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &-address {
      color: $grey-taubmans;
    }
  }
  &__store-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  &__store-link {
    display: flex;
    align-items: center;
    gap: 8px;
    .e-icon {
      color: $yellow;
      > svg {
        width: 18px;
      }
    }
    &-text {
      &:hover {
        text-decoration: underline;
      }
    }
    &:hover {
      color: $yellow;
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__detail-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-name {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &-content {
      color: $grey-taubmans;
    }
  }
  @include tablet-landscape {
    &__banner {
      @include height-except-header;
      &-img {
        height: 100%;
        > img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &-content {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 40px grid-width(2);
        background: linear-gradient(to right, rgba($black, 0.75), transparent 75%);
      }
    }
    &__detail-btn {
      left: auto;
      transform: translateX(0);
    }
    &__main {
      padding: 0;
    }
    &__section {
      @include grid-container;
      margin-top: 60px;
      &:first-child {
        margin-top: 40px;
      }
      &:before {
        @include grid-block(3, 20);
        margin-bottom: 36px;
      }
      &-title {
        @include grid-block(3, 7);
      }
    }
    &__store {
      @include grid-block(11, 12);
    }
    &__detail {
      @include grid-block(11, 12);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: grid-width(1);
      row-gap: 48px;
    }
  }
  @include desktop-large {
    &__section {
      &-title {
        @include grid-block(3, 6);
      }
    }
    &__store,
    &__detail {
      @include grid-block(10, 13);
    }
  }
}
</style>
