<script setup>
import { transitions } from '@/utils/transitions';
import { inject, nextTick, reactive } from 'vue';
import utils from '@/utils';
import { scrollToEl } from '@/utils/site-utils';
import gsap from 'gsap';
import { sitecoreProps } from '@/utils/constants';
import { sleep } from '@/utils/dom-utils';
const emit = defineEmits(['update:collapsed', 'update:readonly']);
const props = defineProps({
  fields: {
    type: Object
  },
  index: {
    type: Number
  },
  collapsed: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean
  },
  done: {
    type: Boolean
  },
  bordered: {
    type: Boolean,
    default: true
  },
  ...sitecoreProps
});
let timeout;
const rootMethods = inject('one-form-methods');
const state = reactive({
  rootEl: null
});
const onBeforeEnter = (el) => {
  el.style.overflow = 'hidden';
  el.style.height = 0;
  el.style.opacity = 0;
};
const onEnter = (el, done) => {
  el.style.overflow = 'hidden';
  gsap.killTweensOf(el);
  gsap.to(el, {
    height: el.scrollHeight,
    opacity: 1,
    duration: 0.5,
    ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
    onComplete() {
      el.style.overflow = 'visible';
      el.style.height = 'auto';
      done();
    }
  });
};
const onLeave = (el, done) => {
  gsap.killTweensOf(el);
  el.style.overflow = 'hidden';
  gsap.to(el, {
    height: 0,
    opacity: 0,
    duration: 0.5,
    ease: 'cubic-bezier(0.38, 0.015, 0, 0.995)',
    onComplete() {
      el.style.overflow = 'hidden';
      done();
    }
  });
};
const onEdit = async () => {
  await intoView();
  emit('update:collapsed', false);
};
const collapse = () => {
  if (timeout) clearTimeout(timeout);
  emit('update:collapsed', true);
  return new Promise((resolve) => {
    utils.site.scrollToEl(state.rootEl, 'top', true);
    sleep(100).then(() => {
      utils.dom.hideHeader();
      timeout = setTimeout(() => {
        resolve();
      }, 236);
    });
  });
};
const intoView = () => {
  return new Promise((resolve) => {
    if (timeout) clearTimeout(timeout);
    utils.site.scrollToEl(state.rootEl, 'top', true);
    timeout = setTimeout(resolve, 336);
  });
};
const lock = () => {
  emit('update:readonly', true);
};

defineExpose({ collapse, intoView, lock });
</script>

<template>
  <div class="s-of-sec" :class="[{ bordered, done }]" v-if="!fields?.invisible?.value" :ref="(e) => (state.rootEl = e)">
    <transition :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
      <div v-if="collapsed">
        <div class="s-of-sec__collapsed">
          <div class="s-of-sec__collapsed-header">
            <div class="flex g8 ast">
              <icon class="s-of-sec__icon" name="check" size="tiny" v-if="done" />
              <jss-rich-text class="fs-20 fw-500" :field="done ? $tryValueField(fields.completedTitle, fields.title) : fields.title" />
            </div>
            <span class="s-of-sec__edit" @click="onEdit" v-if="!readonly">{{ $t('Edit') }}</span>
          </div>
          <div class="s-of-sec__collapsed-content" v-if="fields">
            <slot name="collapsed" />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="s-of-sec__content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.s-of-sec {
  $c: &;
  .s-dynamic-form {
    padding: 16px 0;
    .e-autocomplete-input {
      &__messages {
        display: none;
      }
    }
  }
  &__collapsed {
    padding: 24px grid-width-m(1);
    &-header {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      .e-icon {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    &-content {
      color: $grey-next;
    }
  }
  &__icon {
    width: 16px;
    height: 16px;
    margin-top: 7px;
  }
  &__edit {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
  }
  &__content {
    padding: 48px grid-width-m(1) 48px grid-width-m(1);
  }
  &.done {
    #{$c}__collapsed {
      &-content {
        padding-left: 24px;
      }
    }
  }
  + .s-of-sec {
    border-top: 1px solid $grey-89;
  }
  &:last-child {
    border-bottom: 1px solid $grey-89;
  }
  @include tablet-landscape {
    &__collapsed {
      padding: 24px grid-width(1);
    }
    &__content {
      padding: 72px grid-width(1);
    }
  }
}
</style>
