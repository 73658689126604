import { webStorage } from '@/utils/web-storage';
import { S_LANG } from '@/utils/web-storage-keys';
import api from '@/api';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { equalString } from '@/utils/string-utils';

export const getDictionary = async (dictCodeList, lang = null) => {
  if (!lang) lang = webStorage.get(S_LANG);
  const [dict, dictEx] = await api.order.dictionary(null, {
    dictCodeList,
    dictType: 'PRICE_ELEMENT_NAME_TYPE',
    languageType: (lang ?? 'en').toLowerCase()
  });
  return [dict, dictEx];
};
export const updateDictionaryValue = async (source, sourceKey, targetKey, predicate) => {
  let tempSource = source;
  if (predicate) {
    tempSource = source.filter((x) => predicate(x));
  }
  const dictCodeList = tempSource.map((x) => x[sourceKey]);
  const [dict] = await getDictionary(dictCodeList);
  tempSource.forEach((x, i) => {
    if (predicate && !predicate(x)) return true;
    x[targetKey] = dict?.length ? dict.find((y) => y.dictCode === x[sourceKey])?.dictValue : x[sourceKey];
    if (isNullOrEmpty(x[targetKey])) {
      x[targetKey] = x[sourceKey];
    }
  });
  return tempSource;
};
