<template>
  <div class="s-merchandise-return-list">
    <div class="s-merchandise-return-list__close" @click="onCloseMerchandiseDetail" v-if="$deviceComputes.isMobileOrTablet.value">
      <Icon name="close" type="tiny" />
    </div>
    <div class="s-merchandise-return-list__detail">
      <jss-rich-text class="s-merchandise-return-list__detail__title" :field="returnItemsText" />
      <div class="s-merchandise-return-list__detail__number">
        <div class="s-merchandise-return-list__detail__number__value">{{ numTotal }}</div>
        <div class="s-merchandise-return-list__detail__number__label" v-html="`${$t('Items')}:`" />
      </div>
      <div class="s-merchandise-return-list__detail__list">
        <div class="s-merchandise-return-list__detail__list__item" v-for="item in returnItems" :key="item.id">
          <div class="s-merchandise-return-list__detail__list__item__image">
            <img :src="item.image" :alt="item.name" />
          </div>
          <div class="s-merchandise-return-list__detail__list__item__content">
            <div class="s-merchandise-return-list__detail__list__item__content__name">{{ item.name }}</div>
            <div class="s-merchandise-return-list__detail__list__item__content__attr">{{ item.spec }}</div>
            <div class="s-merchandise-return-list__detail__list__item__content__qty">
              <div class="s-merchandise-return-list__detail__list__item__content__qty__label" v-html="`${$t('Qty')}:`" />
              <div class="s-merchandise-return-list__detail__list__item__content__qty__value">{{ item.num }}</div>
            </div>
            <div class="s-merchandise-return-list__detail__list__item__content__price">{{ $formatShopMoney(item.price, currency) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-merchandise-return-list__price">
      <!-- <div class="s-merchandise-return-list__price__item">
        <div class="s-merchandise-return-list__price__item__label" v-html="$t('Refund subtotal')" />
        <div class="s-merchandise-return-list__price__item__value">{{ $formatShopMoney(total, currency) }}</div>
      </div> -->
      <div class="s-merchandise-return-list__price__item">
        <div class="s-merchandise-return-list__price__item__content">
          <div class="s-merchandise-return-list__price__item__content__label" v-html="$t('Total refund')" />
          <div class="s-merchandise-return-list__price__item__content__item" v-html="$t('Inc VAT')" />
        </div>
        <div class="s-merchandise-return-list__price__item__value">{{ $formatShopMoney(total, currency) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, toRefs } from 'vue';
import { getGlobalCfgValues } from '@/utils/site-utils';
import gsap from 'gsap';
export default {
  name: 'MerchandiseReturnList',
  props: {
    step: {
      type: Number
    },
    returnItems: {
      type: Object
    },
    total: {
      type: Number
    },
    page: {
      type: Object
    },
    returnItemsText: {
      type: Object
    },
    currency: {
      type: String
    }
  },
  setup(props, context) {
    const state = reactive({
      numTotal: 0
    });
    const methods = {
      onCloseMerchandiseDetail() {
        context.emit('onCloseMerchandiseDetail');
      }
    };
    onMounted(() => {
      props.returnItems?.forEach((item) => {
        state.numTotal += item.num;
      });
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-return-list {
  background: $black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100vh;
  padding-top: 52px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  &__close {
    color: $white;
    height: 56px;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }
  &__detail {
    padding: 0 grid-width(2) 48px;
    flex: 1;
    color: $white;
    &__title {
      font-size: 26px;
      line-height: 32px;
    }
    &__number {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 24px;
      &__label {
        font-size: 12px;
        line-height: 20px;
      }
      &__value {
        font-size: 12px;
        line-height: 20px;
      }
    }
    &__list {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      &__item {
        display: flex;
        gap: 20px;
        &__image {
          width: 90px;
          height: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $white;
          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
          }
        }
        &__content {
          display: flex;
          flex-direction: column;
          &__name {
            font-size: 12px;
            line-height: 20px;
          }
          &__attr {
            font-size: 12px;
            line-height: 20px;
            color: #b8b8b8;
            margin-top: 5px;
          }
          &__qty {
            font-size: 12px;
            line-height: 20px;
            color: #b8b8b8;
            display: flex;
            gap: 3px;
          }
          &__price {
            font-size: 12px;
            line-height: 20px;
            color: #b8b8b8;
          }
        }
      }
    }
  }
  &__price {
    width: 100%;
    padding: 24px grid-width(2);
    border-top: 1px solid #2f2f2f;
    background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0, rgba(0, 0, 0, 0.9) 100%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &__content {
        display: flex;
        align-items: center;
        gap: 5px;
        &__label {
          font-size: 12px;
          line-height: 20px;
          color: $white;
        }
        &__item {
          font-size: 12px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      &__label {
        font-size: 12px;
        line-height: 20px;
        color: $white;
      }
      &__value {
        font-size: 12px;
        line-height: 20px;
        color: $white;
      }
    }
  }
  @include tablet-landscape {
    position: relative;
    height: auto;
    padding-top: 0px;
    min-height: 100vh;
    &__price {
      gap: 24px;
    }
    &__detail {
      padding: 60px grid-width(1) 0;
      &__list {
        height: calc(100vh - 113px - 60px - 124px);
        overflow: auto;
        padding-bottom: 48px;
      }
    }
  }
}
</style>
