import * as site from './siteService';
import * as order from './orderService';
import * as dict from './dictService';
import * as agent from './agentService';
import * as price from './priceService';
import * as finance from './financeService';
import * as map from './mapService';
import * as user from './userService';
import * as mall from './mallService';
import * as mhp from './mhpService';
const services = { site, user, order, dict, agent, price, finance, mall, mhp, map };
export default services;
