<script setup>
import { ref, inject, computed } from 'vue';
import { ifEmpty } from '@/utils/string-utils';
import svgIcons from '@/svg-icons';
import { isNullOrEmpty } from '@/utils/obj-utils';

const props = defineProps({
  title: {
    type: String
  },
  icon: {
    type: String
  },
  disclaimer: {
    type: Object
  },
  position: {
    type: String,
    default: 'center'
  },
  relativeEl: {
    type: Object
  }
});
const { deviceComputes } = inject('device-common');
const visible = ref(false);
const contentStyle = ref(null);
const disclaimerText = computed(() => ifEmpty(props.disclaimer?.fields?.text.value, props.disclaimer?.fields?.text, props.disclaimer));
const iconSvg = computed(() => ifEmpty(props.disclaimer?.fields?.icon.value?.svgCode, !isNullOrEmpty(props.icon) ? svgIcons[props.icon] : null));
const onMouseEnter = (e) => {
  const rect = e.target.getBoundingClientRect();
  const relativeRect = props.relativeEl?.getBoundingClientRect();
  if (deviceComputes.isMobileOrTablet.value) {
    contentStyle.value = {
      right: '24px',
      bottom: '24px',
      left: '24px'
    };
  } else {
    if (props.position === 'center') {
      contentStyle.value = {
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%'
      };
    }
    if (props.position === 'top-left') {
      contentStyle.value = {
        right: `${global.innerWidth - rect.left}px`,
        bottom: `${global.innerHeight - rect.top}px`
      };
    }
    if (props.position === 'top-whole') {
      if (relativeRect) {
        console.log('relativeRect', relativeRect);
        contentStyle.value = {
          right: `${global.innerWidth - relativeRect.right}px`,
          bottom: `${global.innerHeight - rect.top + 8}px`,
          width: `${relativeRect.width}px`
        };
      }
    }
  }
  visible.value = true;
};
const onMouseLeave = () => {
  visible.value = false;
};
</script>

<template>
  <div class="s-popover-disclaimer" v-if="!$isNullOrEmpty(disclaimerText)">
    <div class="s-popover-disclaimer__title" v-html="title" v-if="!$isNullOrEmpty(title)" />
    <slot class="s-popover-disclaimer__title" v-else />
    <icon class="s-popover-disclaimer__icon" :svg="iconSvg" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @touchstart="onMouseEnter" @touchend="onMouseLeave" />
    <teleport to="body">
      <transition name="fade-in">
        <div class="s-popover-disclaimer__content" :style="contentStyle" v-html="disclaimerText" v-if="visible" />
      </transition>
    </teleport>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-popover-disclaimer {
  display: flex;
  align-items: center;
  gap: 8px;
  &__title {
    display: inline;
  }
  &__icon {
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    top: -1px;
  }
  &__content {
    position: fixed;
    z-index: 4;
    padding: 16px;
    background-color: $grey-light;
    filter: drop-shadow(0 0 4px rgba($black, 0.2));
    color: $black;
  }
  &.theme {
    &-black {
      background-color: $black;
      color: $white;
      box-shadow: 0 2px 4px 0 rgba($white, 0.2);
    }
  }
  @include tablet-landscape {
    &__content {
      width: grid-width(6);
      padding: 16px 20px;
    }
  }
}
</style>
