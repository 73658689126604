import { webStorage } from '@/utils/web-storage';
import { S_FINANCE_CALCULATOR_CONFIG, S_LANG, S_FINANCE_STATUSES, S_SANTANDER_LOAN, S_CODE_WAVER_FINANCE, S_CA_AUTO_BANK_CONFIG, S_FINANCE_PRODUCT_MAP } from '@/utils/web-storage-keys';
import api from '@/api';
import { getCurrentAlpha2Code } from '@/utils/site-utils';
import { injectScript, loadStyle, sleep } from '@/utils/dom-utils';
import { equalString } from '@/utils/string-utils';
import config from '@/temp/config';
import services from '@/services/index';
import { getSitecoreData } from '@/services/siteService';

export const getSantanderLoanConfig = async (alpha2Code) => {
  const cache = webStorage.get(S_SANTANDER_LOAN) ?? {};
  if (!cache[alpha2Code]) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.santanderLoanConfig
    });
    if (ex) {
      return null;
    }
    cache[alpha2Code] = res;
    webStorage.set(S_SANTANDER_LOAN, cache);
  }
  return cache[alpha2Code];
};
export const getCodeWaverFinanceConfig = async () => {
  let cache = webStorage.get(S_CODE_WAVER_FINANCE);
  if (!cache) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.codeWaverFinanceConfig
    });
    if (ex) {
      return null;
    }
    cache = res;
    webStorage.set(S_SANTANDER_LOAN, cache);
  }
  return cache;
};
export const getCAAutoBankConfig = async () => {
  return services.site.getSitecoreData('caAutoBankConfig', S_CA_AUTO_BANK_CONFIG, false);
};
export const loadSantanderLoanPlugin = async () => {
  const alpha2Code = getCurrentAlpha2Code();
  const config = await getSantanderLoanConfig(alpha2Code);
  await injectScript(config.url, () => !!global.scfSimulation);
  await sleep(100);
  return [global.scfSimulation, config];
};
export const loadCodeWaverFinancePlugin = async () => {
  const config = await getCodeWaverFinanceConfig();
  loadStyle(config.css);
  await injectScript(config.url, () => !!global.cwFinance);
  await sleep(100);
  return [global.cwFinance, config];
};
export const loadCAAutoBankPlugin = async () => {
  const config = await getCAAutoBankConfig();
  await injectScript(config.url, () => !!global.FCABFC3);
  await sleep(100);
  return [global.FCABFC3, config];
};

export const getFinanceStatuses = async () => {
  const lang = webStorage.get(S_LANG);
  const cache = webStorage.get(S_FINANCE_STATUSES) || {};
  if (!cache[lang]) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.financeStatuses,
      isDeep: true
    });
    if (res?.children?.length > 0) {
      cache[lang] = res.children;
      webStorage.set(S_FINANCE_STATUSES, cache);
    }
  }
  return cache[lang];
};
export const getFinanceStatus = async (code) => {
  const statuses = await getFinanceStatuses();
  return statuses.find((s) => equalString(s.code, code));
};
export const getFinanceProductMap = async () => {
  return getSitecoreData('financeProductMap', S_FINANCE_PRODUCT_MAP, true, (res) => res?.children ?? []);
};
export const getFinanceProductMapItem = async (code) => {
  const items = await getFinanceProductMap();
  console.log('getFinanceProductMapItem', code, items);
  return items.find((x) => equalString(x.code, code));
};
