<template>
  <div class="s-reservation-model-select">
    <div class="s-reservation-model-select__model" :class="[{ active: selectedModel?.id === model?.id }]" v-for="(model, i) in models" :key="i">
      <div class="s-reservation-model-select__model-header">
        <div class="s-reservation-model-select__radio" @click="onModelClick($event, model)" />
        <div class="s-reservation-model-select__model-name" @click="onModelClick($event, model)">{{ model?.fields.name.value }}</div>
      </div>
      <jss-rich-text class="s-reservation-model-select__body" :field="model?.fields.body" />
      <div class="s-reservation-model-select__sections">
        <div class="s-reservation-model-select__section" v-for="(section, j) in model?.fields.sections" :key="j">
          <div class="s-reservation-model-select__section-header" @click="toggleSection($event, section)">
            <jss-rich-text class="s-reservation-model-select__section-name" :field="section.fields.name" />
            <icon name="down" />
          </div>
          <transition
            :css="false"
            @before-enter="transitions.accordion.beforeEnter"
            @enter="transitions.accordion.enter"
            @leave="transitions.accordion.leave"
            @after-enter="onAfterEnter"
            @after-leave="onAfterLeave"
          >
            <div class="s-reservation-model-select__section-content" v-if="expandSections.includes(section)">
              <div class="s-reservation-model-select__section-content-main">
                <template v-for="(content, k) in section?.fields.contents" :key="k">
                  <component :is="$settingValue(content.fields.contentType)" :fields="content.fields" />
                </template>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { transitions } from '@/utils/transitions';

export default {
  name: 'reservation-model-select',
  emits: ['update:selected-model', 'collapse-change'],
  props: {
    models: {
      type: Array
    },
    selectedModel: {
      type: Object
    }
  },
  setup(props, ctx) {
    const methods = {
      onModelClick(e, model) {
        if (props.selectedModel?.id !== model?.id) {
          console.log('update model', model);
          ctx.emit('update:selected-model', model);
        }
      },
      toggleSection(e, section) {
        const index = state.expandSections.findIndex((x) => x.id === section.id);
        if (index >= 0) {
          state.expandSections.splice(index, 1);
        } else {
          state.expandSections.push(section);
        }
      },
      onAfterEnter() {
        ctx.emit('collapse-change');
      },
      onAfterLeave() {
        ctx.emit('collapse-change');
      }
    };
    const state = reactive({
      expandSections: []
    });
    return {
      ...toRefs(state),
      ...methods,
      transitions
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-reservation-model-select {
  $c: &;
  --line-color: #{$grey-light};
  &__model {
    border: 1px solid var(--line-color);
    &-header {
      padding: 12px 16px;
      border-bottom: 1px solid var(--line-color);
      display: flex;
    }
    &-name {
      user-select: none;
      cursor: pointer;
    }
    + #{$c}__model {
      margin-top: 40px;
    }
    padding-bottom: 12px;
    &.active {
      --line-color: #{$black};
      #{$c}__radio {
        &:before {
          width: 10px;
          height: 10px;
          background: $black;
        }
      }
    }
  }
  &__radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid var(--line-color);
    margin-right: 8px;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-radius: 50%;
      background: transparent;
      transition: all 0.4s ease;
    }
  }
  &__body {
    padding: 12px 16px;
  }
  &__sections {
    padding: 0 16px;
  }
  &__section {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      cursor: pointer;
    }
    &-content {
      @include h10;
      &-main {
        padding-bottom: 16px;
      }
    }
    + #{$c}__section {
      border-top: 1px solid $grey-light;
    }
  }
}
</style>
