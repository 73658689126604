<template>
  <div class="s-reservation-model-lv-content">
    <div class="s-reservation-model-lv-content__item" v-for="(item, index) in fields.items" :key="index">
      <jss-rich-text class="s-reservation-model-lv-content__label" :field="item.fields.label" />
      <jss-rich-text class="s-reservation-model-lv-content__value" :field="item.fields.content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'reservation-model-lv-content',
  props: {
    fields: {
      type: Object
    }
  }
};
</script>

<style lang="scss">
.s-reservation-model-lv-content {
  &__item {
    display: flex;
    justify-content: space-between;
  }
  &__label {
    width: calc(50% - 12px);
    flex-shrink: 0;
  }
  &__value {
    width: calc(50% - 12px);
    flex-shrink: 0;
  }
}
</style>
