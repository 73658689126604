<script setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import Swiper from 'swiper';
import { getGlobalConfig, getGlobalConfigs } from '@/utils/site-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { appendQuery } from '@/utils/uri-utils';
import { getDeliveryTime } from '@/services/siteService';
import { equalString } from '@/utils/string-utils';
let swiper;
const props = defineProps({
  fields: {
    type: Object
  },
  page: {
    type: Object
  },
  item: {
    type: Object
  }
});
const swiperEl = ref();
const paginationEl = ref();
const deliveryTime = ref('');
const carModel = ref(null);
const showPriceDisclaimer = ref(false);
const vehicleFields = computed(() => props.fields.vehicleInfo?.fields);
const stockLink = computed(() => {
  const [stockDetailLink] = getGlobalConfigs(props.page, 'stockDetailLink');
  if (isNullOrEmpty(stockDetailLink.value) || isNullOrEmpty(props.item)) return null;
  const { href } = stockDetailLink.value;
  if (isNullOrEmpty(href)) return null;
  return {
    value: {
      ...stockDetailLink.value,
      href: appendQuery(href, {
        vin: props.item.vin
      })
    }
  };
});
const buildSwiper = () => {
  swiper = new Swiper(swiperEl.value, {
    slidesPerView: 1,
    pagination: {
      el: paginationEl.value
    }
  });
};
const onMouseEnter = () => {
  showPriceDisclaimer.value = true;
};
const onMouseLeave = () => {
  showPriceDisclaimer.value = false;
};
onMounted(async () => {
  const timeCode = props.item?.organization === 1 ? 'rcc' : 'store';
  deliveryTime.value = await getDeliveryTime(timeCode);
  await nextTick();
  buildSwiper();
});
</script>

<template>
  <div class="s-svc-v2" v-if="vehicleFields">
    <site-link :link="stockLink" class="s-svc-v2__swiper">
      <div class="swiper" ref="swiperEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(img, index) in item.images" :key="index">
            <div class="s-svc-v2__swiper-img" v-aspect-ratio="'19_15'">
              <img :src="img.externalUrl" alt="" />
            </div>
          </div>
        </div>
        <div class="s-svc-v2__pagination" ref="paginationEl" />
      </div>
      <transition name="options-ani">
        <div class="s-svc-v2__disclaimer" v-html="vehicleFields?.priceDisclaimer?.fields.text.value" v-if="!$isNullOrEmpty(vehicleFields?.priceDisclaimer?.fields.text.value) && showPriceDisclaimer" />
      </transition>
    </site-link>
    <div class="s-svc-v2__content">
      <div>
        <div class="flex jc-sb g24 fs-18 fw-500">
          <site-link :link="stockLink">
            <span>{{ $formatModel(item.model) }}</span>
            <div class="s-svc-v2__delivery">
              <div v-html="deliveryTime" />
            </div>
          </site-link>
          <div class="flex fdc ae">
            <div class="flex g4 ac hand" @mouseenter.passive="onMouseEnter" @mouseleave.passive="onMouseLeave" @touchstart="onMouseEnter" @touchend="onMouseLeave">
              <span>{{ $formatMoney(item.price, item.currency) }}</span>
              <icon :field="vehicleFields?.priceDisclaimer?.fields.icon" size="xs" />
            </div>
            <jss-rich-text class="fs-10 __c-grey-next" :field="vehicleFields.priceDesc" />
          </div>
        </div>
        <!--        <template-string class="s-svc-v2__delivery" :field="vehicleFields?.deliveryText" :data="{ time: '2 weeks' }" />-->
      </div>
      <div class="s-svc-v2__line" />
      <site-link :link="stockLink" class="s-svc-v2__configs">
        <template v-for="feature in item.features" :key="feature.label">
          <template v-for="info in feature.infos" :key="info.label">
            <label-value :label="info.label" v-if="/(config-(color|appearance|interior)|exterior-paint|interior-(theme|trim))$/gi.test(info.parentFeatureType)">{{ info.value }}</label-value>
          </template>
        </template>
        <template v-if="item.carModel?.specs?.length > 0">
          <template v-for="(spec, i) in item.carModel?.specs" :key="i">
            <label-value :label="spec.title" v-if="i <= 2">{{ spec.content }}</label-value>
          </template>
        </template>
      </site-link>
      <div class="s-svc-v2__line" />
      <site-link :link="stockLink" class="s-svc-v2__accessories">
        <template v-for="(accessory, index) in item.accessories" :key="accessory">
          <div v-if="(item.accessories.length > 4 && index < 3) || (item.accessories.length <= 4 && index <= 3)">
            <span :title="accessory.value">{{ accessory.value }}</span>
          </div>
        </template>
        <jss-rich-text v-if="item.accessories.length > 4" :field="vehicleFields.accessoryMoreText" />
      </site-link>
      <div class="s-svc-v2__store">
        <icon name="location" size="tiny" />
        <jss-rich-text :field="vehicleFields?.storeText" tag="span" />
        <span v-if="[1, 3].includes(item.organization)">&nbsp;<jss-rich-text :field="vehicleFields?.rccText" tag="span"/></span>
        <span class="fw-600 __c-black" v-else>&nbsp;{{ item.dealer }}</span>
        <template v-if="item.distance > 0">
          <span class="s-svc-v2__line-vertical" />
          <template-string :field="vehicleFields?.distanceText" :data="{ distance: $formatDistance(item.distance, 'm', 0) }" tag="span" />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-svc-v2 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &__swiper {
    overflow: hidden;
    position: relative;
    .swiper-slide {
      overflow: hidden;
    }
    &-img {
      aspect-ratio: 19/15;
      img {
        display: block;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__pagination {
    position: absolute;
    z-index: 1;
    left: 24px;
    bottom: 24px;
    width: calc(100% - 48px);
    display: flex;
    background-color: rgba($grey-89, 0.4);
    .swiper-pagination-bullet {
      flex-grow: 1;
      height: 1px;
      &-active {
        background-color: $white;
      }
    }
  }
  &__content {
    position: relative;
    border: 1px solid $grey-89;
    padding: 32px 24px 72px 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__disclaimer {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    padding: 24px;
    font-size: 12px;
    background-color: $grey-light;
    color: $grey-dark;
  }
  &__line {
    border-top: 1px solid $grey-89;
  }
  &__line-vertical {
    display: inline-block;
    height: 16px;
    border-left: 1px solid $grey-next;
    vertical-align: middle;
    margin-left: 8px;
    margin-right: 8px;
  }
  &__delivery {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 8px;
    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $green;
      vertical-align: middle;
    }
    > div {
      white-space: nowrap;
    }
  }
  &__configs {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    .e-label-value {
      padding: 0;
      &__label {
        color: $grey-next;
      }
      &__right {
        justify-content: flex-start;
      }
      &__value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__accessories {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    color: $grey-next;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    > div {
      width: calc(50% - 12px);
      display: flex;
      align-items: center;
      gap: 8px;
      &:before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $grey-next;
        flex-shrink: 0;
      }
      > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 12px);
      }
    }
  }
  &__store {
    position: absolute;
    bottom: 32px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $grey-next;
    .e-icon {
      display: inline-flex;
    }
  }
}
</style>
