<script setup>
import { computed, inject, ref } from 'vue';
import { toFistLetterUpper } from '@/utils/string-utils';

const props = defineProps({
  carSeries: {
    type: Object
  },
  carModel: {
    type: Object
  },
  downPayment: {
    type: Number
  },
  currency: {
    type: String
  },
  personalInfo: {
    type: Object
  },
  addressInfo: {
    type: Object
  },
  billingInfo: {
    type: Object
  },
  companyInfo: {
    type: Object
  },
  customerType: {
    type: Object
  }
});
const { fields } = inject('reservation');
const rootEl = ref();
const fData = computed(() => {
  return {
    series: toFistLetterUpper(props.carSeries?.series),
    model: toFistLetterUpper(props.carModel?.name),
    deliveryDate: props.carSeries?.expectedDeliveryDate
  };
});
</script>

<template>
  <div class="s-reservation-summary" ref="rootEl">
    <div class="s-reservation-summary__main">
      <background-image class="s-reservation-summary__image" :image="carSeries?.reservationImage" :is-background="false" v-if="!$deviceComputes.isMobileDevice.value" />
      <div class="s-reservation-summary__content">
        <div class="text-secondary mg-b-20" v-html="$formatString(fields.summaryTitle?.value, fData)" />
        <div class="mg-b-60">
          <simple-disclaimer :title="$formatString(fields.summaryDeliveryText?.value, fData)" :disclaimer="carSeries?.reservationDeliveryDisclaimer" />
        </div>
        <div class="text-desc mg-b-40" v-html="$formatString(fields.summaryBody?.value, fData)" />
        <div class="s-reservation-summary__part" v-if="!$isNullOrEmpty(carModel?.fullName)">
          <label>{{ $t('Powertrain') }}</label>
          <div>{{ carModel.fullName }}</div>
        </div>
        <!--        <template v-if="personalInfo && addressInfo && billingInfo">-->
        <!--          <div class="fs-18 mg-b-24" v-html="$formatString(fields.summaryDetailTitle?.value, fData)" />-->
        <!--          <div class="s-reservation-summary__masonry">-->
        <!--            <div class="s-reservation-summary__masonry-item" v-if="customerTypeVal === 'individual'">-->
        <!--              <div class="s-reservation-summary__kv">-->
        <!--                <label>{{ $t('Personal information').toUpperCase() }}</label>-->
        <!--                <div>-->
        <!--                  <p>{{ $concatString([personalInfo.firstName, personalInfo.lastName], ' ') }}</p>-->
        <!--                  <p>+{{ $concatString([personalInfo.mobile.area.code, personalInfo.mobile.number], ' ') }}</p>-->
        <!--                  <p>{{ $buildAddress(personalInfo.firstName, personalInfo.lastName, addressInfo.address, addressInfo.address2, addressInfo.zipCode, addressInfo.city, addressInfo.country.text) }}</p>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="s-reservation-summary__masonry-item" v-if="customerTypeVal === 'business'">-->
        <!--              <div class="s-reservation-summary__kv">-->
        <!--                <label>{{ $t('Business information').toUpperCase() }}</label>-->
        <!--                <div>-->
        <!--                  <p>{{ companyInfo?.vatNumber }}</p>-->
        <!--                  <p>{{ companyInfo?.companyRegistrationNumber }}</p>-->
        <!--                  <p>{{ companyInfo?.companyName }}</p>-->
        <!--                  <p>{{ $buildAddress(personalInfo.firstName, personalInfo.lastName, addressInfo.address, addressInfo.address2, addressInfo.zipCode, addressInfo.city, addressInfo.country.text) }}</p>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </template>-->
      </div>
    </div>
    <div class="s-reservation-summary__toolbar">
      <label-value :value="$formatMoney(downPayment, currency)">
        <template #label>
          <simple-disclaimer :title="$t('Reservation fee due')" :disclaimer="carSeries?.reservationFeeDisclaimer" />
        </template>
      </label-value>
    </div>
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-reservation-summary {
  height: 100%;
  &__main {
    min-height: calc(100vh - 57px);
    height: calc(100% - 57px);
    padding-bottom: 57px;
  }
  &__content {
    padding: 24px 24px 0 24px;
  }
  &__part {
    > label {
      display: block;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 500;
    }
    > div {
      color: $grey-next;
    }
  }
  &__kv {
    > label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: bold;
    }
    > div {
      color: $grey-next;
    }
  }
  &__toolbar {
    position: sticky;
    bottom: 0;
    height: 57px;
    border-top: 1px solid $grey-night;
    background: radial-gradient(130.47% 130.47% at 50.13% 130.47%, rgba(64, 64, 64, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    padding: 0 24px;
    .e-label-value {
      align-items: center;
      .s-simple-disclaimer {
        display: flex;
        align-items: center;
      }
      &__label {
        text-transform: none;
      }
    }
  }
  @include tablet-landscape {
    &__content {
      padding: 60px grid-width(2) 0 grid-width(2);
    }
    &__masonry {
      columns: 2;
      column-gap: 24px;
      &-item {
        break-inside: avoid;
      }
    }
    &__toolbar {
      padding: 0 grid-width(2);
    }
  }
}
</style>
