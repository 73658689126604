<script setup>
/**
 * @typedef UsedCarFilterMenuRef
 * @property {() => void } open
 * */
import { computed, getCurrentInstance, reactive, ref } from 'vue';
import { sitecoreProps } from '@/utils/constants';
import { isNullOrEmpty } from '@/utils/obj-utils';
import querystring from 'querystring';
import api from '@/api';
import { getGlobalConfigs } from '@/utils/site-utils';
import { getCurrencySymbol } from '@/utils/math-utils';
import { ifEmpty } from '@/utils/string-utils';

const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreProps
});
const emit = defineEmits(['updateFilter']);
const { proxy } = getCurrentInstance();
const { $jss } = proxy;
const modalRef = ref();
const filterData = ref(null);
const priceStart = ref(null);
const priceEnd = ref(null);
const yearErrorMsg = ref(null);
const mileageErrorMsg = ref(null);
const priceErrorMsg = ref(null);
const currency = ref('EUR');
const currencySymbol = ref('€');
const seriesProps = reactive({
  options: [],
  selectedOption: null,
  columnCountMobile: 2,
  columnCountTablet: 2,
  columnCountDesktop: 2
});
const yearStartProps = reactive({
  options: [],
  selectedOption: null,
  placeholder: '-',
  clearable: true
});
const yearEndProps = reactive({
  options: [],
  selectedOption: null,
  placeholder: '-',
  clearable: true
});
const mileageStartProps = reactive({
  options: [],
  selectedOption: null,
  placeholder: '-',
  clearable: true
});
const mileageEndProps = reactive({
  options: [],
  selectedOption: null,
  placeholder: '-',
  clearable: true
});
const colorProps = reactive({
  options: []
});
const transmissionProps = reactive({
  options: [],
  columnCountMobile: 2,
  columnCountTablet: 2,
  columnCountDesktop: 2
});
const approvedProps = reactive({
  overline: '',
  options: [],
  selectedOption: null,
  columnCountMobile: 2,
  columnCountTablet: 2,
  columnCountDesktop: 2
});
const titles = computed(() => {
  const val = props.fields?.filterTitles.value;
  if (isNullOrEmpty(val)) return {};
  return querystring.parse(val);
});
const getFilter = async () => {
  const [res] = await api.usedCar.filter();
  filterData.value = res;
};
const buildSeriesOptions = () => {
  const { seriesList } = filterData.value ?? {};
  if (!seriesList?.length) return;
  seriesProps.options = seriesList.map((x) => ({
    code: x,
    text: x
  }));
};
const buildYearOptions = () => {
  const now = new Date();
  const { yearStart, yearEnd } = props.fields || {};
  let startYear = Number(yearStart.value),
    endYear = Number(yearEnd.value);
  if (isNaN(endYear)) endYear = now.getFullYear();
  if (isNaN(startYear)) startYear = endYear;
  const yearOptions = [];
  for (let i = startYear; i <= endYear; i++) {
    yearOptions.push({
      code: i,
      text: i
    });
  }
  yearStartProps.options = yearOptions;
  yearEndProps.options = yearOptions;
};
const buildMileageOptions = () => {
  const { mileageStart, mileageEnd, mileageStep } = props.fields || {};
  let start = Number(mileageStart.value),
    end = Number(mileageEnd.value),
    step = Number(mileageStep.value);
  if (isNaN(start)) start = 0;
  if (isNaN(end)) end = 20000;
  if (isNaN(step)) step = 5000;
  const mileageStartOptions = [];
  const mileageEndOptions = [];
  for (let i = start; ; ) {
    if (i >= start) {
      mileageStartOptions.push({
        code: i,
        text: `${i} km`
      });
    }
    if (i > start) {
      mileageEndOptions.push({
        code: i,
        text: `${i} km`
      });
    }
    i += step;
    if (i >= end) {
      mileageEndOptions.push({
        code: i,
        text: `${i} km`
      });
      break;
    }
  }
  mileageStartProps.options = mileageStartOptions;
  mileageEndProps.options = mileageEndOptions;
};
const buildColorOptions = () => {
  const { colorList } = filterData.value ?? {};
  colorProps.options =
    colorList?.map((x) => ({
      id: x.id,
      text: x.color,
      image: x.imageUrl,
      checked: false
    })) ?? [];
};
const buildTransmissionProps = () => {
  if (!props.fields?.transmissionCheckList?.fields) return;
  const { checkItems } = props.fields?.transmissionCheckList?.fields;
  transmissionProps.options = checkItems.map((x) => ({
    code: x.fields.code.value,
    text: x.fields.text.value,
    checked: x.fields.checked.value
  }));
};
const buildApprovedProps = () => {
  if (!props.fields?.approvedRadioList?.fields) return;
  const { overline, checkItems, checkedItem } = props.fields?.approvedRadioList?.fields;
  const options = checkItems.map((x) => ({
    code: x.fields.code.value,
    text: x.fields.text.value
  }));
  const selectedOption = checkedItem ? options.find((x) => x.code === checkedItem.fields.code.value) : null;
  approvedProps.options = options;
  approvedProps.selectedOption = selectedOption;
  approvedProps.overline = overline?.value;
};
const checkYearRange = () => {
  const startYear = yearStartProps.selectedOption?.code,
    endYear = yearEndProps.selectedOption?.code;
  if (startYear && endYear && startYear > endYear) {
    yearErrorMsg.value = props.fields.yearRangeError.value;
  } else {
    yearErrorMsg.value = null;
  }
};
const checkMileageRange = () => {
  const startMileage = mileageStartProps.selectedOption?.code,
    endMileage = mileageEndProps.selectedOption?.code;
  if (startMileage && endMileage && startMileage > endMileage) {
    mileageErrorMsg.value = props.fields.mileageRangeError.value;
  } else {
    mileageErrorMsg.value = null;
  }
};
const checkPriceRange = () => {
  if (priceStart.value && priceEnd.value && priceStart.value > priceEnd.value) {
    priceErrorMsg.value = props.fields.priceRangeError.value;
  } else {
    priceErrorMsg.value = null;
  }
};
const onYearStartChange = (e, op) => {
  yearStartProps.selectedOption = op;
  checkYearRange();
};
const onYearEndChange = (e, op) => {
  yearEndProps.selectedOption = op;
  checkYearRange();
};
const onMileageStartChange = (e, op) => {
  mileageStartProps.selectedOption = op;
  checkMileageRange();
};
const onMileageEndChange = (e, op) => {
  mileageEndProps.selectedOption = op;
  checkMileageRange();
};
const onPriceStartBlur = (e) => {
  priceStart.value = e.target.value;
  checkPriceRange();
};
const onPriceEndBlur = (e) => {
  priceEnd.value = e.target.value;
  checkPriceRange();
};
const onColorChange = (e, op) => {
  op.checked = !op.checked;
};
const onApprovedChange = (e, i, op) => {
  if (approvedProps.selectedOption?.code === op?.code) {
    approvedProps.selectedOption = null;
  } else {
    approvedProps.selectedOption = op;
  }
};
const onReset = () => {
  modalRef.value?.close();
  emit('updateFilter', null);
};
const onSubmit = () => {
  modalRef.value?.close();
  console.log('transmission', transmissionProps.options);
  const body = {
    series: seriesProps.options.filter((x) => x.checked).map((x) => x.code),
    yearStart: yearStartProps.selectedOption?.code,
    yearEnd: yearEndProps.selectedOption?.code,
    mileageStart: mileageStartProps.selectedOption?.code,
    mileageEnd: mileageEndProps.selectedOption?.code,
    priceStart: priceStart.value,
    priceEnd: priceEnd.value,
    colors: colorProps.options.filter((x) => x.checked).map((x) => x.id),
    transmission: transmissionProps.options.filter((x) => x.checked).map((x) => x.code),
    approved: approvedProps.selectedOption?.code
  };
  emit('updateFilter', body);
};
const init = async () => {
  await getFilter();
  const page = $jss.routeData();
  const [currencyField] = getGlobalConfigs(page, 'currency');
  currency.value = ifEmpty(currencyField?.value, filterData.value?.currencyList?.length > 0 ? filterData.value?.currencyList[0] : 'EUR', 'EUR');
  currencySymbol.value = getCurrencySymbol(currency.value);
  buildSeriesOptions();
  buildYearOptions();
  buildMileageOptions();
  buildColorOptions();
  buildTransmissionProps();
  buildApprovedProps();
  return {
    currency: currency.value,
    series: seriesProps.options.filter((x) => x.checked).map((x) => x.code),
    yearStart: yearStartProps.value,
    yearEnd: yearEndProps.value,
    mileageStart: mileageStartProps.selectedOption?.code,
    mileageEnd: mileageEndProps.selectedOption?.code,
    priceStart: priceStart.value,
    priceEnd: priceEnd.value,
    colors: colorProps.options.filter((x) => x.checked).map((x) => x.id),
    transmission: transmissionProps.options.filter((x) => x.checked).map((x) => x.code),
    approved: approvedProps.selectedOption?.code
  };
};
const open = (filter) => {
  if (filter === null) {
    seriesProps.options.forEach((x) => (x.checked = false));
    yearStartProps.selectedOption = null;
    yearEndProps.selectedOption = null;
    mileageStartProps.selectedOption = null;
    mileageEndProps.selectedOption = null;
    colorProps.options.forEach((x) => (x.checked = false));
  }
  modalRef.value?.open();
};
defineExpose({ init, open });
</script>

<template>
  <modal class="s-uc-fm" animation="left-slide-in" closable ref="modalRef">
    <div class="s-uc-fm__line" />
    <div class="s-uc-fm__content">
      <div class="s-uc-fm__sections">
        <filter-menu-section :title="titles.series" v-if="seriesProps.options.length > 0">
          <check-list class="s-uc-fm__series" v-bind="seriesProps" @change="(e, i, checked) => (seriesProps.options[i].checked = checked)" />
        </filter-menu-section>
        <filter-menu-section :title="titles.year" v-if="yearStartProps.options.length > 0">
          <div class="s-uc-fm__range">
            <jss-text :field="fields.fromText" tag="span" />
            <dropdown theme="search-grey" v-bind="yearStartProps" @change="onYearStartChange" />
            <jss-text :field="fields.toText" tag="span" />
            <dropdown theme="search-grey" v-bind="yearEndProps" @change="onYearEndChange" />
          </div>
          <div class="s-uc-fm__error" v-if="yearErrorMsg">
            <span v-html="yearErrorMsg" />
            <icon name="important" size="tiny" />
          </div>
        </filter-menu-section>
        <filter-menu-section :title="titles.mileage" v-if="mileageStartProps.options.length > 0">
          <div class="s-uc-fm__range">
            <jss-text :field="fields.fromText" tag="span" />
            <dropdown theme="search-grey" v-bind="mileageStartProps" @change="onMileageStartChange" />
            <jss-text :field="fields.toText" tag="span" />
            <dropdown theme="search-grey" v-bind="mileageEndProps" @change="onMileageEndChange" />
          </div>
          <div class="s-uc-fm__error" v-if="mileageErrorMsg">
            <span v-html="mileageErrorMsg" />
            <icon name="important" size="tiny" />
          </div>
        </filter-menu-section>
        <filter-menu-section :title="titles.price">
          <jss-rich-text class="mg-b-16" :field="fields.priceLabel" tag="div" />
          <div class="s-uc-fm__range">
            <jss-text :field="fields.fromText" tag="span" />
            <div class="s-uc-fm__input">
              <span>{{ currencySymbol }}</span>
              <input v-model="priceStart" type="number" :max="priceEnd" @blur="onPriceStartBlur" />
            </div>
            <jss-text :field="fields.toText" tag="span" />
            <div class="s-uc-fm__input">
              <span>{{ currencySymbol }}</span>
              <input v-model="priceEnd" type="number" :min="priceStart" @blur="onPriceEndBlur" />
            </div>
          </div>
          <div class="s-uc-fm__error" v-if="priceErrorMsg">
            <span v-html="priceErrorMsg" />
            <icon name="important" size="tiny" />
          </div>
        </filter-menu-section>
        <filter-menu-section :title="titles.exteriorColor" v-if="colorProps.options.length > 0">
          <div class="s-uc-fm__colors">
            <div class="s-uc-fm__color" v-for="op in colorProps.options" :key="op.id">
              <div class="s-uc-fm__color-label">
                <div class="s-uc-fm__color-img" v-if="!$isNullOrEmpty(op.image)">
                  <img :src="op.image" :alt="op.text" />
                </div>
                <span class="s-uc-fm__color-text">{{ op.text }}</span>
              </div>
              <checkbox :value="op.checked" @change="onColorChange($event, op)" />
            </div>
          </div>
        </filter-menu-section>
        <filter-menu-section :title="titles.transmission" v-if="transmissionProps.options.length > 0">
          <check-list v-bind="transmissionProps" @change="(e, i, checked) => (transmissionProps.options[i].checked = checked)" />
        </filter-menu-section>
        <filter-menu-section :title="titles.lotusApproved" v-if="approvedProps.options.length > 0">
          <radio-list v-bind="approvedProps" free @change="onApprovedChange" />
        </filter-menu-section>
      </div>
    </div>
    <div class="s-uc-fm__toolbar">
      <menu-button v-bind="fields.cancelButton" @click="onReset" />
      <menu-button v-bind="fields.submitButton" :disabled="yearErrorMsg || mileageErrorMsg || priceErrorMsg" @click="onSubmit" />
    </div>
  </modal>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-uc-fm {
  $c: &;
  $m: '.e-modal';
  &.e-modal {
    justify-content: flex-start;
    #{$m}__close {
      top: 32px;
      right: 28px;
    }
    #{$m}__content {
      height: 100vh;
      width: 100%;
      padding-top: 96px;
    }
  }
  .s-uc-fm-sec {
    &__content {
      position: relative;
    }
  }
  .e-form-input {
    &__main-input {
      height: 40px;
    }
  }
  .e-form-input {
    &__messages {
      display: none;
    }
  }
  .e-check-list {
    padding-bottom: 0;
    &__items {
      column-gap: 40px;
    }
    &__item {
      .e-form-checkbox {
        &__check {
          order: 2;
        }
        &__label {
          order: 1;
        }
      }
    }
    &__error-box {
      height: 32px;
    }
  }
  .e-radio-list {
    &__items {
      column-gap: 40px;
    }
    &__item {
      .e-site-radio {
        order: 2;
        flex-shrink: 0;
      }
      &-label {
        order: 1;
        flex-grow: 1;
      }
      &.checked {
        .e-radio-list__item-label {
          color: $black;
        }
      }
    }
    &__error-box {
      height: 32px;
    }
  }
  .e-form-checkbox {
    &.checked {
      .e-form-checkbox__label {
        color: $black;
      }
    }
  }
  .s-uc-fm-sec {
    + .s-uc-fm-sec {
      border-top: 1px solid $grey-89;
    }
  }
  &__series {
    .e-check-list__item {
      .e-form-checkbox__label {
        text-transform: uppercase;
      }
    }
  }
  &__line {
    margin: 0 32px;
    border-top: 1px solid $grey-89;
  }
  &__content {
    position: relative;
    padding: 0 32px;
    overflow-y: auto;
    height: calc(100% - 120px);
    &:before {
      content: '';
      position: absolute;
    }
  }
  &__range {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding-bottom: 32px;
    .e-dropdown {
      flex-grow: 1;
      &__placeholder {
        width: calc(100% - 57.5px);
        text-align: center;
      }
      &__text {
        position: absolute;
        width: calc(100% - 57.5px);
        text-align: center;
      }
    }
  }
  &__input {
    height: 40px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 12px;
    border: 1px solid currentColor;
    > input {
      border: none;
      width: 100%;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }
  &__error {
    position: absolute;
    right: 0;
    bottom: 8px;
    font-size: 12px;
    color: $red;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }
  &__colors {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
    column-gap: 40px;
    padding-bottom: 32px;
  }
  &__color {
    display: flex;
    justify-content: space-between;
    &-label {
      display: flex;
      gap: 16px;
      line-height: 32px;
    }
    &-text {
      text-transform: capitalize;
    }
    &-img {
      flex-shrink: 0;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .e-form-checkbox {
      align-self: center;
    }
  }
  &__toolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 32px;
    display: flex;
    justify-content: center;
    gap: 8px;
    background: $black;
    .e-menu-button {
      width: calc((100% - 8px) / 2);
      height: 56px;
      font-size: 14px;
      font-weight: 700;
      padding: 12px;
    }
  }
  @include tablet-landscape {
    &.e-modal {
      #{$m}__content {
        width: grid-width(8);
        min-width: 375px;
        max-width: 595px;
      }
    }
  }
}
</style>
