<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue';
import { transitions } from '@/utils/transitions';
import { debounce, merge } from 'lodash';
import { CAPTCHA_SCENE, captchaTypes } from '@/utils/constants';
import { GET_CAPTCHA_SCENE, getCaptchaExecutor } from '@/utils/captcha-utils';
import { getSiteError } from '@/services/siteService';

const props = defineProps({
  email: {
    type: String
  }
});
const emit = defineEmits(['update:email']);
/**@type AppStore*/
const appStore = inject('appStore');
const loading = inject('loading');
const toast = inject('toast');
const { fields, methods, prevStep, validateGeeTest } = inject('reservation');
const accountFields = computed(() => fields.accountStep?.fields);
const appMethods = inject('appMethods');
let codeTimeout, getCaptcha, checkCaptcha;
const accountFormRef = ref();
const captchaInputRef = ref();
const captcha = ref('');
const captchaHasSent = ref(false);
const resendDisabled = ref(true);
const seconds = ref(0);
const onAccountFormChange = (name, value) => {
  if (name === 'email') {
    emit('update:email', value);
  }
};
const onPrevClick = () => {
  if (captchaHasSent.value) {
    captcha.value = '';
    captchaHasSent.value = false;
    seconds.value = 0;
  } else {
    methods.onPrev();
  }
};
const onNextClick = async () => {
  if (!captchaHasSent.value) {
    await onSendCode();
  } else {
    await onVerify();
  }
};
const startCodeCountdown = (_seconds) => {
  resendDisabled.value = true;
  const fn = (s) => {
    seconds.value = s;
    if (s > 0) {
      codeTimeout = setTimeout(fn, 1000, --s);
    } else {
      resendDisabled.value = false;
    }
  };
  fn(_seconds);
};
const onSendCode = debounce(async () => {
  const [valid, data] = await accountFormRef.value.validate();
  if (valid) {
    const { email } = data;
    loading.show();
    let geeResult = await validateGeeTest();
    const body = merge(
      {
        captchaType: captchaTypes.email,
        captchaScene: CAPTCHA_SCENE.registerLogin,
        email
      },
      geeResult
    );
    let res, ex;
    if (props.bindRequired) {
      merge(body, {
        captchaScene: CAPTCHA_SCENE.bindEmail
      });
    }
    [res, ex] = await getCaptcha(null, body);
    if (ex) {
      await toast.showEx(ex);
      loading.hide();
      return;
    }
    emit('update:email', email);
    captchaHasSent.value = true;
    startCodeCountdown(60);
    loading.hide();
  }
}, 50);
const onResend = debounce(async () => {
  await onSendCode();
}, 50);
const onVerify = debounce(async () => {
  loading.show();
  const body = {
    captchaType: captchaTypes.email,
    captcha: captcha.value,
    email: props.email
  };
  const [, ex] = await checkCaptcha(null, body);
  if (ex) {
    const msg = await getSiteError(ex);
    console.log('validate error', msg);
    captchaInputRef.value.setErrorMsg(accountFields.value.captchaInput?.fields?.errorMsg?.value ?? 'Verification failed');
    loading.hide();
    return;
  }
  if (codeTimeout) {
    clearTimeout(codeTimeout);
  }
  loading.hide();
  captchaInputRef.value.setSuccessMsg(accountFields.value.captchaInput?.fields?.successMsg?.value ?? 'Verification success');
  setTimeout(() => {
    methods.onNext();
  }, 1000);
}, 50);
const resetEmail = () => {
  captchaHasSent.value = false;
  captcha.value = '';
};
watch(
  () => captcha.value,
  (_captcha) => {
    if (_captcha?.length === 6) {
      onVerify();
    }
  }
);
onMounted(() => {
  [getCaptcha, checkCaptcha] = getCaptchaExecutor(GET_CAPTCHA_SCENE.RESERVATION, false);
});
defineExpose({ resetEmail });
</script>

<template>
  <div class="s-reservation-account-verify">
    <div class="s-reservation-account-verify__content">
      <div class="s-reservation-account-verify__main" v-show="!captchaHasSent">
        <jss-rich-text class="s-reservation-account-verify__title text-primary" :field="accountFields.title" />
        <jss-rich-text class="s-reservation-account-verify__subtitle text-desc" :field="accountFields.body" />
        <dynamic-form :form="accountFields.accountForm" ref="accountFormRef" @change="onAccountFormChange" />
        <site-button v-bind="fields.sendButton" :disabled="$isNullOrEmpty(email)" @click="onSendCode" />
      </div>
      <div class="s-reservation-account-verify__captcha" v-show="captchaHasSent">
        <jss-rich-text class="s-reservation-account-verify__title text-primary" :field="accountFields.verifyTitle" />
        <div class="mg-b-24 text-desc" v-html="$formatString(accountFields.verifySubtitle?.value, { email, seconds })" />
        <captcha-input class="mg-b-24" v-model:value="captcha" :label="accountFields.captchaInput?.fields?.label?.value" ref="captchaInputRef" />
        <transition :css="false" @before-enter="transitions.accordion.beforeEnter" @enter="transitions.accordion.enter" @leave="transitions.accordion.leave">
          <div v-show="captchaHasSent && seconds === 0">
            <a class="s-reservation-account-verify__resend ib label-primary mg-b-32" :class="[{ disabled: resendDisabled }]" @click="onResend">{{ accountFields.resendText?.value }}</a>
          </div>
        </transition>
        <site-button v-bind="fields.verifyButton" :disabled="captcha?.length !== 6" @click="onVerify" />
      </div>
    </div>
    <reservation-toolbar :prev-button="prevStep !== null ? fields.prevButton : null" @prev-click="onPrevClick" />
  </div>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-reservation-account-verify {
  &__content {
    min-height: calc(100vh - #{nth($header-height, 1)} - 104px);
  }
  &__main,
  &__captcha {
    > .e-site-button {
      width: 100%;
    }
  }
  .e-site-button {
    width: 100%;
    &__text {
      font-weight: bold;
    }
  }
  @include tablet-landscape {
    &__content {
      padding-bottom: 100px;
      min-height: unset;
    }
  }
}
</style>
