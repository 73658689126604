<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import Swiper from 'swiper';
import { destroySwiper } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';
const props = defineProps({
  detail: {
    type: Object
  }
});
let swiper, swiperThumb;
const modalRef = ref();
const contentEl = ref();
const swiperEl = ref();
const swiperThumbEl = ref();
const leftEl = ref();
const rightEl = ref();
const paginationEl = ref();
const thumbPaginationEl = ref();
const open = async (index = 0) => {
  await modalRef.value.open();
  contentEl.value.style.opacity = 0;
  swiperThumb = new Swiper(swiperThumbEl.value, {
    slidesPerView: 'auto',
    spaceBetween: 16,
    centeredSlides: true,
    pagination: {
      el: thumbPaginationEl.value,
      dynamicBullets: true
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true
    }
  });
  swiper = new Swiper(swiperEl.value, {
    slidesPerView: 1,
    spaceBetween: 16,
    initialSlide: index ?? 0,
    navigation: {
      prevEl: leftEl.value,
      nextEl: rightEl.value
    },
    pagination: {
      el: paginationEl.value,
      type: 'fraction'
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true
    },
    thumbs: {
      swiper: swiperThumb
    },
    on: {
      slideChange(_this) {
        swiperThumb.slideTo(_this.activeIndex);
      }
    }
  });
  contentEl.value.style.opacity = 1;
};
const onClose = async () => {
  destroySwiper(swiper);
  destroySwiper(swiperThumb);
};
const onKeyDown = async (e) => {
  if (['ArrowLeft', 'a'].some((x) => equalString(x, e.key))) {
    swiper?.slidePrev(665);
  }
  if (['ArrowRight', 'd'].some((x) => equalString(x, e.key))) {
    swiper?.slideNext(665);
  }
};
onMounted(() => {
  window.addEventListener('keydown', onKeyDown, { passive: true });
});
onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyDown);
});
defineExpose({ open });
</script>

<template>
  <modal class="s-uc-image-modal" animation="center-scale-fade-in" @close="onClose" closable ref="modalRef">
    <div class="s-uc-image-modal__content" ref="contentEl">
      <div class="s-uc-image-modal__main swiper" ref="swiperEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(pic, index) in detail.picList" :key="index">
            <div class="s-uc-image-modal__img">
              <img :src="pic.url" alt="" />
            </div>
          </div>
        </div>
        <div class="s-uc-image-modal__nav left" ref="leftEl">
          <icon name="left" />
        </div>
        <div class="s-uc-image-modal__nav right" ref="rightEl">
          <icon name="right" />
        </div>
      </div>
      <div class="s-uc-image-modal__pagination" ref="paginationEl" />
      <div class="s-uc-image-modal__thumb swiper" ref="swiperThumbEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(pic, index) in detail.picList" :key="index">
            <div class="s-uc-image-modal__img">
              <img :src="pic.url" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="s-uc-image-modal__thumb-pagination" ref="thumbPaginationEl" />
    </div>
  </modal>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-uc-image-modal {
  $c: &;
  $m: '.e-modal';
  user-select: none;
  &.e-modal {
    #{$m}__close {
      top: 24px;
      right: 24px;
    }
    #{$m}__content {
      width: 100%;
      height: 100%;
      background: $black;
      color: $white;
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 120px 0 96px 0;
    opacity: 0;
  }
  &__main {
    width: 100%;
    .swiper-slide {
      display: flex;
      justify-content: center;
    }
  }
  &__img {
    height: 100%;
    aspect-ratio: 3/2;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  &__thumb {
    position: absolute;
    bottom: 32px;
    width: 100%;
    &-pagination {
      position: absolute;
      bottom: 16px;
      width: 100% !important;
      height: 1px;
      background: $grey-dark;
      display: flex;
      align-items: stretch;
      .swiper-pagination-bullet {
        flex-grow: 1;
        &-active {
          background-color: $white;
        }
      }
    }
    .swiper-slide {
      width: 80px;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($black, 0.6);
        opacity: 1;
        transition: all 0.2s;
      }
      &-thumb-active {
        &:before {
          opacity: 0;
        }
      }
    }
    #{$c}__img {
      img {
        object-fit: cover;
      }
    }
  }
  &__nav {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid $yellow;
    cursor: pointer;
    padding: 8px;
    display: none;
    &:hover {
      color: $yellow;
    }
    &.swiper-button-disabled {
      display: none;
    }
    &.left {
      left: 24px;
    }
    &.right {
      right: 24px;
    }
  }
  &__pagination {
    text-align: center;
    padding: 24px 0;
  }
  @include tablet-landscape {
    &__content {
      padding-bottom: 148px;
    }
    &__thumb {
      bottom: 78px;
      &-pagination {
        bottom: 60px;
      }
    }
    &__nav {
      display: block;
      &.left {
        left: 80px;
      }
      &.right {
        right: 80px;
      }
    }
  }
}
</style>
