<template>
  <div class="s-merchandise-return-item" v-if="fields" v-show="step === 0">
    <JssRichText class="s-merchandise-return-item__title" :field="fields.title" />
    <JssRichText class="s-merchandise-return-item__description" :field="fields.description" />
    <div class="s-merchandise-return-item__cart">
      <div class="s-merchandise-return-item__cart__item" v-for="item in returnItems" :key="item.id">
        <div class="s-merchandise-return-item__cart__item__checkbox">
          <Checkbox v-model:value="item.checked" @change="onChangeChecked" />
        </div>
        <div class="s-merchandise-return-item__cart__item__image">
          <img :src="item.image" :alt="item.name" />
        </div>
        <div class="s-merchandise-return-item__cart__item__content">
          <div class="s-merchandise-return-item__cart__item__content__top">
            <div class="s-merchandise-return-item__cart__item__content__name">{{ item.name }}</div>
            <div class="s-merchandise-return-item__cart__item__content__attr">{{ item.spec }}</div>
            <div class="s-merchandise-return-item__cart__item__content__qty">
              <div class="s-merchandise-return-item__cart__item__content__qty__label" v-html="`${$t('Qty')}:`" v-if="item.allowAfterSalesNum == 1" />
              <div class="s-merchandise-return-item__cart__item__content__qty__value" v-if="item.allowAfterSalesNum == 1">{{ item.allowAfterSalesNum }}</div>
              <num-stepper v-else v-model:value="item.num" :max="item.allowAfterSalesNum" :disabled="!item.disabled" @change="(number) => onQuantityChange(item, number)" />
            </div>
          </div>
          <div class="s-merchandise-return-item__cart__item__content__price">{{ $formatShopMoney(item.price, currency) }}</div>
        </div>
      </div>
    </div>
    <div class="s-merchandise-return-item__selectall">
      <Checkbox :value="returnItems?.every((x) => x.checked)" @change="onCheckAllChange" />
      <JssRichText class="s-merchandise-return-item__selectall__label" :field="selectAllText" />
    </div>
    <SiteButton v-bind="fields.button" :disabled="returnItems?.every((x) => !x.checked)" @click="goNext" />
  </div>
</template>
<script>
import { reactive, onMounted, toRefs } from 'vue';
import { getGlobalCfgValues } from '@/utils/site-utils';
export default {
  name: 'MerchandiseReturnItem',
  props: {
    step: {
      type: Number
    },
    fields: {
      type: Object
    },
    returnItems: {
      type: Object
    },
    selectAllText: {
      type: Object
    },
    page: {
      type: Object
    },
    currency: {
      type: String
    }
  },
  setup(props, context) {
    const state = reactive({
      currency: 'EUR'
    });
    const methods = {
      onQuantityChange(item, num) {
        let total = 0;
        let returnItems = [];
        props.returnItems?.forEach((item) => {
          if (item.checked) {
            total += item.price * item.num;
            returnItems.push(item);
          }
        });
        let obj = {
          total: total,
          returnItems: returnItems
        };
        context.emit('updateDate', obj);
      },
      onCheckAllChange(e, checked) {
        for (let item of props.returnItems) {
          item.checked = checked;
        }
        let returnItems = [];
        let total = 0;
        props.returnItems?.forEach((item) => {
          if (item.checked) {
            total += item.price * item.num;
            returnItems.push(item);
          }
        });
        let obj = {
          total: total,
          returnItems: returnItems
        };
        context.emit('updateDate', obj);
      },
      onChangeChecked(e, checked) {
        let total = 0;
        let returnItems = [];
        props.returnItems?.forEach((item) => {
          if (item.checked) {
            total += item.price * item.num;
            returnItems.push(item);
          }
        });
        let obj = {
          total: total,
          returnItems: returnItems
        };
        context.emit('updateDate', obj);
      },
      goNext() {
        let step = props.step + 1;
        let obj = {
          step: step
        };
        context.emit('updateStep', obj);
      }
    };
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-return-item {
  &__description {
    margin-top: 32px;
  }
  .e-site-button {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 100px;
  }
  &__cart {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 40px;
    border-bottom: 1px solid #e3e3e3;
    &__item {
      display: flex;
      gap: 24px;
      &__checkbox {
        height: 134px;
        display: flex;
        align-items: center;
      }
      &__image {
        width: 123px;
        height: 134px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f2f1f0;
        img {
          width: 90px;
          height: 90px;
          object-fit: cover;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__top {
          display: flex;
          flex-direction: column;
        }
        &__name {
          font-size: 12px;
          line-height: 20px;
        }
        &__attr {
          font-size: 12px;
          line-height: 20px;
          color: #757575;
          margin-top: 10px;
        }
        &__qty {
          font-size: 12px;
          line-height: 20px;
          color: #757575;
          display: flex;
          align-items: center;
          margin-top: 5px;
          gap: 3px;
          .e-icon {
            display: flex;
            align-items: center;
          }
        }
        &__price {
          font-size: 12px;
          line-height: 20px;
          color: #757575;
        }
      }
    }
  }
  &__selectall {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
    &__label {
      font-size: 16px;
      line-height: 1;
      color: #757575;
    }
  }
  @include tablet-landscape {
    .e-site-button {
      margin-top: 64px;
      margin-bottom: 0;
    }
    &__cart {
      margin-top: 44px;
    }
  }
}
</style>
