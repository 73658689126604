<template>
  <div class="s-overall-settings" :id="rendering?.dataSource" :class="rootClasses" v-if="fields" v-show="$appVisible($route, fields)" ref="rootEl">
    <slot />
  </div>
</template>

<script>
import { computed, inject, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { overallSettingClasses, settingValue } from '@/utils/site-utils';
import BUS_EVENTS from '@/utils/bus-events';
import { sitecoreProps } from '@/utils/constants';

export default {
  name: 'OverallSettings',
  props: {
    /**@type OverallSettingFields*/
    fields: {
      type: Object
    },
    page: {
      type: Object
    },
    rendering: {
      type: Object
    },
    ...sitecoreProps
  },
  setup(props) {
    if (!props.fields) return;
    const $bus = inject('$bus');
    const state = reactive({
      rootEl: null
    });
    const computes = {
      rootClasses: computed(() => [...overallSettingClasses(props)])
    };
    const methods = {
      onScroll() {
        if (!state.rootEl) return;
        const rect = state.rootEl.getBoundingClientRect();
        if (rect.top <= 0 && rect.bottom > 0) {
          $bus.emit(BUS_EVENTS.UPDATE_HEADER_THEME, settingValue(props.fields.headerTheme, null));
        }
      }
    };
    onMounted(() => {
      window.addEventListener('scroll', methods.onScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', methods.onScroll);
    });
    return {
      ...toRefs(state),
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-overall-settings {
  @include component-overall-settings;
  background-color: $white;
  position: relative;
  z-index: 1;
}
</style>
