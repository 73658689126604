<script setup>
/**
 * @typedef SaveVehicleConfigModalRef
 * @property {(vehicleConfig: VehicleConfiguration) => void} openEdit
 * @property{(configCode: string) => void} open
 * */
/**
 * @typedef VehicleConfiguration
 * @property {string} configurationCode
 * */
import { getCurrentInstance, inject, onMounted, reactive } from 'vue';
import { sitecoreProps } from '@/utils/constants';
import api from '@/api';
import { appendQuery } from '@/utils/uri-utils';
import utils from '@/utils';
import { isNullOrEmpty } from '@/utils/obj-utils';

const emit = defineEmits(['saved']);
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreProps
});
const toast = inject('toast');
const { proxy } = getCurrentInstance();
const { $jss } = proxy;
const state = reactive({
  /**@type DynamicForm*/
  formRef: null,
  /**@type ModalRef*/
  modalRef: null,
  mode: 'new',
  item: null,
  page: null,
  configName: ''
});
const open = (newItem) => {
  openModal('new', newItem);
};
const openEdit = (editItem = null) => {
  openModal('edit', editItem);
};
const openModal = async (mode, item) => {
  state.item = item;
  state.mode = mode;
  await state.modalRef.open();
  state.formRef.updateItem('name', {
    controlProps: {
      value: item.configurationName
    }
  });
};
const onSave = async () => {
  if (!isNullOrEmpty(state.configName)) {
    const [, ex] = await api.vehicleConfig.edit(null, {
      id: state.item.id,
      configurationName: state.configName
    });
    if (ex) {
      await toast.showEx(ex);
      return;
    }
    state.item.configurationName = state.configName;
  }
  await state.modalRef.close();
  emit('saved', state.mode, state.item);
};
const onView = () => {
  state.modalRef.close();
  // window.location.href = utils.mhp.getMhpConfigUrlByCode(state.page, state.item.configurationCode);
};
const onFormChange = (key, value) => {
  if (key === 'name') {
    state.configName = value;
  }
};
onMounted(() => {
  state.page = $jss.routeData();
});
defineExpose({ open, openEdit });
</script>

<template>
  <modal class="s-save-vc-modal" :ref="(e) => (state.modalRef = e)" @close-click="onView" closable>
    <div class="s-save-vc-modal__content">
      <jss-rich-text :field="fields[state.mode === 'edit' ? 'editTitle' : 'newTitle']" />
      <jss-rich-text :field="fields[state.mode === 'edit' ? 'editBody' : 'newBody']" />
      <dynamic-form :form="fields[state.mode === 'edit' ? 'editForm' : 'newForm']" @change="onFormChange" :ref="(e) => (state.formRef = e)" />
      <div class="s-save-vc-modal__buttons">
        <site-button v-bind="fields.saveButton" @click="onSave" v-if="state.mode === 'edit'" />
        <template v-else>
          <site-button v-bind="fields.newButton" @click="onSave" />
          <site-button v-bind="fields.viewButton" @click="onView" />
        </template>
      </div>
    </div>
  </modal>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-save-vc-modal {
  $c: &;
  $m: '.e-modal';
  &#{$m} {
    #{$m}__content {
      width: grid-width-m(10);
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 64px 32px 32px;
    .s-dynamic-form {
      padding: 0;
      .e-form-input__messages {
        display: none;
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    .e-site-button {
      &.primary-button {
        width: 100%;
      }
    }
  }
  @include tablet-landscape {
    &#{$m} {
      #{$m}__content {
        width: grid-width(10);
      }
    }
  }
}
</style>
