<template>
  <div class="s-merchandise-return-reason" v-if="fields" v-show="step === 1">
    <JssRichText class="s-merchandise-return-reason__title" :field="fields.title" />
    <JssRichText class="s-merchandise-return-reason__description" :field="fields.description" />
    <dynamic-form :form="fields.form" :scrollToForm="false" ref="formRef" />
    <SiteButton v-bind="fields.button" @click="goNext" />
  </div>
</template>
<script>
import { reactive, onMounted, toRefs, computed } from 'vue';
export default {
  name: 'MerchandiseReturnReason',
  props: {
    step: {
      type: Number
    },
    fields: {
      type: Object
    }
  },
  setup(props, context) {
    const state = reactive({
      formRef: null
    });
    const methods = {
      async goNext() {
        const [valid, formData] = await state.formRef.validate();
        if (valid) {
          let step = props.step + 1;
          let obj = {
            step: step,
            formData: formData
          };
          context.emit('updateStep', obj);
        }
      }
    };
    onMounted(() => {
      // console.log('================');
      // console.log(props.form);
      // console.log(props.fields);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-return-reason {
  &__description {
    margin-top: 48px;
  }
  .e-site-button {
    width: 100%;
    margin-bottom: 100px;
  }
  @include tablet-landscape {
    &__description {
      margin-top: 32px;
    }
    .e-site-button {
      margin-bottom: 0;
    }
  }
}
</style>
