<template>
  <teleport to="body">
    <div class="s-stock-vehicle-splash-screen" v-if="visible" ref="rootEl">
      <adaptive-image class="s-stock-vehicle-splash-screen__image" :field="fields.image" />
      <div class="s-stock-vehicle-splash-screen__content">
        <jss-rich-text class="s-stock-vehicle-splash-screen__title" :field="fields.title" />
        <jss-rich-text class="s-stock-vehicle-splash-screen__body" :field="fields.body" />
      </div>
    </div>
  </teleport>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { sitecoreProps } from '@/utils/constants';
import gsap from 'gsap';

export default {
  name: 'stock-vehicle-splash-screen',
  emits: ['hide'],
  props: {
    fields: {
      type: Object
    },
    ...sitecoreProps
  },
  setup(props, ctx) {
    let timeout = null;
    const state = reactive({
      rootEl: null,
      visible: true
    });
    onMounted(async () => {
      const $title = state.rootEl.querySelector('.s-stock-vehicle-splash-screen__title');
      const $body = state.rootEl.querySelector('.s-stock-vehicle-splash-screen__body');
      const tl = gsap.timeline({ paused: true });
      tl.to(
        $title,
        {
          transform: 'translate(0, -100px)',
          opacity: 0,
          duration: 1
        },
        0
      );
      tl.to(
        $body,
        {
          transform: 'translate(0, -100px)',
          opacity: 0,
          duration: 1
        },
        0
      );
      tl.to(
        state.rootEl,
        {
          yPercent: 5,
          opacity: 0,
          duration: 0.6,
          onComplete() {
            state.visible = false;
            ctx.emit('hide');
          }
        },
        1
      );
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        tl.play();
      }, 5000);
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-stock-vehicle-splash-screen {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100vw;
  @include height-except-header;
  color: $white;
  &__image {
    width: 100%;
    height: 100%;
    background: $black;
  }
  &__content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-100px);
  }
  &__title {
    margin-bottom: 12px;
  }
  &__body {
    font-size: 16px;
  }
  @include desktop {
    &__content {
      padding: 108px;
      transform: translateY(0);
    }
    &__body {
      font-size: 18px;
    }
  }
}
</style>
