<template>
  <div class="s-personal-center" :class="[{ collapsed }]" v-if="fields">
    <div class="s-personal-center__side">
      <div class="s-personal-center__side-main">
        <div class="s-personal-center__side-main-header">
          <div
            class="s-personal-center__welcome"
            v-if="appStore?.hasLoggedIn && (['mobile', 'tablet'].includes(deviceState.deviceType) || !collapsed)"
            v-html="
              $formatString(fields?.welcomeText.value, {
                name: appStore?.loginInfo?.firstName && appStore?.loginInfo?.lastName ? `${appStore?.loginInfo?.firstName} ${appStore?.loginInfo?.lastName}` : appStore?.loginInfo?.nickName
              })
            "
          />
          <!--      <div class="s-personal-center__avatar">-->
          <!--        <avatar :src="appStore?.loginInfo?.headUrl" :user-name="appStore?.loginInfo?.nickName" />-->
          <!--      </div>-->
          <div class="s-personal-center__sign-out" @click="onSignOut">
            <jss-text :field="fields.signOutText" />
            <Icon name="forward" />
          </div>
        </div>
        <div class="s-personal-center__menus">
          <site-link class="s-personal-center__menu" :class="[{ active: activeMenu === menu.fields.code?.value }]" :link="menu.fields?.link" v-for="menu in fields?.menus" :key="menu.id">
            <div class="s-personal-center__menu-icon">
              <icon :field="menu.fields?.icon" />
            </div>
            <div class="s-personal-center__menu-link">{{ menu.fields?.link?.value?.text }}</div>
          </site-link>
        </div>
        <div class="s-personal-center__toggle" @click="toggleCollapsed">
          <icon name="back" />
        </div>
      </div>
    </div>
    <div class="s-personal-center__main">
      <button class="s-personal-center__back" @click="onBack">
        <icon name="back" />
        <span>{{ $t('Back') }}</span>
      </button>
      <div class="s-personal-center__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @typedef PersonalCenterBar
 * @property {SimpleField} welcomeText
 * @property {SimpleField} welcomeTextCollapsed
 * @property {SimpleField} signOutText
 * @property {Array<LinkField>} menus
 * */
import { getCurrentInstance, inject, reactive, toRefs } from 'vue';
import useAppStore from '@/store/appStore';
import api from '@/api';
import { getBetterUrl, redirectToLogin } from '@/utils/uri-utils';
import { canUseDOM } from '@/utils/dom-utils';
import useDevice from '@/hooks/useDevice';
import BUS_EVENTS from '@/utils/bus-events';
import { getGlobalConfigs } from '@/utils/site-utils';

export default {
  name: 'personal-center',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    },
    activeMenu: {
      type: String,
      default: 'my-order'
    }
  },
  setup(props) {
    /**@type AppStore*/
    const appStore = useAppStore();
    const { proxy } = getCurrentInstance();
    const $bus = inject('$bus');
    const { deviceState, deviceComputes } = useDevice();
    const appMethods = inject('appMethods');
    const collapsed = canUseDOM() && deviceComputes.isMobileDevice.value;
    const state = reactive({
      collapsed
    });
    const methods = {
      onBack() {
        const { $jss } = proxy;
        const page = $jss.routeData();
        const [myOrderLink] = getGlobalConfigs(page, 'myOrderLink');
        const myOrderUrl = getBetterUrl(myOrderLink?.value.href, page.itemLanguage, true);
        if (myOrderUrl) {
          window.location = myOrderUrl;
        } else {
          window.history.go(-1);
        }
      },
      toggleCollapsed() {
        state.collapsed = !state.collapsed;
      },
      async onSignOut() {
        await appMethods.logoutToLogin();
      }
    };
    $bus.on(BUS_EVENTS.OPEN_PERSONAL_CENTER_BAR, () => {
      state.collapsed = !state.collapsed;
    });
    return {
      ...toRefs(state),
      ...methods,
      deviceState,
      deviceComputes,
      appStore
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-personal-center {
  $c: &;
  background: $black;
  color: $white;
  @include height-except-header;
  overflow: auto;
  &__side {
    position: fixed;
    top: mobile-header-height();
    z-index: 1;
    width: 100%;
    height: 100%;
    @include height-except-header;
    background: rgba($black, 0.2);
    font-size: 14px;
    &-main {
      position: relative;
      background: $white;
      color: $black;
      width: 100%;
      max-height: 100%;
      padding-top: 24px;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid rgba(#2c2c2c, 0.2);
      }
    }
  }
  &__welcome {
    padding: 0 24px 24px;
    line-height: 18px;
    &:empty {
      display: none;
    }
  }
  &__avatar {
    text-align: center;
    margin-top: $space-16;
  }
  &__sign-out {
    // @include h10;
    display: inline-block;
    // margin-left: 24px;
    // margin-top: 24px;
    // margin-right: auto;
    margin-right: 18px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    line-height: 20px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 0;
      transition: all 400ms cubic-bezier(0.355, 0.005, 0.26, 1);
      border-top: 1px solid $black;
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
    > .e-icon {
      margin-left: 8px;
      vertical-align: middle;
      > svg {
        height: 12px;
        width: 16px;
      }
    }
  }
  &__menus {
    padding: 32px 24px 38px;
    line-height: 24px;
  }
  &__menu {
    display: flex;
    align-items: center;
    &-icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $grey-neutral;
      color: $white;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: $space-8;
      .e-icon {
        svg {
          width: 12px;
        }
      }
    }
    &.active {
      font-weight: bold;
    }
    & + & {
      margin-top: $space-24;
    }
  }
  &__toggle {
    position: absolute;
    right: 24px;
    bottom: 24px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 1px solid $grey;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__main {
    margin-left: 0;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
  }
  &__back {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: currentColor;
    margin-left: 10px;
    cursor: pointer;
    flex-shrink: 0;
    span {
      margin-left: 8px;
    }
  }
  &__content {
    flex-grow: 1;
    overflow-y: auto;
  }
  &.collapsed {
    #{$c}__side {
      display: none;
    }
  }
  @include tablet-landscape {
    @include height-except-header;
    position: relative;
    &__side {
      display: block;
      position: absolute;
      top: 0;
      width: grid-width(4);
      height: 100%;
      overflow-y: auto;
      &-main {
        height: 100%;
        &-header {
          flex-direction: column;
        }
      }
    }
    &__sign-out {
      margin-right: 0;
      padding: 0 24px 32px;
    }
    &__main {
      height: 100%;
      margin-left: grid-width(5);
      overflow-y: auto;
      padding-top: 0;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    &__back {
      margin-top: 24px;
    }
    &.collapsed {
      #{$c}__side {
        display: block;
        width: 84px;
      }
      #{$c}__sign-out {
        margin-left: 8px;
        margin-right: auto;
        padding: 0;
        margin-bottom: 32px;
        .e-icon {
          display: none;
        }
      }
      #{$c}__menu {
        justify-content: center;
        &-icon {
          margin-right: 0;
        }
        &-link {
          display: none;
        }
      }
      #{$c}__main {
        margin-left: 100px;
      }
      #{$c}__toggle {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
