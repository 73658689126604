<script setup>
import { computed, inject, reactive, ref } from 'vue';
import querystring from 'querystring';
import { debounce, merge } from 'lodash';
import api from '@/api';
import { formatModel, formDdl, getAlpha2CodeByLang } from '@/utils/site-utils';
import { equalString, toPureText } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { getCurrentPosition } from '@/utils/dom-utils';
import { ERROR_CODES } from '@/utils/constants';
import useAppStore from '@/store/appStore';
import services from '@/services';
import utils from '@/utils';
const emits = defineEmits(['update-filter']);
const props = defineProps({
  fields: {
    type: Object
  },
  series: {
    type: Object
  }
});
const toast = inject('toast');
/**@type AppStore*/
const appStore = useAppStore();
const searchFormRef = ref();
const state = reactive({
  page: null,
  language: null,
  alpha2Code: null,
  /**@type ModalRef | null*/
  modalRef: null,
  distanceDdl: null,
  fetchingLocation: false,
  locationDenied: false,
  /**@type MyGeolocation | null*/
  location: null,
  series: null,
  modelList: []
});
const filter = reactive({
  model: null,
  distanceOption: null,
  exteriorPaint: [],
  interiorTheme: [],
  wheels: []
});
const location = reactive({
  longitude: null,
  latitude: null,
  authorized: false
});
const titles = computed(() => {
  const { filterLabels } = props.fields || {};
  if (isNullOrEmpty(filterLabels?.value)) return {};
  return querystring.parse(filterLabels?.value);
});
const selectedExteriorPaints = computed(() => filter.exteriorPaint?.filter((x) => x.selected).map((x) => x.code));
const selectedThemes = computed(() => filter.interiorTheme?.filter((x) => x.selected).map((x) => x.code));
const selectedWheelsList = computed(() => filter.wheels?.filter((x) => x.selected).map((x) => x.code));
const setLocation = ({ longitude, latitude, detailAddress, authorized }) => {
  state.location = { longitude, latitude, detailAddress, authorized };
};
// const useMyLocation = async () => {
//   const [res, ex] = await getCurrentPosition();
//   if (ex?.code === 1) {
//     state.locationDenied = true;
//     await toast.showEx(ERROR_CODES.GeoRefused);
//   } else if (!isNullOrEmpty(ex?.message)) {
//     toast.showError(ex.message);
//   }
//   if (res?.coords) {
//     setLocation({
//       longitude: res.coords.longitude,
//       latitude: res.coords.latitude,
//       authorized: true
//     });
//   }
// };
const onFormChange = async (name, value, item) => {
  if (name === 'keyword') {
    const place = item.controlProps.selectedOption;
    if (!place) return;
    const geoResult = await services.map.geoDecode(props.page, {
      placeId: place.data.placeId
    });
    setLocation({
      longitude: geoResult.lng,
      latitude: geoResult.lat,
      detailAddress: toPureText(place.text),
      authorized: false
    });
  }
};
const onDisOptionChange = (e, option) => {
  filter.distanceOption = option;
};
const onModelChange = async (e, index, option) => {
  filter.model = option;
  await fetchFilter();
};
const getModelList = async () => {
  const [res, ex] = await api.stock.models(null, {
    series: state.series?.series,
    language: state.language
  });
  if (ex) {
    await toast.showEx(ex);
    return false;
  }
  let models = res;
  if (!isNullOrEmpty(state.series?.series)) {
    models = res.filter((x) => equalString(x.series, state.series?.series));
  }
  state.modelList = models.map((x) => ({
    code: formatModel(x.model),
    text: formatModel(x.model)
  }));
};
const updateFilter = (name, value) => {
  const item = filter[name];
  if (!item?.some((x) => x.selected)) {
    filter[name] =
      value?.map((x) => ({
        ...x,
        selected: false
      })) ?? [];
  }
};
const fetchFilter = debounce(async () => {
  const body = {
    country: state.alpha2Code,
    series: state.series?.series
  };
  if (!isNullOrEmpty(filter.model?.code)) {
    body.model = filter.model?.code;
  }
  if (selectedExteriorPaints.value?.length > 0) {
    body.exteriorPaintOptionId = selectedExteriorPaints.value;
  }
  if (selectedThemes.value?.length > 0) {
    body.themeOptionId = selectedThemes.value;
  }
  if (selectedWheelsList.value?.length > 0) {
    body.wheelsOptionId = selectedWheelsList.value;
  }
  const [res, ex] = await api.stock.filter(null, body);
  if (ex) {
    await toast.showEx(ex);
    return;
  }
  updateFilter('exteriorPaint', res.exteriorPaintInfoList);
  updateFilter('interiorTheme', res.themeInfoList);
  updateFilter('wheels', res.wheelsInfoList);
}, 50);
const onFilterToggle = async (option) => {
  option.selected = !option.selected;
  await fetchFilter();
};
const onReset = () => {
  appStore.setGeoLocation(null);
  filter.model = null;
  filter.distanceOption = null;
  filter.exteriorPaint = [];
  filter.interiorTheme = [];
  filter.wheels = [];
  emits('update-filter', null);
  state.modalRef.close();
};
const onQuery = () => {
  const result = {
    distance: filter.distanceOption?.code,
    location: state.location
  };

  if (!isNullOrEmpty(filter.model?.code)) {
    result.model = filter.model?.code;
  }
  if (selectedExteriorPaints.value?.length > 0) {
    merge(result, {
      exteriorPaintOptionId: selectedExteriorPaints.value
    });
  }
  if (selectedThemes.value?.length > 0) {
    merge(result, {
      interiorThemeOptionId: selectedThemes.value
    });
  }
  if (selectedWheelsList.value?.length > 0) {
    merge(result, {
      wheelsOptionId: selectedWheelsList.value
    });
  }
  console.log('result', result);
  emits('update-filter', result);
  state.modalRef.close();
};
const expMethods = {
  async init(language, series, modelName) {
    state.language = language;
    state.alpha2Code = getAlpha2CodeByLang(language);
    state.series = series;
    const { filterLabels } = props.fields ?? {};
    state.distanceDdl = formDdl(props.fields.distanceDropdown);
    if (!isNullOrEmpty(filterLabels?.value)) {
      const filterLabelsObj = querystring.parse(filterLabels?.value);
      const keys = Object.keys(filterLabelsObj);
      state.filterLabels = keys.map((x) => ({
        code: x,
        text: filterLabelsObj[x],
        opened: false
      }));
    }
    if (appStore.hasGeoLocation) {
      state.location = appStore.geoLocation;
      searchFormRef.value.updateItem('keyword', {
        controlProps: {
          value: appStore.geoLocation.detailAddress
        }
      });
    }
    await getModelList();
    if (!isNullOrEmpty(modelName)) {
      const activeModel = state.modelList.find((x) => equalString(x.code, modelName));
      state.model = activeModel;
      if (!isNullOrEmpty(state.model)) {
        await fetchFilter();
      }
      return activeModel;
    }
    return null;
  },
  async open() {
    if (appStore.hasGeoLocation) {
      searchFormRef.value.updateItem('keyword', {
        controlProps: {
          value: appStore.geoLocation.detailAddress
        }
      });
    }
    await state.modalRef.open();
  }
};
defineExpose({ ...expMethods });
</script>
<template>
  <modal class="s-sv-fm-v2" animation="left-slide-in" closable :ref="(e) => (state.modalRef = e)" :remove-on-hidden="false">
    <div class="s-sv-fm-v2__content">
      <filter-menu-section :title="titles.location" opened>
        <dynamic-form class="mg-b-40" :form="fields.searchForm" @change="onFormChange" ref="searchFormRef" />
        <!--        <div class="mg-b-40">-->
        <!--          <div class="s-sv-fm-v2__my-loc-btn" @click="useMyLocation">-->
        <!--            <icon :field="fields.myLocationIcon" />-->
        <!--            <jss-rich-text :field="fields.myLocationText" />-->
        <!--          </div>-->
        <!--          <div class="fs-12 mg-t-8 text-desc" v-if="appStore.hasGeoLocation">Location: {{ appStore.geoLocation.longitude }}, {{ appStore.geoLocation.latitude }}</div>-->
        <!--        </div>-->
        <dropdown v-bind="state.distanceDdl" :selected-option="filter.distanceOption" :disabled="$isNullOrEmpty(state.location)" @change="onDisOptionChange" />
      </filter-menu-section>
      <filter-menu-section :title="`${titles.model} (${filter.model ? 1 : 0})`" opened>
        <radio-list
          class="s-sv-fm-v2__models"
          :options="state.modelList"
          @change="onModelChange"
          :selected-option="filter.model"
          :column-count-mobile="1"
          :column-count-tablet="1"
          :column-count-desktop="1"
        />
      </filter-menu-section>
      <template v-for="(text, code) of titles" :key="code">
        <filter-menu-section :title="`${text} (${utils.array.arraySum(filter[code], (x) => (x.selected ? 1 : 0))})`" v-if="!$equalString(code, 'location') && filter[code]?.length > 0" :opened="true">
          <div class="s-sv-fm-v2__options">
            <div class="s-sv-fm-v2__option" :class="[{ checked: item.selected }]" v-for="item in filter[code]" :key="item.code">
              <div class="s-sv-fm-v2__option-label">{{ $toCapitalize(item.value) }}</div>
              <checkbox :value="item.selected" @change="onFilterToggle(item)" />
            </div>
          </div>
        </filter-menu-section>
      </template>
    </div>
    <div class="s-sv-fm-v2__ctas">
      <site-button v-bind="fields.resetButton" @click="onReset" />
      <site-button v-bind="fields.queryButton" @click="onQuery" />
    </div>
  </modal>
</template>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-sv-fm-v2 {
  $c: &;
  $m: '.modal';
  $rl: '.e-radio-list';
  &__content {
    height: 100%;
    overflow-y: auto;
    padding: 0 32px 120px 32px;
  }
  &__my-loc-btn {
    display: flex;
    gap: 8px;
    line-height: 1;
    margin-top: 16px;
    cursor: pointer;
    .e-icon {
      svg {
        fill: none;
      }
    }
  }
  .s-dynamic-form {
    padding: 0;
    .e-autocomplete-input {
      &__messages {
        display: none;
      }
    }
    .e-form-checkbox {
      width: 24px;
    }
  }
  .e-dropdown {
    &__label {
      color: $grey-next;
    }
  }
  .s-uc-fm-sec {
    border-top: 1px solid $grey-89;
    &__content {
      color: $black;
    }
  }
  &__models {
    #{$rl}__items {
      display: flex;
      flex-direction: column;
    }
    #{$rl}__item {
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
  &__options {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    padding-bottom: 24px;
  }
  &__option {
    display: flex;
    justify-content: space-between;
  }
  &__ctas {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    gap: 24px;
    background-color: $black;
    padding: 24px 32px;
    .e-site-button {
      width: calc(50% - 12px);
    }
  }
}
</style>
