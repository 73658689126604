<template>
  <div class="s-social-medias" v-if="items.length > 0">
    <site-link class="s-social-medias__item" v-for="item in items" :key="item.id" :link="item.fields.link" :data-event_name="linkEventName">
      <icon :field="item.fields.icon" />
    </site-link>
  </div>
</template>

<script>
export default {
  name: 'social-medias',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    linkEventName: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-social-medias {
  $c: &;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  &__item {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid currentColor;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s linear;
    .e-icon {
      > svg {
        width: 20px;
        height: 20px;
      }
    }
    &:hover {
      transform: scale(1.05);
    }
  }
  @include tablet-landscape {
    &__item {
      .e-icon {
        > svg {
          fill: $white;
        }
      }
    }
  }
}
</style>
