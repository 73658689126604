<script setup>
import { getPageAlpha2Code } from '@/utils/site-utils';
import { debounce, merge } from 'lodash';
import { getAgentListByTypes } from '@/services/agentService';
import services from '@/services';
import useAppStore from '@/store/appStore';
import { computed, getCurrentInstance, inject, nextTick, reactive, toRefs, watch } from 'vue';
import { isArray, isNullOrEmpty } from '@/utils/obj-utils';
import { getCurrentPosition, sleep } from '@/utils/dom-utils';
import { ERROR_CODES } from '@/utils/constants';
import { equalString, toPureText } from '@/utils/string-utils';
import utils from '@/utils';
import { unWrapSitecoreItem } from '@/utils/sitecore-utils';

const emit = defineEmits(['change']);
const props = defineProps({
  fields: {
    type: Object
  },
  index: {
    type: Number
  }
});
const { proxy } = getCurrentInstance();
const { $jss } = proxy;
/**@type AppStore*/
const appStore = useAppStore();
const toast = inject('toast');
const loading = inject('loading');
const rootState = inject('one-form-state');
const rootComputes = inject('one-form-computes');
const rootMethods = inject('one-form-methods');
console.log('props.index', props.index);
const state = reactive({
  page: null,
  /**@type OneFormSectionRef*/
  sectionRef: null,
  /**@type DynamicForm*/
  formRef: null,
  collapsed: props.index > 0,
  readonly: false,
  invisible: props.fields?.invisible.value ?? false,
  preSelected: false,
  dealers: [],
  originalList: [],
  storeCode: null,
  locationTypes: [],
  allLocationTypes: []
});
const count = reactive({
  lotusCentre: 6,
  event: 2
});
const form = computed(() => utils.oneForm.buildFormItems(props.fields?.formItems));
const checkDealerList = (list = null) => {
  if (!isArray(list)) return;
  let result = [];
  if (list === null) list = state.originalList;
  else state.originalList = list;
  let checkCodes = [];
  if (/test-drive/gi.test(rootComputes.formTypeVal.value)) {
    if (rootState.selectedVehicles.length > 0) {
      checkCodes = rootState.selectedVehicles.map((x) => `${x.code.toLowerCase()}TestDriveLocation`);
    } else if (!isNullOrEmpty(rootState.carSeries)) {
      checkCodes = [`${rootState.carSeries.series.toLowerCase()}TestDriveLocation`];
    }
  }
  if (checkCodes.length > 0) {
    result = list.filter((x) =>
      checkCodes.some((y) => {
        if (!x.extra || isNullOrEmpty(x.extra[y])) return true;
        return x.extra[y] === 1;
      })
    );
  } else {
    result = list;
  }
  console.log(rootComputes.formTypeVal.value, 'checkCodes:', checkCodes);
  state.dealers = result;
  if (!isNullOrEmpty(state.storeCode)) {
    rootState.dealer = result.find((x) => equalString(x.storeCode, state.storeCode));
  }
  return result;
};
const onSearch = async (value, item) => {
  if (item.controlProps.selectedOption) {
    const geoResult = await services.map.geoDecode(state.page, {
      placeId: item.controlProps.selectedOption.data.placeId
    });
    if (!geoResult) return;
    const place = item.controlProps.selectedOption;
    appStore.setGeoLocation({
      longitude: geoResult.lng,
      latitude: geoResult.lat,
      keyword: value,
      detailAddress: toPureText(place.text),
      authorized: false
    });
  }
  await getData();
};
const onFormChange = async (name, value, item) => {
  if (name === 'keyword') {
    await onSearch(value, item);
  }
};
const onDealerClick = debounce(async (dealer) => {
  state.storeCode = dealer.storeCode;
  rootState.dealer = dealer;
  emit('change', 'location', dealer);
  await state.sectionRef.collapse();
  rootMethods.expandNext(props.index);
}, 90);
const useMyLocation = async () => {
  loading.show();
  const [res, ex] = await getCurrentPosition();
  if (ex?.code === 1) {
    state.locationDenied = true;
    await toast.showEx(ERROR_CODES.GeoRefused);
  } else if (!isNullOrEmpty(ex?.message)) {
    loading.hide();
    toast.showError(ex.message);
  }
  if (res?.coords) {
    appStore.setGeoLocation({
      longitude: res.coords.longitude,
      latitude: res.coords.latitude,
      authorized: true
    });
    await getData();
  }
  loading.hide();
};
const expand = async () => {
  state.collapsed = false;
  await nextTick();
  if (appStore.hasGeoLocation && !isNullOrEmpty(appStore.geoLocation.keyword)) {
    state.formRef?.updateItem('keyword', {
      controlProps: {
        value: appStore.geoLocation.keyword
      }
    });
  }
};
const toggleLocationType = (type) => {
  type.selected = !type.selected;
};
const getData = async () => {
  const body = {};
  if (appStore.hasGeoLocation) {
    body.longitude = appStore.geoLocation.longitude;
    body.latitude = appStore.geoLocation.latitude;
    body.radius = 1000000;
  }
  const list = await services.agent.getAgentListByTypes(props.page, rootState.alpha2Code, body, null, props.dealerTypes);
  return checkDealerList(list);
};
const init = async (storeCode) => {
  state.page = $jss.routeData();
  let list = [],
    locationTypes = [];
  for (let lt of props.fields.locationTypes) {
    locationTypes.push({
      ...unWrapSitecoreItem(lt),
      selected: true
    });
  }
  state.locationTypes = locationTypes;
  [list, state.allLocationTypes] = await Promise.all([getData(), services.site.getLocationTypes()]);
  let preSelected = false;
  const sitecoreStoreCode = props.fields?.storeCode.value;
  if (!isNullOrEmpty(sitecoreStoreCode)) storeCode = sitecoreStoreCode;
  if (!isNullOrEmpty(storeCode)) {
    state.storeCode = storeCode;
    rootState.dealer = list.find((x) => equalString(x.storeCode, storeCode));
  }
  if (!isNullOrEmpty(rootState.dealer)) {
    state.collapsed = !isNullOrEmpty(rootState.dealer);
    preSelected = true;
    if (rootState.lockDealer || props.fields?.locked.value) {
      state.sectionRef.lock();
    }
    emit('change', 'location', rootState.dealer);
  }
  state.preSelected = preSelected;
  return preSelected;
};
watch(
  () => rootState.selectedVehicles,
  () => {
    checkDealerList();
  }
);
defineExpose({ init, expand, state: toRefs(state) });
</script>

<template>
  <one-form-section :fields="fields" :index="index" v-model:collapsed="state.collapsed" v-model:readonly="state.readonly" :done="!!rootState.dealer" :ref="(e) => (state.sectionRef = e)">
    <template #collapsed>{{ rootState.dealer?.storeName }}</template>
    <div class="s-of-location">
      <jss-rich-text class="fs-20 mg-b-16" :field="fields.title" />
      <jss-rich-text class="mg-b-24" :field="fields.body" />
      <dynamic-form :form="form" :ref="(e) => (state.formRef = e)" @change="onFormChange" />
      <div class="s-of-location__my-loc" @click="useMyLocation">
        <icon :field="fields.myLocationIcon" />
        <jss-rich-text :field="fields.myLocationText" />
      </div>
      <div class="s-of-location__types" v-if="state.locationTypes?.length > 1">
        <jss-text class="s-of-location__types-title" :field="fields.locationTypeTitle" />
        <div class="s-of-location__types-list">
          <div class="s-of-location__type" :class="[{ selected: type.selected }]" @click="toggleLocationType(type)" v-for="type in state.locationTypes" :key="type.id">
            <icon name="check" size="tiny" />
            <span v-html="`${type.text} (${count[type.code] ?? 0})`" />
          </div>
        </div>
      </div>
      <dealer-radio-list
        render-type="dealer-card-v4"
        :fields="fields"
        :data="{ locationTypes: state.allLocationTypes, isEvent: state.locationTypes.some((x) => $equalString(x.code, 'event')) }"
        :dealers="state.dealers"
        :dealer="rootState.dealer"
        @dealer-click="onDealerClick"
        v-if="state.dealers.length > 0"
      />
      <div class="s-of-location__empty" v-else>
        <div class="s-of-location__empty-block">
          <div class="s-of-location__empty-side">
            <icon :field="fields.emptyIcon" size="l" />
          </div>
          <div class="s-of-location__empty-main">
            <template-string
              class="fs-18"
              :field="!appStore.hasGeoLocation || appStore.geoLocation.authorized ? fields.myLocationEmptyTitle : fields.searchEmptyTitle"
              :data="{ keyword: appStore.geoLocation?.keyword ?? '' }"
            />
            <jss-rich-text class="text-desc" :field="fields.emptyBody" />
            <site-button class="mg-t-16" v-bind="fields.emptyButton" />
          </div>
        </div>
        <div class="s-of-location__empty-items">
          <div class="s-of-location__empty-item" v-for="item in fields.emptyItems" :key="item.id">
            <adaptive-image class="s-of-location__empty-item-img" :field="item.fields.image" v-aspect-ratio="'3_2'" />
            <jss-rich-text class="fs-18 fw-500 mg-b-8" :field="item.fields.title" />
            <jss-rich-text class="__c-grey-next mg-b-8" :field="item.fields.body" />
            <site-button v-bind="item.fields.button" />
          </div>
        </div>
      </div>
    </div>
  </one-form-section>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.s-of-location {
  &__my-loc {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    .e-icon {
      svg {
        fill: $white;
      }
    }
  }
  &__types {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 60px;
    &-title {
      color: $grey-next;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
  &__type {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px 16px;
    border: 1px solid $grey-89;
    user-select: none;
    cursor: pointer;
    .e-icon {
      color: $grey-89;
    }
    &.selected {
      color: $white;
      background: $black;
      .e-icon {
        color: $yellow;
      }
    }
  }
  &__empty {
    padding-top: 56px;
    &-block {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      border-bottom: 1px solid $grey-89;
      padding-bottom: 56px;
      margin-bottom: 56px;
    }
    &-main {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    &-items {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &-item {
      &-img {
        aspect-ratio: 3/2;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
