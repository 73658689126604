<template>
  <div class="s-merchandise-checkout-summary-life">
    <div class="s-merchandise-checkout-summary-life-header">
      <div class="s-merchandise-checkout-summary-life-header-title">
        {{ $t('Order summary') }}
      </div>
    </div>
    <div class="s-merchandise-checkout-summary-life-count" v-if="!storeInstall">{{ `${count} ${$t('Items').toLowerCase()}` }}</div>
    <div class="s-merchandise-checkout-summary-life-main">
      <div class="s-merchandise-checkout-summary-life-list">
        <div class="s-merchandise-checkout-summary-life-list-item" v-for="item in skuList" :key="item.id">
          <div class="s-merchandise-checkout-summary-life-list-item-img">
            <img :src="item.skuImageSeo?.skuImages[0]?.pic ?? item.spuMainImage" :alt="item.skuBusId" />
          </div>
          <div class="s-merchandise-checkout-summary-life-list-item-main">
            <div class="s-merchandise-checkout-summary-life-list-item-top">
              <div class="s-merchandise-checkout-summary-life-list-item-main-name">
                {{ item.spuName }}
              </div>
              <div class="s-merchandise-checkout-summary-life-list-item-main-des">
                {{ item.spec?.replace(/\|/g, ', ') }}
              </div>
              <div class="s-merchandise-checkout-summary-life-list-item-main-num">
                {{ storeInstall ? item.carModel : `${$t('Qty')}: ${item.num}` }}
              </div>
              <div class="s-merchandise-checkout-summary-life-list-item-main-price" v-if="!storeInstall">
                {{ $formatShopMoney(item.price ?? 0, currency) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="s-merchandise-checkout-summary-life-delivery" v-if="deliveryDetails">
        <div class="s-merchandise-checkout-summary-life-delivery-title">{{ storeInstall ? $t('Pickup details') : $t('Delivery details') }}</div>
        <div class="s-merchandise-checkout-summary-life-delivery-details">
          <div class="s-merchandise-checkout-summary-life-delivery-shipping">
            <div class="s-merchandise-checkout-summary-life-delivery-details-title">{{ $tu('Personal information') }}</div>
            <div>{{ `${deliveryDetails.personalData.firstName} ${deliveryDetails.personalData.lastName}` }}</div>
            <div>{{ deliveryDetails.pickup ? deliveryDetails.dealer?.addressDetail : deliveryDetails.shippingData.address }}</div>
            <div>{{ deliveryDetails.pickup ? deliveryDetails.dealer?.cityName : deliveryDetails.shippingData.city }}</div>
            <div>{{ deliveryDetails.pickup ? deliveryDetails.dealer?.countryName : deliveryDetails.shippingData.country.text }}</div>
            <div>{{ `+${deliveryDetails.personalData.mobile?.area.code} ${deliveryDetails.personalData.mobile?.number}` }}</div>
          </div>
          <div class="s-merchandise-checkout-summary-life-delivery-billing">
            <template v-if="storeInstall">
              <div class="s-merchandise-checkout-summary-life-delivery-details-title">{{ $tu('Pickup location') }}</div>
              <div>{{ deliveryDetails.dealerData.dealer.storeName }}</div>
              <div>{{ deliveryDetails.dealerData.dealer.addressDetail }}</div>
              <div>{{ deliveryDetails.dealerData.dealer.cityName }}</div>
              <div>{{ deliveryDetails.dealerData.dealer.countryName }}</div>
            </template>
            <template v-else>
              <div class="s-merchandise-checkout-summary-life-delivery-details-title">{{ $tu('Billing address') }}</div>
              <div>{{ deliveryDetails.diffAddr ? deliveryDetails.billingData.address : deliveryDetails.shippingData.address }}</div>
              <div>{{ deliveryDetails.diffAddr ? deliveryDetails.billingData.city : deliveryDetails.shippingData.city }}</div>
              <div>{{ deliveryDetails.diffAddr ? deliveryDetails.billingData.country.text : deliveryDetails.shippingData.country.text }}</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="s-merchandise-checkout-summary-life-price">
      <div class="s-merchandise-checkout-summary-life-price-details">
        <div class="s-merchandise-checkout-summary-life-price-details-item">
          <div class="s-merchandise-checkout-summary-life-price-details-item-label">{{ $tu('Subtotal') }}</div>
          <div class="s-merchandise-checkout-summary-life-price-details-item-num">{{ $formatShopMoney(priceDetails.itemFee ?? 0, currency) }}</div>
        </div>
        <div class="s-merchandise-checkout-summary-life-price-details-item" v-if="!storeInstall">
          <div class="s-merchandise-checkout-summary-life-price-details-item-label">{{ $tu('Shipping') }}</div>
          <div class="s-merchandise-checkout-summary-life-price-details-item-num">
            {{ !!priceDetails.expressFee ? $formatShopMoney(priceDetails.expressFee, currency) : $t('Free') }}
          </div>
        </div>
        <div class="s-merchandise-checkout-summary-life-price-details-item" v-show="!$equalString(pageAlpha2Code, 'GB')">
          <div
            class="s-merchandise-checkout-summary-life-price-details-item-left"
            @click="!largeThanTablet() && toggleVATDis()"
            @mouseover="largeThanTablet() && showVATDis()"
            @mouseout="largeThanTablet() && hideVATDis()"
            :style="{ cursor: showVATDisclaimer ? 'pointer' : 'default' }"
          >
            <JssRichText class="s-merchandise-checkout-summary-life-price-details-item-label" :field="fields.vatLabel" />
            <Icon :field="fields.disclaimerIcon" v-if="!$isNullOrEmpty(fields.vatDisclaimer)" />
          </div>
          <div class="s-merchandise-checkout-summary-life-price-details-item-num">{{ $formatShopMoney(priceDetails.vat ?? 0, currency) }}</div>
          <div
            class="s-merchandise-checkout-summary-life-price-details-item-disclaimer"
            v-html="$formatString(fields.vatDisclaimer?.fields.text.value, { vat: $formatShopMoney(priceDetails.vat ?? 0, currency) })"
            v-if="showVATDisclaimer"
          />
        </div>
      </div>
      <div class="s-merchandise-checkout-summary-life-price-top">
        <div
          class="s-merchandise-checkout-summary-life-price-top-left"
          @click="!largeThanTablet() && toggleDis()"
          @mouseover="largeThanTablet() && showDis()"
          @mouseout="largeThanTablet() && hideDis()"
          :style="{ cursor: showDisclaimer ? 'pointer' : 'default' }"
        >
          <JssRichText class="s-merchandise-checkout-summary-life-price-top-left-label" :field="fields.summaryLabel" />
          <Icon :field="fields.disclaimerIcon" v-if="!$isNullOrEmpty(fields.lifePriceDisclaimer)" />
          <div class="s-merchandise-checkout-summary-life-price-top-left-des">{{ !$equalString(pageAlpha2Code, 'GB') && !storeInstall ? $t('Excluded VAT') : $t('Including VAT') }}</div>
        </div>
        <div class="s-merchandise-checkout-summary-life-price-top-right">{{ $formatShopMoney(priceDetails.fee ?? 0, currency) }}</div>
        <div
          class="s-merchandise-checkout-summary-life-price-top-disclaimer"
          v-html="$formatString(fields.lifePriceDisclaimer?.fields.text.value, { price: $formatShopMoney(priceDetails.fee ?? 0, currency) })"
          v-if="showDisclaimer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, computed, onMounted } from 'vue';
import { largeThanTablet } from '@/utils/dom-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { getPageAlpha2Code } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';

export default {
  name: 'merchandise-checkout-summary-life',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    },
    skuList: {
      type: Array
    },
    priceDetails: {
      type: Object
    },
    currency: {
      type: String,
      default: 'EUR'
    },
    deliveryDetails: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      showVATDisclaimer: false,
      showDisclaimer: false,
      pageAlpha2Code: null
    });
    const computes = {
      count: computed(() => {
        let count = 0;
        props.skuList.forEach((s) => {
          count += Number(s.num);
        });
        return count;
      }),
      storeInstall: computed(() => props.skuList.length && equalString(props.skuList[0].spuType + '', '51121007'))
    };
    const methods = {
      largeThanTablet,
      toggleDis: () => {
        state.showDisclaimer = !state.showDisclaimer;
      },
      showDis: () => {
        if (!isNullOrEmpty(props.fields.lifePriceDisclaimer)) {
          state.showDisclaimer = true;
        } else {
          state.showDisclaimer = false;
        }
      },
      hideDis: () => {
        state.showDisclaimer = false;
      },
      toggleVATDis: () => {
        state.showVATDisclaimer = !state.showVATDisclaimer;
      },
      showVATDis: () => {
        if (!isNullOrEmpty(props.fields.vatDisclaimer)) {
          state.showVATDisclaimer = true;
        } else {
          state.showVATDisclaimer = false;
        }
      },
      hideVATDis: () => {
        state.showVATDisclaimer = false;
      }
    };
    onMounted(() => {
      state.pageAlpha2Code = getPageAlpha2Code(props.page);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-checkout-summary-life {
  height: calc(100vh - #{mobile-header-height()} - 56px);
  display: flex;
  flex-direction: column;
  &-header {
    padding: 0 24px;
    &-title {
      font-size: 26px;
      line-height: 32px;
      margin-top: 16px;
    }
  }
  &-count {
    font-size: 12px;
    line-height: 20px;
    margin-top: 24px;
    padding: 0 24px;
  }
  &-main {
    flex: 1;
    padding: 24px;
    margin-top: 8px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-delivery {
    margin-top: 72px;
    padding-top: 24px;
    border-top: 1px solid $grey-night;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
    &-details {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-bottom: 32px;
      &-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: $white;
      }
      > div {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $grey-next;
      }
    }
  }
  &-list {
    &-item {
      display: flex;
      flex-direction: row;
      gap: 27px;
      margin-bottom: 24px;
      &-img {
        width: 90px;
        height: 110px;
        background-color: $grey-light;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 88px;
          height: auto;
          object-fit: contain;
        }
      }
      &-main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-name {
          margin-bottom: 5px;
          font-size: 12px;
          font-weight: 700;
          line-height: 20px;
          color: $white !important;
        }
        > div {
          font-size: 12px;
          line-height: 20px;
          color: $grey-taubmans;
        }
      }
    }
  }
  &-price {
    width: 100%;
    color: $white;
    padding: 16px 24px 24px;
    border-top: 1px solid var(--shades-nshadeb, $grey-dark);
    background: radial-gradient(47.73% 50% at 52.27% 100%, rgba(64, 64, 64, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    &-details {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &-item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        &-left {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 6px;
          > p,
          div,
          span {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
          }
        }
        &-disclaimer {
          position: absolute;
          bottom: 24px;
          padding: 24px;
          background-color: $grey-light;
          color: $black;
        }
      }
    }
    &-top {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 18px;
      &-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        > p,
        div,
        span {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
        }
        &-des {
          opacity: 0.5;
        }
      }
      &-right {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      &-disclaimer {
        position: absolute;
        bottom: 24px;
        padding: 24px;
        background-color: $grey-light;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $black;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 4px;
      font-size: 12px;
      line-height: 20px;
      margin-top: 12px;
      .e-icon {
        display: flex;
        align-items: center;
      }
    }
  }
  @include tablet-landscape {
    height: calc(100vh - #{tablet-header-height()});
    margin-top: #{tablet-header-height()};
    &-header {
      padding: 0 grid-width(2);
      &-title {
        margin-top: 0;
      }
    }
    &-count {
      margin-top: 16px;
      padding: 0 grid-width(2);
    }
    &-main {
      margin-top: 48px;
      padding: 0 grid-width(2);
    }
    &-delivery {
      margin-top: 66px;
      padding-top: 24px;
      border-top: 1px solid $grey-night;
      display: flex;
      flex-direction: column;
      gap: 24px;
      &-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
      &-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        &-title {
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          color: $white;
        }
        > div {
          flex: 1;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: $grey-next;
        }
      }
    }
    &-price {
      padding: 32px grid-width(2) 16px;
      &-top {
        &-left {
          font-size: 16px;
          line-height: 24px;
          &-des {
            font-size: 12px;
            line-height: 20px;
          }
        }
        &-right {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
  @include desktop {
    height: calc(100vh - #{desktop-header-height()});
    margin-top: #{desktop-header-height()};
  }
}
</style>
