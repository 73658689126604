import api from '@/api';
import { buildAddress, getAlpha2CodeByLang, getGlobalConfigs, getPageAlpha2Code, isAppBrowser } from '@/utils/site-utils';
import { S_LANG, S_ORDER_STATUSES, S_WHEELS_ORDER_STATUSES, S_SERVICE_PLAN_ORDER_STATUSES, S_ORDER_VEHICLE_STATUSES } from '@/utils/web-storage-keys';
import { concatString, equalString, toFistLetterUpper } from '@/utils/string-utils';
import { formatDate } from '@/utils/date-utils';
import { merge } from 'lodash';
import { webStorage } from '@/utils/web-storage';
import { isNullOrEmpty } from '@/utils/obj-utils';
import config from '../temp/config';
import { BUY_CAR_TYPES } from '@/utils/constants';

/**
 *
 * @param mobileFormData
 * @param priceInfo
 * @param modelInfo
 * @param {ReservationAddress} address
 * @param {ReservationAddress} billingAddress
 * @param {Object} storeFormData
 * @param {Number} buyCarType
 * @returns {Promise<*>}
 */
export const submitReservationOld = async (mobileFormData, priceInfo, modelInfo, address, billingAddress, storeFormData, buyCarType) => {
  const body = prepareReservationOld(mobileFormData, priceInfo, modelInfo, address, billingAddress, storeFormData, buyCarType);
  return await api.order.submit(null, body);
};
export const prepareReservationOld = (mobileFormData, priceInfo, modelInfo, address, billingAddress, storeFormData, buyCarType) => {
  return {
    itemId: priceInfo.itemId,
    buyerName: `${address.firstName} ${address.lastName}`,
    buyerNameFirst: address.firstName,
    buyerNameMiddle: address.middleName ?? '',
    buyerNameLast: address.lastName,
    areaCode: mobileFormData.mobile.area.code,
    buyerPhone: `${mobileFormData.mobile.number}`,
    carModel: modelInfo?.engine?.code ?? modelInfo?.fields?.data?.value ?? '',
    market: address.country.data.alpha2Code,
    email: address.email,
    carSeries: priceInfo.code,
    orderConfigId: priceInfo.ruleId,
    orderPrice: priceInfo.price,
    currency: priceInfo.currency,
    // carModelDTO: {
    //   carModelPic: priceInfo.carModelPic,
    //   transferCode: priceInfo.transferCode,
    //   userOptionId: priceInfo.userOptionId
    // },
    // currency: priceInfo.currency,
    // orderPrice: priceInfo.prePrice,
    provinceCode: '',
    provinceName: '',
    orderSource: isAppBrowser() ? 'APP' : 'Official Website',
    countryName: address.country.data.alpha2Code,
    storeId: storeFormData?.store?.code ?? null,
    buyCarType,
    companyName: address.companyName ?? '',
    businessEmail: buyCarType === 200 ? address.email : null,
    detailAddress: {
      name: `${address.firstName} ${address.lastName}`,
      buyerNameFirst: address.firstName,
      buyerNameMiddle: address.middleName ?? '',
      buyerNameLast: address.lastName,
      city: address.city,
      country: address.country.code,
      province: '',
      street: buildAddress(address.address, address.address2),
      address: address.address,
      address2: address.address2,
      zipcode: address.zipcode
    },
    billingAddress: {
      name: `${billingAddress.firstName} ${billingAddress.lastName}`,
      buyerNameFirst: billingAddress.firstName ?? address.firstName,
      buyerNameMiddle: billingAddress.middleName ?? address.middleName ?? '',
      buyerNameLast: billingAddress.lastName ?? address.lastName,
      city: billingAddress.city,
      country: billingAddress.country.code,
      province: '',
      street: buildAddress(billingAddress.address, billingAddress.address2),
      address: billingAddress.address,
      address2: billingAddress.address2,
      zipcode: address.zipcode
    }
  };
};
export const createReservationOld = async (mobileFormData, priceInfo, modelInfo, address, billingAddress, storeFormData, buyCarType) => {
  const body = prepareReservationOld(mobileFormData, priceInfo, modelInfo, address, billingAddress, storeFormData, buyCarType);
  return await api.reservation.createOld(null, body);
};
export const prepareSubmitReservation = async (carSeries, powertrain, orderRule, downPayment, currency, dealer, email, customerType, personalData, addressData, billingData, companyData) => {
  const ct = customerType.fields.code.value;
  const buyCarType = BUY_CAR_TYPES[ct];
  return {
    carSeries: toFistLetterUpper(carSeries.series),
    carModel: powertrain?.name ?? '',
    orderConfigId: orderRule?.id ?? '',
    orderPrice: downPayment,
    currency: currency,
    market: addressData.country.data.alpha2Code,
    buyerName: `${personalData.firstName} ${personalData.lastName}`,
    buyerNameFirst: personalData.firstName,
    buyerNameMiddle: personalData.middleName ?? '',
    buyerNameLast: personalData.lastName,
    areaCode: personalData.mobile.area.code,
    buyerPhone: personalData.mobile.number,
    email: email,
    provinceCode: addressData.state?.code ?? '',
    provinceName: addressData.state?.text ?? '',
    orderSource: isAppBrowser() ? 'APP' : 'Official Website',
    countryName: addressData.country.data.alpha2Code,
    storeId: Number(dealer?.id) ?? null,
    buyCarType,
    companyName: companyData?.companyName ?? addressData?.companyName ?? '',
    companyCode: companyData?.companyRegistrationNumber ?? addressData?.companyRegistrationNumber ?? '',
    vatNumber: companyData?.vatNumber ?? addressData?.companyRegistrationNumber ?? '',
    businessEmail: buyCarType === '200' ? email : null,
    detailAddress: {
      name: `${personalData.firstName} ${personalData.lastName}`,
      buyerNameFirst: personalData.firstName,
      buyerNameMiddle: personalData.middleName ?? '',
      buyerNameLast: personalData.lastName,
      city: addressData.city,
      country: addressData.country.code,
      province: '',
      street: buildAddress(addressData.address, addressData.address2),
      address: addressData.address,
      address2: addressData.address2,
      zipcode: addressData.zipCode
    },
    billingAddress: {
      name: `${billingData.firstName ?? personalData.firstName} ${billingData.lastName ?? personalData.lastName}`,
      buyerNameFirst: billingData.firstName ?? personalData.firstName,
      buyerNameMiddle: billingData.middleName ?? personalData.middleName ?? '',
      buyerNameLast: billingData.lastName ?? personalData.lastName,
      city: billingData.city,
      country: billingData.country.code,
      province: '',
      street: buildAddress(billingData.address, billingData.address2),
      address: billingData.address,
      address2: billingData.address2,
      zipcode: billingData.zipCode
    }
  };
};
export const submitReservation = async (carSeries, powertrain, orderRule, downPayment, currency, dealer, email, customerType, personalData, addressData, billingData, companyData) => {
  const body = await prepareSubmitReservation(carSeries, powertrain, orderRule, downPayment, currency, dealer, email, customerType, personalData, addressData, billingData, companyData);
  return await api.reservation.submit(null, body);
};
export const createReservation = async (carSeries, powertrain, orderRule, downPayment, currency, dealer, email, customerType, personalData, addressData, billingData, companyData) => {
  const body = await prepareSubmitReservation(carSeries, powertrain, orderRule, downPayment, currency, dealer, email, customerType, personalData, addressData, billingData, companyData);
  return await api.reservation.create(null, body);
};
export const intactSubmit = async ({
  page,
  addressType,
  loginInfo,
  mobile,
  email,
  filterId,
  priceIds,
  vehicleInfo,
  paymentInfo,
  companyFormData,
  shippingFormData,
  billingFormData,
  consents,
  blindOrder,
  alternateInfo,
  storeFormData,
  tradeInData,
  financeFormData,
  soRightsAppCreateCo,
  formulaNo,
  orderRule,
  orderSource
}) => {
  const alpha2Code = getPageAlpha2Code(page);
  if (equalString(alpha2Code, 'IT')) {
    formulaNo = '54';
  }
  const title = companyFormData?.title;
  const closeDate = new Date();
  closeDate.setFullYear(closeDate.getFullYear() + 1);
  // const [resOpp, exOpp] = await api.crm.opp(null, {
  //   modelsofInterest__c: vehicleInfo.series,
  //   stageName: 'Sales Order',
  //   closeDate: formatDate(closeDate),
  //   name: 'rd',
  //   customerLotusID__c: loginInfo?.lotusId,
  //   customerLastName__c: shippingFormData?.firstName,
  //   customerFirstName__c: shippingFormData?.lastName
  // });
  // if (exOpp) {
  //   return [resOpp, exOpp];
  // }
  const body = {
    contractDetail: '111',
    commercialRelease: 0,
    country: shippingFormData.country.code,
    tradeIn: tradeInData?.check ? 1 : 0,
    financeOrder: financeFormData?.needFinanceOptions ? '1' : '0',
    localLang: page.itemLanguage,
    orderConfigId: orderRule?.id ?? '',
    vehicleType: isNullOrEmpty(vehicleInfo.vin) ? 'New' : 'Stock',
    orderMethod: isNullOrEmpty(vehicleInfo.vin) ? 2 : 1,
    orderSource,
    deliveryInfo: {
      firstName: shippingFormData.firstName,
      middleName: shippingFormData.middleName ?? '',
      lastName: shippingFormData.lastName,
      province: shippingFormData.province?.text ?? '',
      zipCode: shippingFormData.zipCode,
      deliveryMethod: '2',
      preferredDeliveryTime: '25/09/2023',
      email: email,
      country: shippingFormData.country.code,
      countryName: shippingFormData.country.text,
      street: buildAddress(shippingFormData.address, shippingFormData.address2),
      address: shippingFormData.address,
      address2: shippingFormData.address2,
      deliveryCenter: 'E1012',
      city: shippingFormData.city
    },
    invoiceInfo: {
      firstName: billingFormData.firstName,
      middleName: billingFormData.middleName ?? '',
      lastName: billingFormData.lastName,
      province: shippingFormData.province?.text ?? '',
      customerName: concatString([title, billingFormData.firstName, billingFormData.middleName, billingFormData.lastName], ' '),
      zipCode: billingFormData.zipCode,
      street: buildAddress(billingFormData.address, billingFormData.address2),
      address: billingFormData.address,
      address2: billingFormData.address2,
      email: email,
      country: billingFormData.country.code,
      countryName: billingFormData.country.text,
      city: billingFormData.city
    },
    comments: '',
    paymentInfo,
    immediatePayment: true,
    userInfo: {
      customerId: loginInfo?.lotusId,
      customerType: addressType?.code, // 'individual',
      customerName: concatString([title, shippingFormData.firstName, shippingFormData.middleName, shippingFormData.lastName], ' '),
      areaCode: mobile?.area.code ?? blindOrder?.areaCode,
      phone: mobile?.number ?? blindOrder?.buyerPhone,
      businessEmail: addressType?.code === 'individual' ? '' : email,
      companyName: companyFormData?.companyName ?? '',
      vatNumber: companyFormData?.vatNumber ?? alternateInfo?.vatNumber ?? '',
      companyRegistrationNumber: companyFormData?.companyRegistrationNumber ?? '',
      email: email,
      memberUniId: loginInfo?.lotusId,
      firstName: shippingFormData.firstName,
      middleName: shippingFormData.middleName ?? '',
      lastName: shippingFormData.lastName,
      // oppId: resOpp.id, //`0069E00000${randomString(8, 3)}`, // '0069E00000KJiYRQA1',
      mobilePhone: mobile?.number ?? blindOrder?.buyerPhone,
      dateOfBirth: formatDate(shippingFormData.dateOfBirth),
      store: storeFormData?.store?.text ?? '', //'Van Der Kooi Sportscars',
      storeId: storeFormData?.store?.code ?? '',
      orderType: addressType?.code === 'individual' ? '1' : '2',
      buyCarType: addressType?.code === 'individual' ? '100' : '200',
      title
    },
    privacyPolicies: consents.map((x) => ({
      agreementName: 'ada',
      agreementNo: x,
      agreementVersion: '1.0',
      pdfSn: '114477',
      pdfUrl: '114514',
      signedDateTime: new Date().getTime()
    })),
    prePaymentMsgReqVO: {
      country: alpha2Code,
      formulaNo: formulaNo,
      sourceItemId: filterId,
      sourceItemIds: priceIds,
      summary: ''
    },
    vehicleInfo,
    reservationInfo: {
      businessId: '',
      reservationVehicle: '',
      reservationSource: '',
      reservationNo: '',
      orderPrice: 0,
      reservationStatus: '',
      paymentTime: ''
    },
    soRightsAppCreateCo
  };
  if (blindOrder?.id) {
    merge(body, { blindOrderId: blindOrder?.id });
  }
  return await api.order.intactSubmit(null, body);
};

export const submitOrder = async ({
  page,
  formulaNo,
  customerType,
  loginInfo,
  email,
  filterId,
  priceIds,
  vehicleInfo,
  paymentInfo,
  personalData,
  companyData,
  shippingData,
  billingData,
  consents,
  blindOrder,
  alternateInfo,
  agent,
  businessData,
  tradeInData,
  paymentOption,
  soRightsAppCreateCo,
  simulationId,
  orderRule,
  orderSource
}) => {
  const alpha2Code = getPageAlpha2Code(page);
  let formulaNoVal = formulaNo;
  if (!equalString(vehicleInfo?.series, 'Emira') && equalString(alpha2Code, 'IT')) {
    formulaNoVal = '54';
  }
  const title = companyData?.title?.code ?? personalData?.title?.code ?? companyData?.title ?? personalData?.title ?? '';
  const isFinanceOrder = paymentOption?.name !== 'cash';
  const closeDate = new Date();
  const ct = customerType?.code;
  closeDate.setFullYear(closeDate.getFullYear() + 1);
  const body = {
    contractDetail: '111',
    commercialRelease: 0,
    country: shippingData.country.code,
    tradeIn: tradeInData?.check ? 1 : 0,
    financeOrder: isFinanceOrder ? '1' : '0',
    localLang: page.itemLanguage,
    simulationId: isFinanceOrder || alpha2Code === 'GB' ? simulationId : null,
    vehicleType: isNullOrEmpty(vehicleInfo.vin) ? 'New' : 'Stock',
    orderConfigId: orderRule?.id ?? '',
    orderMethod: isNullOrEmpty(vehicleInfo.vin) ? 2 : 1,
    orderSource,
    deliveryInfo: {
      firstName: personalData.firstName,
      middleName: personalData.middleName ?? '',
      lastName: personalData.lastName,
      province: shippingData.province?.text ?? '',
      zipCode: shippingData.zipCode,
      deliveryMethod: '2',
      preferredDeliveryTime: '25/09/2023',
      email: email,
      country: shippingData.country.code,
      countryName: shippingData.country.text,
      street: buildAddress(shippingData.address, shippingData.address2),
      address: shippingData.address,
      address2: shippingData.address2,
      deliveryCenter: 'E1012',
      city: shippingData.city
    },
    invoiceInfo: {
      lastName: personalData.lastName,
      middleName: personalData.middleName ?? '',
      firstName: personalData.firstName,
      province: shippingData.province?.text ?? '',
      customerName: concatString([title, personalData.firstName, personalData.middleName, personalData.lastName], ' '),
      zipCode: billingData.zipCode,
      street: buildAddress(billingData.address, billingData.address2),
      address: billingData.address,
      address2: billingData.address2,
      email: email,
      country: billingData.country.code,
      countryName: billingData.country.text,
      city: billingData.city
    },
    comments: '',
    paymentInfo,
    immediatePayment: true,
    userInfo: {
      firstName: personalData.firstName,
      middleName: personalData.middleName ?? '',
      lastName: personalData.lastName,
      customerId: loginInfo?.lotusId,
      customerType: ct, // 'individual',
      customerName: concatString([title, personalData.firstName, personalData.middleName, personalData.lastName], ' '),
      areaCode: blindOrder?.areaCode ?? personalData.mobile?.area.code,
      mobilePhone: blindOrder?.buyerPhone ?? personalData.mobile?.number,
      businessEmail: ct === 'individual' ? '' : companyData?.email ?? email ?? '',
      companyName: companyData?.companyName ?? '',
      vatNumber: companyData?.vatNumber ?? alternateInfo?.vatNumber ?? '',
      companyRegistrationNumber: companyData?.companyRegistrationNumber ?? '',
      email: email,
      memberUniId: loginInfo?.lotusId,
      // oppId: resOpp.id, //`0069E00000${randomString(8, 3)}`, // '0069E00000KJiYRQA1',
      dateOfBirth: formatDate(personalData.dateOfBirth),
      store: agent?.text ?? '',
      storeId: agent?.code ?? '',
      orderType: ct === 'individual' ? '1' : '2',
      buyCarType: ct === 'individual' ? '100' : '200',
      title
    },
    privacyPolicies: consents.map((x) => ({
      agreementName: 'ada',
      agreementNo: x,
      agreementVersion: '1.0',
      pdfSn: '114477',
      pdfUrl: '114514',
      signedDateTime: new Date().getTime()
    })),
    prePaymentMsgReqVO: {
      country: alpha2Code,
      formulaNo: formulaNoVal,
      sourceItemId: filterId,
      sourceItemIds: priceIds,
      summary: ''
    },
    vehicleInfo,
    reservationInfo: {
      businessId: '',
      reservationVehicle: '',
      reservationSource: '',
      reservationNo: '',
      orderPrice: 0,
      reservationStatus: '',
      paymentTime: ''
    },
    soRightsAppCreateCo
  };
  if (blindOrder?.id) {
    merge(body, { blindOrderId: blindOrder?.id });
  }
  if (equalString(alpha2Code, 'GB')) {
    merge(body, {
      businessType: businessData?.businessType.code,
      financeMethod: paymentOption?.code ?? ''
    });
    merge(body, {
      userInfo: {
        companyRelationships: [
          {
            mobileAreaCode: companyData?.mobile?.area.code ?? '',
            phone: companyData?.mobile?.number ?? '',
            firstName: companyData?.firstName ?? '',
            lastName: companyData?.lastName ?? ''
          }
        ]
      }
    });
  }
  return await api.order.intactSubmit(null, body);
};

export const getOrderStatuses = async () => {
  const lang = webStorage.get(S_LANG);
  const cache = webStorage.get(S_ORDER_STATUSES) || {};
  if (!cache[lang]) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.orderStatuses,
      isDeep: true
    });
    if (res?.children?.length > 0) {
      cache[lang] = res.children;
      webStorage.set(S_ORDER_STATUSES, cache);
    }
  }
  return cache[lang];
};
export const getWheelsOrderStatuses = async () => {
  const lang = webStorage.get(S_LANG);
  const cache = webStorage.get(S_WHEELS_ORDER_STATUSES) || {};
  if (!cache[lang]) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.wheelsOrderStatuses,
      isDeep: true
    });
    if (res?.children?.length > 0) {
      cache[lang] = res.children;
      webStorage.set(S_WHEELS_ORDER_STATUSES, cache);
    }
  }
  return cache[lang];
};
export const getServicePlanOrderStatuses = async () => {
  const lang = webStorage.get(S_LANG);
  const cache = webStorage.get(S_SERVICE_PLAN_ORDER_STATUSES) || {};
  if (!cache[lang]) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.servicePlanOrderStatuses,
      isDeep: true
    });
    if (res?.children?.length > 0) {
      cache[lang] = res.children;
      webStorage.set(S_SERVICE_PLAN_ORDER_STATUSES, cache);
    }
  }
  return cache[lang];
};
export const getOrderStatus = async (code) => {
  const statuses = await getOrderStatuses();
  return statuses.find((x) => x.code === code);
};
export const getOrderVehicleStatuses = async () => {
  const lang = webStorage.get(S_LANG);
  const cache = webStorage.get(S_ORDER_VEHICLE_STATUSES) || {};
  if (!cache[lang]) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.vehicleStatuses,
      isDeep: true
    });
    if (res?.children?.length > 0) {
      cache[lang] = res.children;
      webStorage.set(S_ORDER_VEHICLE_STATUSES, cache);
    }
  }
  return cache[lang];
};
export const getOrderVehicleStatus = async (code) => {
  const statuses = await getOrderVehicleStatuses();
  if (!isNullOrEmpty(code)) {
    return statuses.find((x) => equalString(x.otdCode, code.toString()));
  } else {
    return null;
  }
};
export const getOrderDefaultLang = (page, orderCountry) => {
  if (isNullOrEmpty(orderCountry)) return page.itemLanguage;
  const [areas] = getGlobalConfigs(page, 'areas');
  const pageLang = page.itemLanguage;
  if (pageLang.indexOf('-') > 0 && equalString(pageLang.split('-')[1], orderCountry)) return pageLang;
  if (areas?.length) {
    for (let area of areas) {
      for (let region of area.fields.regions) {
        if (isNullOrEmpty(region.fields.language?.fields?.code.value)) continue;
        if (region.fields.languages.some((l) => l?.fields?.code.value.indexOf('-') > 0 && equalString(l?.fields?.code.value.split('-')[1], orderCountry))) {
          return region.fields.language.fields.code.value;
        }
      }
    }
  }
  return `en-${orderCountry.toUpperCase()}`;
};
export const checkOrderLang = (page, orderCountry) => {
  const orderLanguage = getOrderDefaultLang(page, orderCountry);
  const needRedirect = getAlpha2CodeByLang(orderLanguage) !== getAlpha2CodeByLang(page.itemLanguage);
  return [needRedirect, needRedirect ? orderLanguage : page.itemLanguage];
};
