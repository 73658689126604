<script setup>
import { reactive, inject, computed, ref } from 'vue';
import { transitions } from '@/utils/transitions';
import useAppStore from '@/store/appStore';
import { isNullOrEmpty } from '@/utils/obj-utils';
import utils from '@/utils';
import { equalString } from '@/utils/string-utils';

const props = defineProps({
  fields: {
    type: Object
  }
});
/**@type AppStore*/
const appStore = useAppStore();
const rootState = inject('one-form-state');
const calculation = inject('one-form-calculation');
const rootMethods = inject('one-form-methods');
const state = reactive({
  /**@type {?DynamicForm}*/
  formRef: null,
  /**@type {?OneFormTradeInRef}*/
  tradeInRef: null,
  financeQuoteOpened: false,
  tradeInQuoteOpened: false,
  paymentOptions: []
});
const form = computed(() => utils.oneForm.buildFormItems(props.fields?.formItems));
const onPayOptionChange = (e, option) => {
  rootState.paymentOption = option;
};
const onFormChange = async (key, value) => {
  switch (key) {
    case 'tradeIn':
      {
        if (value) {
          await state.tradeInRef.open(rootState.tradeInData);
        }
      }
      break;
    case 'financeInterest':
      {
        if (!rootState.financeInitialized && value) {
          await rootMethods.initFinanceInfo();
        }
      }
      break;
    default:
      break;
  }
};
const onTradeInClosed = () => {
  if (isNullOrEmpty(rootState.tradeInData)) {
    state.formRef.updateItem('tradeIn', {
      controlProps: {
        value: false
      }
    });
  }
};
const onEdit = () => {
  state.tradeInRef.open(rootState.tradeInData);
};
const onChangeFinance = () => {
  rootMethods.editFinance();
};
const validate = async () => {
  const [valid, formData] = await state.formRef.validate();
  const consents = state.formRef.getVerifiedFullConsents();
  return [valid, formData, consents];
};
const updateFormItems = (payload) => {
  state.formRef.updateItems(payload);
};
const getVerifiedConsents = () => state.formRef.getVerifiedConsents();
defineExpose({ validate, updateFormItems, getVerifiedConsents });
</script>

<template>
  <one-form-section :bordered="false">
    <div class="s-of-light-forms">
      <jss-rich-text class="fs-20 mg-b-4" :field="fields.title" />
      <jss-rich-text class="fs-12 __c-grey-next" :field="fields.body" />
      <dynamic-form :form="form" @change="onFormChange" :ref="(e) => (state.formRef = e)">
        <template #financeInterestContent v-if="rootState.hasFinanceLevel2 && rootState.carSeries?.financeLevel2">
          <div class="mg-t-24">
            <content-loading theme="white" :loading="rootState.financeCalculating">
              <template #loading>
                <spinner name="ball-beat" />
              </template>
              <site-accordion :title="fields?.financeQuoteTitle?.value" v-model:active="state.financeQuoteOpened">
                <background-image class="mg-b-40" :image="fields.regulatoryImage" :is-background="false" />
                <template v-if="!$equalString(rootState.alpha2Code, 'GB') && rootState.paymentOptions?.length > 1">
                  <jss-rich-text class="mg-t-24 mg-b-16" :field="fields.paymentOptionTitle" />
                  <div class="s-std-form__payment-options">
                    <div
                      class="s-std-form__payment-option"
                      :class="[{ active: rootState.paymentOption?.name === payment?.name }]"
                      v-html="payment.title"
                      v-for="payment in rootState.paymentOptions"
                      :key="payment.name"
                      @click.stop="onPayOptionChange($event, payment)"
                    />
                  </div>
                </template>
                <div class="s-std-form__change-finance">
                  <icon :field="fields.changeFinanceIcon" @click="onChangeFinance" />
                  <jss-rich-text class="s-checkout-finance__change-finance-text" :field="fields.changeFinanceText" @click="onChangeFinance" />
                </div>
                <finance-quote-detail :state="rootState" :calculation="calculation" />
              </site-accordion>
            </content-loading>
          </div>
        </template>
        <template #tradeInContent v-if="rootState.tradeInData">
          <site-accordion :title="fields.tradeInQuoteTitle?.value" v-model:active="state.tradeInQuoteOpened">
            <div class="s-of-light-forms__toolbar">
              <jss-text class="fs-12 hand tdl" :field="fields.changeTradeInText" @click="onEdit" />
            </div>
            <div class="s-of-light-forms__quote">
              <label-value :label="$t('Make')">{{ rootState.tradeInData.make }}</label-value>
              <label-value :label="$t('Model')">{{ rootState.tradeInData.model }}</label-value>
              <label-value :label="$t('License plat number')">{{ rootState.tradeInData.licensePlatNumber }}</label-value>
              <label-value :label="$t('Year of first registration')">{{ rootState.tradeInData.yearOfFirstRegistration }}</label-value>
              <label-value :label="$t('Mileage')">{{ rootState.tradeInData.mileage }}</label-value>
            </div>
          </site-accordion>
        </template>
      </dynamic-form>
      <one-form-trade-in v-bind="fields.tradeInModal" @closed="onTradeInClosed" :ref="(e) => (state.tradeInRef = e)" />
    </div>
  </one-form-section>
</template>

<style lang="scss">
@import '../../../styles/variable';
@import '../../../styles/function';
@import '../../../styles/mixin';
.s-of-light-forms {
  &__toolbar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
  &__quote {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .e-label-value {
      padding: 0;
      display: flex;
      justify-content: space-between;
      &__label {
        color: $grey-next;
      }
    }
  }
}
</style>
