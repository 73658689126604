<template>
  <div class="s-merchandise-checkout-plan" v-if="fields" v-show="$equalString(stepCode, 'plan')">
    <Heading class="s-merchandise-checkout-plan__title" :field="fields.title" :type="fields.titleType" rich />
    <div class="s-merchandise-checkout-plan__vins" v-if="!vinList.length">
      <JssRichText class="s-merchandise-checkout-plan__subtitle" :field="fields.addVinSubtitle" />
      <JssRichText class="s-merchandise-checkout-plan__body" :field="fields.addVinBody" />
      <DynamicForm :form="fields.vinForm" ref="vinFormRef" @change="onChangeVin" />
    </div>
    <div class="s-merchandise-checkout-plan__vins" v-else>
      <JssRichText class="s-merchandise-checkout-plan__subtitle" :field="fields.subtitle" />
      <JssRichText class="s-merchandise-checkout-plan__body" :field="fields.body" />
      <div class="s-merchandise-checkout-plan__vins-item" :class="{ active: vin && $equalString(item.vin, vin) }" v-for="item in vinList" :key="item.id" @click="selectVin(item)">
        <div class="s-merchandise-checkout-plan__vins-item-model">{{ item.model }}</div>
        <div class="s-merchandise-checkout-plan__vins-item-code">{{ `VIN ${item.vin}` }}</div>
        <Icon name="loading" class="s-merchandise-checkout-plan__vins-item-icon" v-if="!showDetails && !$isNullOrEmpty(vin)" />
      </div>
    </div>
    <Transition @before-enter="transitions?.accordion.beforeEnter" @enter="transitions?.accordion.enter" @leave="transitions?.accordion.leave">
      <div class="s-merchandise-checkout-plan__details" v-show="!$isNullOrEmpty(spuDetails) && !$isNullOrEmpty(vin) && showDetails" ref="detailsEl">
        <template v-if="$isNullOrEmpty(skuDetails)">
          <JssRichText class="s-merchandise-checkout-plan__details-empty" :field="fields.emptyTitle" />
        </template>
        <template v-else>
          <JssRichText class="s-merchandise-checkout-plan__details-title" :field="fields.planTitle" />
          <div class="s-merchandise-checkout-plan__details-main">
            <div class="s-merchandise-checkout-plan__details-main-title">{{ spuDetails.name }}</div>
            <div class="s-merchandise-checkout-plan__details-main-price">{{ `${$t('Total')} ${price}` }}</div>
            <div class="s-merchandise-checkout-plan__details-main-description" v-html="skuDetails?.skuDescribe?.describe"></div>
            <div class="s-merchandise-checkout-plan__details-main-download" v-if="!$isNullOrEmpty(contentList)">
              <a target="_blank" :href="c.pdfUrl" :download="c.agreementName" v-for="c in contentList" :key="c.id">{{ fields.downloadText.value }}</a>
            </div>
          </div>
        </template>
      </div>
    </Transition>
  </div>
</template>
<script>
import { reactive, toRefs, inject, watch } from 'vue';
import { equalString } from '@/utils/string-utils';
import { canUseDOM } from '@/utils/dom-utils';
import { transitions } from '@/utils/transitions';
import api from '@/api';

export default {
  name: 'MerchandiseCheckoutPlan',
  props: {
    page: {
      type: Object
    },
    stepCode: {
      type: String
    },
    fields: {
      type: Object
    },
    vinList: {
      type: Array
    },
    vin: {
      type: String
    },
    spuDetails: {
      type: Object
    },
    skuDetails: {
      type: Object
    },
    price: {
      type: String
    },
    showDetails: {
      type: Boolean
    }
  },
  setup(props) {
    const merchandiseCheckoutMethods = inject('merchandiseCheckoutMethods');
    const state = reactive({
      detailsEl: null,
      vinFormRef: null,
      contentList: []
    });
    const methods = {
      selectVin: (i) => {
        merchandiseCheckoutMethods.selectVin(i.vin);
      },
      onChangeVin: (k, v) => {
        if (equalString(k, 'vin')) {
          merchandiseCheckoutMethods.selectVin(v);
        }
      }
    };

    watch(
      () => props.showDetails,
      (v) => {
        if (v && canUseDOM()) {
          setTimeout(() => {
            const offset = state.detailsEl?.getBoundingClientRect();
            window.scrollTo(0, offset.top + window.scrollY - 60);
          }, 20);
        }
      }
    );

    watch(
      () => props.spuDetails,
      (v) => {
        if (v) {
          if (v.decoration?.length) {
            const content = v.decoration[0];
            if (content?.contentDetail?.length) {
              content?.contentDetail.forEach(async (c) => {
                const [res, ex] = await api.consent.getPDF(null, { qo: { agreementNo: c.agreementNo } });
                if (res) {
                  state.contentList.push(res);
                }
              });
            }
          }
        }
      },
      { deep: true }
    );

    return {
      ...toRefs(state),
      ...methods,
      transitions
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-merchandise-checkout-plan {
  $s: &;
  &__title {
    font-size: 32px;
    line-height: 1;
  }
  &__subtitle {
    font-size: 18px;
    line-height: 28px;
    margin-top: 42px;
  }
  &__body {
    font-size: 16px;
    line-height: 24px;
    color: $grey-next;
    margin-top: 4px;
  }
  &__vins {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
    &-item {
      position: relative;
      width: 100%;
      text-align: center;
      padding: 12px 16px;
      border: 1px solid #bdbdbd;
      cursor: pointer;
      &-model {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: $grey-dark;
      }
      &-code {
        font-size: 12px;
        line-height: 20px;
        color: $grey-next;
        margin-top: 3px;
      }
      &-icon {
        position: absolute;
        top: calc(50% - 12px);
        right: 24px;
        animation: iconScroll 1.5s linear infinite;
      }
      &.active {
        background-color: $black;
        #{$s}__vins-item-model {
          color: $white;
        }
      }
    }
  }
  &__details {
    margin-top: 64px;
    &-empty,
    &-title {
      font-size: 18px;
      line-height: 28px;
      color: $black;
    }
    &-main {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;
      border: 1px solid $grey-89;
      padding: 32px 24px;
      color: $black;
      &-title {
        font-size: 26px;
        line-height: 32px;
      }
      &-price {
        font-size: 16px;
        line-height: 24px;
      }
      &-description {
        font-size: 12px;
        line-height: 20px;
        opacity: 0.8;
        word-break: break-word;
      }
      &-download {
        font-size: 12px;
        line-height: 20px;
        > a {
          display: inline-block;
          text-decoration: underline;
          position: relative;
          box-shadow: inset 0 0 0 0 #fdef00;
          transition: all 0.6s cubic-bezier(0.355, 0.005, 0.26, 1);
        }
      }
    }
  }
  @include tablet-landscape {
    &__subtitle {
      font-size: 26px;
      line-height: 32px;
      margin-top: 32px;
    }
    &__body {
      margin-top: 24px;
    }
    &__vins {
      &-item {
        padding: 12px 24px;
      }
    }
  }
  @keyframes iconScroll {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
