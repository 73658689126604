import api from '@/api';
import { sortByChar } from '@/utils/array-utils';
import { getGlobalConfigs, getPageAlpha2Code } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';
import services from '@/services';
import { isNullOrEmpty, isString } from '@/utils/obj-utils';
import { merge } from 'lodash';
import config from '../temp/config';
import { WEEKDAY_ARRAY } from '@/utils/constants';
import { webStorage } from '@/utils/web-storage';
import { S_LANG, S_STORE_LINKS } from '@/utils/web-storage-keys';
import utils from '@/utils';

export const getAgentList = async (page, alpha2Code = null, options = null, series = null, global = false) => {
  if (!alpha2Code) alpha2Code = getPageAlpha2Code(page);
  const [hiddenStores] = getGlobalConfigs(page, 'hiddenStores');
  const hiddenStoreCodes = (hiddenStores?.value ?? '').split(';');
  const body = {
    pageNum: '1',
    pageSize: '999999',
    countryCode: global ? null : alpha2Code
  };
  if (options?.storeType === null) {
    delete options.storeType;
  }
  merge(body, options);
  const [res] = await api.store.query(body);
  let storeList = (res ?? []).filter((x) => !hiddenStoreCodes.some((y) => equalString(y, x.storeCode)));
  if (options?.storeType === null) {
    storeList = storeList.filter((x) => ![2, '2'].includes(x.storeType));
  }
  if (isString(series)) {
    const agentPropName = `${series.toLowerCase()}TestDriveLocation`;
    storeList = storeList.filter((s) => !!s.extra[agentPropName]);
  }
  if (!storeList?.length) return [];
  for (let store of storeList) {
    if (!isNullOrEmpty(store.storeType)) {
      store.storeTypes = store.storeType.split(',').map((x) => Number(x));
    } else {
      store.storeTypes = [];
    }
  }
  if (storeList[0].metre) return storeList;
  return sortByChar(storeList, (e) => e.storeName);
};
const agentList2Options = (list) => {
  return list.map((x) => ({
    code: x.id,
    text: x.storeName,
    data: x
  }));
};
export const getAgentOptions = async (page, alpha2Code = null, options = null, series = null) => {
  const list = await getAgentList(page, alpha2Code, options, series);
  return agentList2Options(list);
};
export const getSalesAgentList = async (page, alpha2Code = null, options = null, series = null) => {
  return getAgentListByTypes(page, alpha2Code, options, series, [4]);
};

export const getSalesAgentOptions = async (page, alpha2Code = null, options = null, series = null) => {
  const list = await getSalesAgentList(page, alpha2Code, options, series);
  return agentList2Options(list);
};
export const getNormalAgentList = async (page, alpha2Code = null, options = null, series = null) => {
  return getAgentListByTypes(page, alpha2Code, options, series, [1, 4]);
};
export const getAgentListByTypes = async (page, alpha2Code = null, options = null, series = null, storeTypes) => {
  const list = await getAgentList(page, alpha2Code, options, series);
  if (!storeTypes?.length) return list;
  return list.filter((x) => storeTypes.some((y) => x.storeTypes.includes(y)));
};
export const getNormalAgentOptions = async (page, alpha2Code = null, options = null, series = null) => {
  const list = await getNormalAgentList(page, alpha2Code, options, series);
  return agentList2Options(list);
};
export const getAgentSchedule = (storeDetail, $t, type = 'sales') => {
  const list = storeDetail[type === 'sales' ? 'businessHoursSalesObj' : 'businessHoursServiceObj'];
  if (!list?.length) return [];
  return list.map((x) => {
    let day = x.week - 1;
    if (isNaN(day)) day = 0;
    const dayOfWeek = $t(WEEKDAY_ARRAY[day]);
    const start = x.workStartTime.slice(0, 5),
      end = x.workEndTime.slice(0, 5);
    let openTime = '';
    if ((isNullOrEmpty(start) && isNullOrEmpty(end)) || (start === '00:00' && end === '00:00')) {
      openTime = $t('Closed');
    } else {
      openTime = start + ' - ' + end;
    }
    return {
      day: dayOfWeek,
      openTime
    };
  });
};
export const getWebLinkStores = async () => {
  const lang = webStorage.get(S_LANG);
  const cache = webStorage.get(S_STORE_LINKS) || {};
  if (!cache[lang]) {
    const [res, ex] = await api.web.item({
      id: config.app.web.itemIds.webLinkStores,
      isDeep: true
    });
    if (res?.children?.length > 0) {
      cache[lang] = res.children;
      webStorage.set(S_STORE_LINKS, cache);
    }
  }
  return cache[lang];
};
