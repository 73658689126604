<template>
  <modal class="s-track-modal" ref="modalRef" :animation="$deviceComputes.isMobileOrTablet.value ? 'bottom-slide-in' : 'bottom-fade-in'" @closed="onClosed" closable>
    <JssRichText class="s-track-modal__title" :field="fields.orderStatusText" />
    <div class="s-track-modal__status">SHIPPED</div>
    <BackgroundImage class="s-track-modal__image" :image="fields.image" />
    <div class="s-track-modal__orderid" v-if="expressCode">
      <JssRichText class="s-track-modal__orderid__title" :field="fields.trackingIdText" />
      <div class="s-track-modal__orderid__id">{{ expressCode }}</div>
    </div>
    <div class="s-track-modal__step">
      <div class="s-track-modal__step__item" v-for="item in express" :key="item.id">
        <div class="s-track-modal__step__item__date">{{ item.date }}</div>
        <div class="s-track-modal__step__item__content">
          <div class="s-track-modal__step__item__content__time">{{ item.sendTime }}</div>
          <div class="s-track-modal__step__item__content__descrpition">{{ item.context }}</div>
        </div>
      </div>
    </div>
    <div class="s-track-modal__buttons">
      <template v-for="buttonItem in buttonItems" :key="buttonItem.id">
        <site-button :fields="buttonItem.button" :data-code="buttonItem.buttonCode" @click="onButtonClick($event, buttonItem)" />
      </template>
    </div>
  </modal>
</template>

<script>
/**
 * @typedef NoticeModalFields
 * @property {SimpleField} title
 * @property {SimpleField} body
 * @property {ButtonField} buttons
 * */
/**
 * @typedef NoticeModal
 * @property {NoticeModalFields} fields
 * */
import { sitecoreProps } from '@/utils/constants';
import { computed, inject, reactive, toRefs, onMounted } from 'vue';
import useAppStore from '@/store/appStore';
import { concatString } from '@/utils/string-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { useRoute, useRouter } from 'vue-router';
import { getCurrentAlpha2Code } from '@/utils/site-utils';
import api from '@/api';

export default {
  name: 'TrackModal',
  props: {
    fields: {
      type: Object
    },
    express: {
      type: Object
    },
    expressCode: {
      type: String
    },
    ...sitecoreProps
  },
  setup(props) {
    const appMethods = inject('appMethods');
    const route = useRoute();
    /**@type AppStore*/
    const appStore = useAppStore();
    const toast = inject('toast');
    const state = reactive({
      /**@type ModalRef*/
      modalRef: null,
      activeButton: null,
      onClosed: () => {}
    });
    const computes = {
      buttonItems: computed(() => {
        const { buttons } = props.fields || {};
        if (!buttons?.length) return [];
        const result = [];
        for (let buttonItem of buttons) {
          if (!buttonItem?.fields) continue;
          const { buttonCode, link, ...otherFields } = buttonItem.fields;
          const buttonCodeVal = buttonCode.value;
          result.push({
            link: link,
            buttonCode: buttonCodeVal,
            button: otherFields
          });
        }
        return result;
      }),
      fData: computed(() => {
        const { firstName, middleName, lastName, email } = appStore?.loginInfo || {};
        if (isNullOrEmpty(firstName)) return appStore?.loginInfo?.nickName;
        return {
          name: concatString([firstName, middleName, lastName], ' '),
          email
        };
      })
    };
    const methods = {
      async open() {
        return new Promise((resolve) => {
          if (!state.modalRef) resolve(null);
          state.onClosed = () => {
            resolve(state.activeButton);
            state.activeButton = null;
          };
          state.modalRef.open();
        });
      },
      async onButtonClick(e, buttonItem) {
        if (!buttonItem) return;
        state.activeButton = buttonItem;
        await state.modalRef?.close();
      },
      getAMPMTime(timestamp) {
        const currentAlpha2Code = getCurrentAlpha2Code();
        let date = new Date(timestamp);
        let timeString = date.toLocaleTimeString('en-' + currentAlpha2Code, { hour12: true });
        return timeString;
      },
      formatDate(expressTime) {
        let date = new Date(expressTime);
        const currentAlpha2Code = getCurrentAlpha2Code();
        return date.toLocaleDateString('en-' + currentAlpha2Code, {
          weekday: 'long',
          month: 'long',
          day: 'numeric'
        });
      }
    };
    onMounted(() => {
      let currentTimestamp = Date.now();
      let ampmTime = methods.getAMPMTime(currentTimestamp);
      if (props.express?.length) {
        props.express.map((item) => {
          item.sendTime = methods.getAMPMTime(item.expressTime);
          item.date = methods.formatDate(item.expressTime);
        });
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods,
      appStore
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-track-modal {
  $c: &;
  $m: #{'.e-modal'};
  $btn: '.e-site-button';
  &.e-modal {
    align-items: flex-end;
    #{$m}__content {
      width: 100%;
      padding: 32px 0 48px;
    }
  }
  &__title {
    font-size: 12px;
    line-height: 20px;
    color: $black;
    margin-top: 24px;
    padding: 0 24px;
  }
  &__status {
    font-size: 26px;
    line-height: 1;
    margin-top: 8px;
    padding: 0 24px;
  }
  &__image {
    width: 100%;
    height: 60vw;
    margin-top: 24px;
  }
  &__orderid {
    display: flex;
    margin-top: 24px;
    padding: 0 24px;
    &__title {
      font-size: 16px;
      line-height: 24px;
      color: #757575;
    }
    &__id {
      font-size: 16px;
      line-height: 24px;
      color: #757575;
      margin-left: 5px;
    }
  }
  &__delivery {
    font-size: 14px;
    line-height: 20px;
    color: $black;
    margin-top: 24px;
    padding: 0 24px;
  }
  &__step {
    padding: 0 24px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__item {
      &__date {
        font-size: 12px;
        line-height: 20px;
        color: $black;
      }
      &__content {
        display: flex;
        margin-top: 16px;
        gap: 24px;
        &__time {
          font-size: 12px;
          line-height: 20px;
          color: $black;
          white-space: nowrap;
        }
        &__descrpition {
          font-size: 12px;
          line-height: 20px;
          color: #4f4f4f;
          border-left: 1px solid #b8b8b8;
          padding-left: 24px;
        }
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px;
    margin-top: 15px;
    .e-site-button {
      width: 100% !important;
      &[data-code='refuse'],
      &[data-code='close'],
      &[data-code='no'] {
        color: $grey-next !important;
        #{$btn}__icon {
          display: none;
        }
      }
    }
  }
  @include tablet-landscape {
    &.e-modal {
      align-items: center;
      #{$m}__content {
        width: grid-width(10);
        padding: 40px 0 80px;
      }
    }
    &__title {
      padding: 0 32px;
    }
    &__status {
      font-size: 32px;
      padding: 0 32px;
    }
    &__image {
      height: calc(grid-width(8) * 0.6);
      margin-top: 48px;
    }
    &__orderid {
      margin-top: 48px;
      padding: 0 32px;
    }
    &__delivery {
      padding: 0 32px;
    }
    &__step {
      padding: 0 32px;
      margin-top: 24px;
    }
    &__buttons {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 32px;
      .e-site-button {
        width: fit-content !important;
        flex-grow: 1;
        flex-shrink: 0;
        overflow: hidden;
      }
    }
  }
}
</style>
