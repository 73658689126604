<template>
  <div class="s-trade-in" v-show="[2, 3].includes(agent?.data.extra?.tradeIn)">
    <div class="s-trade-in__content">
      <jss-rich-text class="s-trade-in__title" :field="fields.tradeInTitle" tag="div" />
      <jss-rich-text class="s-trade-in__body" :field="fields.tradeInBody" tag="div" />
      <div class="s-trade-in__items">
        <template v-for="item in fields.tradeInItems" :key="item.id">
          <div class="s-trade-in__item" :class="[{ checked }]" v-if="agent?.data.extra?.tradeIn === Number($settingValue(item.fields.type))">
            <template-string class="s-trade-in__item-title" :field="item.fields.title" :data="{ model: $formatModel(carModel?.name) }" />
            <template-string class="s-trade-in__item-body" :field="item.fields.body" :data="{ model: $formatModel(carModel?.name) }" />
            <site-button class="s-trade-in__item-btn" v-bind="item.fields.button" />
            <dynamic-form :form="item.fields.form" ref="formRef" @init-form="onInitForm" @change="onFormChange" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @typedef TradeInFields
 * @property {SimpleField} tradeInTitle
 * @property {SimpleField} tradeInBody
 * @property {Array<TradeInItem>} tradeInItems
 * */
/**
 * @typedef TradeInRef
 * @property {TradeInValidate} validate
 * */
/**
 * @callback TradeInValidate
 * @returns {[data:Object, valid: Boolean]}
 * */
/**
 * @typedef TradeInItem
 * @property {{
 *   type: GlobalSettingEntry,
 *   title: SimpleField,
 *   body: SimpleField,
 *   button: ButtonField,
 *   form: Form
 * }} fields
 * */
import { reactive, toRefs, watch } from 'vue';
import { formatString } from '@/utils/string-utils';
import { settingValue } from '@/utils/site-utils';
import { debounce } from 'lodash';

export default {
  name: 'trade-in',
  props: {
    /**@type TradeInFields*/
    fields: {
      type: Object
    },
    agent: {
      type: Object
    },
    carModel: {
      type: Object
    }
  },
  setup(props) {
    const _methods = {
      onAgentChange: debounce(async (agent) => {
        let tradeInItem = null;
        if (agent?.tradeIn !== null) {
          tradeInItem = props.fields.tradeInItems.find((x) => Number(settingValue(x.fields.type)) === agent?.data.extra?.tradeIn);
        }
        if (tradeInItem && state.formRef?.length) {
          const { form } = tradeInItem.fields;
          const { items: formItems } = form?.fields;
          const check = formItems.find((x) => x.fields.dataName.value === 'check');
          const mandatoryText = formatString(check.fields.mandatoryText.value, {
            agent: agent.text
          });
          state.formRef[0].updateItem('check', {
            controlProps: {
              mandatoryText
            }
          });
        }
      }, 50)
    };
    const methods = {
      async validate() {
        let data = null,
          valid = true,
          leadTypes = null;
        if (state.formRef?.length) {
          [valid, data] = await state.formRef[0].validate();
          leadTypes = state.formRef.map((f) => f.getLeadTypes());
        }
        return [valid, data, ...leadTypes];
      },
      onInitForm(data) {
        state.checked = data.check?.controlProps.value;
      },
      onFormChange(name, value) {
        if (name === 'check') {
          state.checked = value;
        }
      }
    };
    const state = reactive({
      /**@type Array<DynamicForm>*/
      formRef: [],
      checked: false
    });
    watch(
      () => props.agent,
      (agent) => {
        _methods.onAgentChange(agent);
      }
    );
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.s-trade-in {
  &__items {
    margin-top: 24px;
  }
  &__item {
    opacity: 0.8;
    &.checked {
      opacity: 1;
    }
    & + & {
      margin-top: 24px;
    }
  }
}
</style>
